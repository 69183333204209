const messages = {
  "title": "Schránka",
  "desc": "Zde najdete důležité zprávy a novinky.",
  "newMessages": "Nové zprávy ve vaší schránce",
  "link": "Zobrazit zprávy",
  "showMessages": "Zobrazit zprávy",
  "message": {
    "sender": "Odesílatel",
    "thread": "Předmět",
    "date": "Datum"
  },
  "sender": {
    "system": "BRIDGESTONE PLUS"
  },
  "back": "Zpět"
}

export default {
  messages,
};
