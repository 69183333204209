const messages = {
    "titleColorRev": "true",
    "buttons": {
        "pending": "Afventer",
        "open": "Åben",
        "requestAccess": "Anmod om adgang"
    },
    "my": "Mine applikationer",
    "directRoute": "Dine direkte links til Bridgestones webapplikationer.",
    "welcomeMessage": "Velkommen til BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Vis profil",
    "newMessages": "Ny besked i indbakken",
    "myApps": "Mine applikationer",
    "allApps": "Alle applikationer",
    "myAppsDescription": "Alle Bridgestone webapplikationer, du er registreret i.",
    "allAppsDescription": "Alle Bridgestone webapplikationer, der kan være relevante for dig.",
    "myProfile": {
        "welcomeMessage": "Velkommen til dit personlige område. Her kan du opdatere eller justere dine oplysninger og se din indbakke.",
        "masterData": "Stamdata",
        "completeDetails": "Udfyld dine personlige og virksomhedsdata for at sikre korrekt registrering.",
        "goToMasterData": "Gå til mine data",
        "goToInbox": "Gå til indbakke"
    },
    "masterData": {
        "welcomeMessage": "Velkommen til dit personlige område. Her kan du opdatere eller justere dine stamdata.",
        "personalDetails": "Personlige oplysninger",
        "companyDetails": "Virksomhedsoplysninger",
        "emailAddress": "E-mailadresse",
        "title": "Titel",
        "surname": "Efternavn",
        "firstName": "Fornavn",
        "jobPosition": "Jobposition",
        "streetAndHouseNumber": "Virksomhedsadresse",
        "postalCode": "Postnummer",
        "city": "By",
        "country": "Land",
        "legalStructure": "Juridisk struktur",
        "customerNumber": "Bridgestone kundennummer",
        "importantPleaseRead": "Vigtigt, læs venligst",
        "customerNumberInfo": "For at kunne tildele dine dækordrer korrekt til forskellige forhandlere, er det vigtigt, at du angiver dit kundennummer.",
        "multipleCustomerNumbers": "Hvis du har flere kundennumre med en forhandler, f.eks. for dit hovedselskab og filialer, skal du først angive hovedselskabets kundennummer.",
        "salesAssignment": "Salget knyttet til yderligere kundennumre vil blive tildelt dit hovedselskab.",
        "supplier": "Leverandør",
        "addAnotherCustomerNumber": "Tilføj kundennummer",
        "save": "Gem",
        "delete": "Slet"
    },
    "inbox": {
        "welcomeMessage": "Her kan du finde notifikationer og vigtige nyheder.",
        "sender": "Afsender",
        "subject": "Emne",
        "date": "Dato",
        "formConsulting": "Formular modtaget - Tak for at have udfyldt den",
        "back": "Tilbage"
    },
    "legalAndContact": {
        "cookieSettings": "Cookieindstillinger",
        "dataPolicy": "Datapolitik",
        "termsOfUse": "Brugsbetingelser",
        "helpAndContact": "Hjælp og kontakt"
    },
    "logInSignUp": {
        "logIn": "Log ind",
        "signUp": "Tilmeld dig",
        "logInMessage": "Log ind med din e-mailadresse og adgangskode.",
        "signUpMessage": "Hvis du er en ny bruger, skal du venligst tilmelde dig."
    }
}
export default {
    messages
}