const messages = {
    "fleetWithOptions": "Nie możesz wybrać floty, jeśli wybrałeś opcje.",
    "addProduct": "Twój produkt został dodany do koszyka.",
    "addProducts": "Twoje produkty zostały dodane do koszyka.",
    "profileSaved": "Dane profilu zostały zapisane.",
    "canAddProduct": "Nie masz wystarczającej liczby punktów.",
    "customerNumberSaved": "Numer klienta został zapisany.",
    "customerNumberExist": "Numer klienta jest dostępny.",
    "complaintForm": "Twoje zgłoszenie zostało przyjęte. Poczekaj na odpowiedź.",
    "openHourSaved": "Godziny otwarcia zostały zapisane.",
    "changeParentId": "Nie możesz zmienić oddziału z nadrzędnym numerem klienta, jeśli posiada podległe oddziały.",
    "requestSent": "Żądanie zostało wysłane.",
    "completeProfileRequired": "Musisz wypełnić swój profil w 100%, aby kontynuować zakupy w sklepie.",
    "accessDenied": "Dostęp zabroniony.",
    "incomplete_address": "Adres jest niekompletny. Proszę to sprawdzić.",
    "waitMoment": "Poczekaj chwilę.",
    "downloadFinished": "Pobieranie zakończone.",
    "selectOneField": "Wybierz pole.",
    "noVoucher": "Nie znaleziono kuponu.",
    "cannotAddCsNumber": "Nie możesz dodać numeru klienta."
}

export default {
    messages
}
