const messages = {
  title: "Travaux de maintenance",
  description: "Des travaux de maintenance nécessaires ont actuellement Ville sur les serveurs." +
      "<br/><br/>Par conséquent, l'accès à <b>BRIDGESTONE <span class=\"text-danger\">PLUS</span></b> " +
      "n'est actuellement pas disponible pour vous.<br/><br/>Nous vous remercions de votre patience et vous" +
      " remercions de votre compréhension." +
      "<br/><br/> Votre équipe Bridgestone"
}
export default {
    messages
}
