const messages = {
  "title": "Vítejte v BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Máte již účet?</b><br/>Přihlaste se pomocí své e-mailové adresy a hesla.<br/><br/><b>Jste tu nový?</b><br/>Pokud jste nový uživatel, zaregistrujte se prosím.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Přihlásit / Registrovat",
    "back": "Zpět na přihlášení",
    "changeLanguage": "Změnit jazyk",
    "start": "Začít"
  },
  "languageSelect": {
    "label": "Výběr jazyka",
    "description": "Vyberte prosím svůj jazyk"
  }
}

export default {
  messages
}