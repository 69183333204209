const messages = {
  seeAll: "Zobacz wszystko",
  clear: "Wyczyść",
  back: "Wróć",
  save: "Oszczędzać",
  close: "Zamknij",
  of: "z",
  to: "do",
  copyrights: "© {date} Bridgestone Solutions. Wszelkie prawa zastrzeżone.",
  pageNotFound: "Strona nie znaleziona",
  accessDenied: "Dostęp zabroniony",
  search: "Szukaj",
  buttons: {
    export: "Eksportuj",
    delete: "Usunąć",
    save: "Oszczędzać",
    cancel: "Anuluj",
    next: "Dalej",
    back: "Wróć",
    skip: "Pomiń",
    send: "Wysłać",
    clear: "Wyczyść",
  },
  tooltips: {
    info: "Potrzebujesz pomocy? +48 22 393 3791",
  },
  confirmation: {
    header: "Wymagane potwierdzenie",
    message: "Czy chcesz usunąć ten wpis?",
  },
  nav: {
    dashboard: "Panel",
    account: "Profil",
    mailbox: "Skrzynka odbiorcza",
    singleMailbox: "Wiadomości",
    stammdaten: "Profil",
  },
  menu: {
    account: "Profil",
    plus: 'O Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Wsparcie sprzedaży",
    mailbox: "Skrzynka odbiorcza",
    about: "O BS Plus",
    trainingAndCourses: "Szkolenia i kursy",
    externalTraining: "Szkolenia zewnętrzne",
    productInfo: "Informacje o produktach",
    productBrochures: "Broszury produktowe",
    priceLists: "Cenniki",
    price: "Cena",
    complaintForm: "Formularz reklamacyjny",
    sales_support: "Wsparcie sprzedaży",
    contact_service: "Informacje kontaktowe i serwis",
    logOut: "Wyloguj się",
    news: "Aktualności",
  },
};

export default {
  messages,
};
