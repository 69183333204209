<template>
  <MDBCard class="py-0 border-0">
    <div class="overview-header">
      <h2 class="font-20 font-xxxl-40 mb-4">
        {{ $t("account.profile.inDetail", { name: user_data.company }) }}
      </h2>
      <MDBRow>
        <MDBCol cols="12" md="3">
          <div>
            {{ $t("account.profile.streetHouseNr") }}: {{ profile_data?.street }}
          </div>
          <div>
            {{ $t("account.profile.postcode") }}: {{ profile_data?.postal_code }}
          </div>
          <div>{{ $t("account.profile.location") }}: {{ profile_data?.city }}</div>
          <div v-if="isGermany">
            {{ $t("account.profile.name") }}: {{ profile_data?.forename }}
            {{ profile_data?.name }}
          </div>
          <div>{{ $t("account.profile.email") }}: {{ profile_data?.email }}</div>
          <div v-if="isNetherland">
            <div>
              VAT:
              {{ profile_data?.vat }}
            </div>
            <div>
              IBAN:
              {{ profile_data?.iban_number }}
            </div>
            <div>
              {{ $t("account.profile.tireSaleDetail") }}:
              {{ profile_data?.tireSalesNumber }}
            </div>
          </div>
        </MDBCol>
        <MDBCol col="12" md="3">
          <div>
            {{ $t("account.profile.clientNrSingular") }}:
            {{ user_data.customer_number }}
          </div>
          <div>
            {{ $t("account.profile.registeredAt") }}: {{ user_data.created_at }}
          </div>
          <div>{{ $t("account.profile.lastLogIn") }}: {{ user_data.last_login }}</div>

          <div>
            {{ $t("bankstatement.tyresAmount") }}:
            <span>{{ pointsByCustomerNumbers?.tyresAmount }}</span>
          </div>
          <div>
            {{ $t("bankstatement.positivePoints") }}:
            <span class="text-success">{{
              profile_data?.commitment_letter_accept
                ? pointsByCustomerNumbers?.positivePoints
                : "0"
            }}</span>
          </div>
          <div>
            {{ $t("bankstatement.negativePoints") }}:
            <span class="text-danger">{{
              pointsByCustomerNumbers?.negativePoints
            }}</span>
          </div>
          <div>
            BRIDGESTONE PLUS:
            {{
              profile_data?.commitment_letter_accept
                ? pointsByCustomerNumbers.sumBsPoints
                : "0"
            }}
          </div>
          <div v-if="pointsByCustomerNumbers.adexPoints">
            <div
              v-for="(point, index) in pointsByCustomerNumbers.adexPoints"
              :key="index"
            >
              <b>{{ $t("account.profile.clientNrSingular") }} {{ index }}:</b>
              {{ profile_data?.commitment_letter_accept ? point : "0" }}
            </div>
          </div>
        </MDBCol>
        <MDBCol
          col="12"
          md="3"
          v-if="
            pointsByCustomerNumbers?.wholesalersDealersOutput &&
            pointsByCustomerNumbers?.wholesalersDealersOutput.length > 0
          "
        >
          <div
            v-for="(
              data, index
            ) in pointsByCustomerNumbers?.wholesalersDealersOutput"
            :key="`wsh_${index}`"
          >
            {{ data.wholesaler }}: {{ data.customer_number }}
          </div>
        </MDBCol>
        <MDBCol col="12" md="3">
          <h4>
            {{ $t("bankstatement.currentPointState") }}:
            {{
              profile_data?.commitment_letter_accept
                ? points?.sumStandardPoints
                : "0"
            }}
          </h4>
          <h4 v-if="isNetherland">
            {{ $t("bankstatement.currentKickbackState") }}:
            {{
              profile_data?.commitment_letter_accept
                ? pointsByCustomerNumbers?.sumBsKickbacks
                : "0"
            }}&euro;
          </h4>
        </MDBCol>
      </MDBRow>
    </div>
    <MDBRow>
      <MDBCol>
        <div class="table-responsive">
          <MDBRow class="points-overview">
            <MDBCol xl="6" md="6" class="mb-2">
              <label for="pointType" class="label px-3 text-uppercase">
                {{ $t("bankstatement.typeFilter") }}
              </label>
              <select
                class="form-select mb-1"
                aria-label=".form-select-lg example"
                v-model="pointType"
                @change="filterResults"
                id="pointType"
              >
                <option :value="null">
                  {{ $t("makeChoice") }}
                </option>
                <option value="bsPlus">BRIDGESTONE PLUS</option>
                <option
                  :value="'bsDe_' + wsh.id"
                  v-for="(wsh, index) in wsh_data"
                  :key="index"
                >
                  {{ wsh.name }}
                </option>
              </select>
            </MDBCol>
            <MDBCol xl="6" md="6" class="mb-2">
              <label for="date" class="label px-3 text-uppercase">
                {{ $t("bankstatement.dateFilter") }}
              </label>
              <VueDatePicker
                v-model="date"
                range
                :locale="LANG"
                :enable-time-picker="false"
                :cancelText="$t('buttons.cancel')"
                :selectText="$t('buttons.select')"
                @update:model-value="filterResults"
                :format="formatDatePicker"
                id="date"
              ></VueDatePicker>
            </MDBCol>
          </MDBRow>
          <MDBTable borderless class="points-overview">
            <thead>
              <tr>
                <th v-for="(head, id) in tableHeaders" :key="`key_${id}`">
                  {{ head.label }}
                  <div
                    class="d-flex justify-content-end py-1"
                    @click="
                      sort({
                        field: head.field,
                        factory: head.factory ? head.factory : undefined,
                      })
                    "
                  >
                    <MDBIcon
                      v-bind:icon="
                        head.field == sortData.field
                          ? `sort-${sortData.order}`
                          : 'sort'
                      "
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, key) in bankData" :key="key">
                <td>{{ dates.parseDate(account.date) }}</td>
                <td
                  v-html="account.source ? setSource(account.source) : '-'"
                ></td>
                <td>
                  {{ account.customer_number ? account.customer_number : "-" }}
                </td>
                <td>{{ account.article ? account.article : "-" }}</td>
                <td>{{ account.brand ? account.brand : "-" }}</td>
                <td>{{ account.product ? account.product : "-" }}</td>
                <td>{{ account.type ? account.type : "-" }}</td>
                <td>{{ account.dimension ? account.dimension : "-" }}</td>
                <td>{{ account.saison ? setSeason(account.saison) : "-" }}</td>
                <td>{{ account.qty ? account.qty : "-" }}</td>

                <td>{{ account.provenance ? account.provenance : "-" }}</td>
                <td :class="{ 'text-danger': account.points < 0 }">
                  {{
                    profile_data?.commitment_letter_accept
                      ? account.points
                      : "0"
                  }}
                </td>
                <td
                  :class="{ 'text-danger': account.kickBackFee < 0 }"
                  v-if="isNetherland"
                >
                  {{
                    profile_data?.commitment_letter_accept
                      ? account.kickBackFee
                      : "0"
                  }}
                  &euro;
                </td>
              </tr>
            </tbody>
          </MDBTable>
          <div class="load-more d-flex justify-content-center">
            <div @click="loadMore" class="btn btn-danger text-uppercase px-5">
              {{ $t("bankstatement.loadMore") }}
            </div>
            <div
              class="btn btn-success text-uppercase px-5"
              v-if="isGermany"
              @click="downloadBankStatement()"
            >
              {{ $t("buttons.downloadAll") }}
            </div>
          </div>
        </div>
        <div v-if="loading">
          <Loader :show="true" spinner="spinner-red.svg" />
        </div>
      </MDBCol>
    </MDBRow>
  </MDBCard>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import { MDBCard, MDBRow, MDBCol, MDBTable, MDBIcon } from "mdb-vue-ui-kit";
import VueDatePicker from "@vuepic/vue-datepicker";

import { useStore } from "vuex";
import {
  computed,
  ref,
  reactive,
  watch,
  toRaw,
  defineProps,
  onMounted,
} from "vue";
import i18n from "@/plugins/i18n";
import dates from "@/plugins/dates";
import config from "@/config";

const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");
const isNetherland = computed(() => LANG === "nl");

const props = defineProps({
  user_id: String,
  user_data: Object,
  profile_data: {
    type: Object,
    default: null,
  },
  wsh_data: {
    type: Object,
    default: null,
  },
});
const user = computed(() => store.getters.getMe);
const isWholesaler = ref(false);

const page = ref(0);
const bankData = ref([]);
const pointType = ref(null);
const date = ref([]);
const tableHeaders = ref([
  {
    label: i18n.global.t("bankstatement.date"),
    field: "date",
    factory: (v) => new Date(v).getTime(),
  },
  {
    label: i18n.global.t("bankstatement.supplySourceReason"),
    field: "source",
  },
  {
    label: i18n.global.t("bankstatement.customer_number"),
    field: "customer_number",
  },
  {
    label: i18n.global.t("bankstatement.article"),
    field: "article",
  },
  {
    label: i18n.global.t("bankstatement.brand"),
    field: "brand",
  },
  {
    label: i18n.global.t("bankstatement.product"),
    field: "product",
  },
  {
    label: i18n.global.t("bankstatement.type"),
    field: "type",
  },
  {
    label: i18n.global.t("bankstatement.inch"),
    field: "dimension",
  },
  {
    label: i18n.global.t("bankstatement.season"),
    field: "saison",
    factory: (v) => {
      return !v ? setSeason("") : setSeason(v);
    },
  },
  {
    label: i18n.global.t("bankstatement.amount"),
    field: "qty",
  },
  {
    label: i18n.global.t("bankstatement.otherActions"),
    field: "provenance",
  },
  {
    label: i18n.global.t("bankstatement.totalPoints"),
    field: "points",
  },
]);

const sortData = reactive({
  field: "",
  order: "",
  factory: (v) => v,
  random: "",
});

const store = useStore();

const loading = computed(() => store.getters.getPending);

const points = computed(() => store.getters.getPoints);
const bank = computed(() => store.getters.getBankStatementData);
const pointsByCustomerNumbers = computed(
  () => store.getters.getPointsByCsNumber
);

const setSeason = (text) => {
  const text_len = text.length;
  let data;
  if (text_len % 2 != 0) {
    let start = (text_len - 1) / 2;
    data = text.slice(start, start + 1);
  } else {
    let start = text_len / 2 - 1;
    data = text.slice(start, start + 2);
  }

  if (data == "S") {
    return i18n.global.t("seasons.summer");
  } else if (data == "W") {
    return i18n.global.t("seasons.winter");
  } else {
    return i18n.global.t("seasons.all");
  }
};

const setSource = (data) => {
  if (data == "BSPLUS") {
    return `BRIDGESTONE <span class='text-danger'>PLUS</span>`;
  } else {
    return data;
  }
};

const loadMore = async () => {
  page.value += 1;

  let query;

  const dateFrom =
    date.value && date.value[0]
      ? new Date(date.value[0]).toLocaleDateString(LANG)
      : "";
  const dateTo =
    date.value && date.value[1]
      ? new Date(date.value[1]).toLocaleDateString(LANG)
      : "";

  if (date.value?.length && pointType.value) {
    query = `source=${pointType.value}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (date.value && !pointType.value) {
    query = `dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (pointType.value) {
    query = `source=${pointType.value}`;
  }
  query += `&user_id=${props.user_id}`;

  if (isWholesaler.value) {
    store.dispatch("wshBankStatement", { page: page.value, query: query });
  } else {
    store.dispatch("bankStatement", { page: page.value, query: query });
  }
};

const filterResults = async () => {
  page.value = 0;
  bankData.value = [];
  let query;

  const dateFrom =
    date.value && date.value[0]
      ? new Date(date.value[0]).toLocaleDateString(LANG)
      : "";
  const dateTo =
    date.value && date.value[1]
      ? new Date(date.value[1]).toLocaleDateString(LANG)
      : "";

  if (date.value?.length && pointType.value) {
    query = `source=${pointType.value}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (date.value && !pointType.value) {
    query = `dateFrom=${dateFrom}&dateTo=${dateTo}`;
  } else if (pointType.value) {
    query = `source=${pointType.value}`;
  }

  query += `&user_id=${props.user_id}`;
  if (isWholesaler.value) {
    store.dispatch("wshBankStatement", { page: page.value, query: query });
  } else {
    store.dispatch("bankStatement", { page: page.value, query: query });
  }
};

const sort = (
  args = {
    field: "",
    order: "",
    factory: (v) => v,
  }
) => {
  sortData.field = args.field;
  sortData.order = args.order || sortData.order !== "down" ? "down" : "up";
  sortData.factory = args.factory
    ? args.factory
    : (v) => {
        if (v == null) return "";
        return v;
      };
  sortData.random = Math.random();
};

watch(bank, (newVal) => {
  if (newVal.length > 0 && page.value == 0) {
    bankData.value = Object.values(toRaw(newVal));
  } else {
    bankData.value.push(...Object.values(toRaw(newVal)));
  }
  sort(toRaw(sortData));
});

watch(
  sortData,
  (newVal) => {
    let sortVal = toRaw(newVal);
    bankData.value.sort((a, b) => {
      if (newVal.order == "up") {
        return sortVal.factory(a[sortVal.field]) >
          sortVal.factory(b[sortVal.field])
          ? 1
          : sortVal.factory(b[sortVal.field]) >
            sortVal.factory(a[sortVal.field])
          ? -1
          : 0;
      } else {
        return sortVal.factory(a[sortVal.field]) <
          sortVal.factory(b[sortVal.field])
          ? 1
          : sortVal.factory(b[sortVal.field]) <
            sortVal.factory(a[sortVal.field])
          ? -1
          : 0;
      }
    });
  },
  {
    deep: true,
  }
);

const formatDatePicker = (date) => {
  return `${dates.parseDate(date[0])} - ${
    date[1] ? dates.parseDate(date[1]) : ""
  }`;
};

const downloadBankStatement = () => {
  window.open(
    `${config.URL}/api/de/user-bank-statement?userId=${props.user_id}`
  );
};

onMounted(() => {
  const isWshOrWshStaff =
    user.value &&
    user.value.roles.find(
      (item) => item.name == "wholesaler" || item.name == "wholesalerStaff"
    );
  if (isWshOrWshStaff) {
    isWholesaler.value = true;
  }

  if (isNetherland.value) {
    tableHeaders.value.push({
      label: i18n.global.t("kickback.word"),
      field: "kickBackFee",
    });
  }

  if (isWholesaler.value) {
    store.dispatch("wshBankStatement", {
      page: page.value,
      query: `user_id=${props.user_id}`,
    });
  } else {
    store.dispatch("bankStatement", {
      page: page.value,
      query: `user_id=${props.user_id}`,
    });
  }
});
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/points_overview.scss";

.overview-header {
  padding: 60px 60px 40px 60px;
}
</style>
