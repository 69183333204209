const messages = {
  "seeAll": "Zobrazit vše",
  "clear": "Vymazat",
  "back": "Zpět",
  "save": "Uložit",
  "close": "Zavřít",
  "of": "z",
  "to": "do",
  "copyrights": "© {date} Bridgestone Solutions. Všechna práva vyhrazena.",
  "pageNotFound": "Stránka nebyla nalezena",
  "accessDenied": "Přístup odepřen",
  "search": "Hledat",
  "buttons": {
    "export": "Exportovat",
    "delete": "Smazat",
    "save": "Uložit",
    "cancel": "Zrušit",
    "next": "Další",
    "back": "Zpět",
    "skip": "Přeskočit",
    "send": "Odeslat"
  },
  "tooltips": {
    "info": "Potřebujete pomoc? +420 771 239 989"
  },
  "confirmation": {
    "header": "Vyžadováno potvrzení",
    "message": "Opravdu chcete tuto položku smazat?"
  },
  "nav": {
    "dashboard": "Domů",
    "account": "Profil",
    "mailbox": "Schránka",
    "singleMailbox": "Zprávy"
  },
  "menu": {
    "account": "Profil",
    "plus": "O Bridgestone <span class=\"text-danger\">Plus</span>",
    "salesSupport": "Podpora prodeje",
    "mailbox": "Schránka",
    "about": "O BS Plus",
    "trainingAndCourses": "Školení a kurzy",
    "externalTraining": "Externí školení",
    "productInfo": "Informace o produktu",
    "productBrochures": "Produktové brožury",
    "priceLists": "Ceníky",
    "price": "Cena",
    "complaintForm": "Formulář stížnosti",
    "sales_support": "Podpora prodeje",
    "contact_service": "Kontaktní údaje a servis",
    "logOut": "Odhlásit se",
    "news": "Novinky"
  }
}

export default {
  messages,
};
