const messages = {
  "title": "Skrzynka odbiorcza",
  "desc": "Tutaj znajdziesz ważne powiadomienia i wiadomości.",
  "newMessages": "Nowe wiadomości w skrzynce odbiorczej",
  "link": "Pokaż wiadomości",
  "showMessages": "Pokaż wiadomości",
  "message": {
    "sender": "Nadawca",
    "thread": "Podmiot",
    "date": "Data"
  },
  "sender": {
    "system": "BRIDGESTONE PLUS"
  },
  "back": "Wróć"
}

export default {
  messages,
};
