import i18n from "@/plugins/i18n";
import { toast } from "vue3-toastify";

import { ProfileService } from "@/services/profile.service";

export async function fetchUserProfile(store) {
  try {
    if (!store.getters.getIsMePending) {
      await store.dispatch("me");
    }
  } catch (error) {
    console.log(error);
  }
}

export async function afterSuccess(
  user_id,
  profile_id,
  fillPercent,
  plainId,
  store
) {
  try {
    await store.dispatch("storeProfilePercentage", {
      user_id: user_id,
      profile_id: profile_id,
      percentage: fillPercent,
    });

    if (fillPercent == 100) {
      await ProfileService.storeFullProfilePoints(plainId);
    }

    await store.dispatch("loadUnreadMessages");

    toast(i18n.global.t("toastMessages.profileSaved"), {
      type: "success",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } catch (error) {
    console.log(error);
  }
}
