const messages = {
  "title": "O meu perfil",
  "desc": "Bem-vindo à sua área pessoal. Aqui, pode atualizar ou ajustar os seus<br/>dados principais e ver a sua caixa de entrada.",
  "descStaff": "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou adapter vos données de référence.",
  "buttons": {
    "showProfile": "Ver perfil",
    "userProfile": "IR À DADOS PRINCIPAIS",
    "mailbox": "IR À CAIXA DE ENTRADA"
  },
  "categories": {
    "baseData": {
      "title": "Dados Principais",
      "desc": "Preencha os seus dados pessoais e os da sua empresa para que o registo seja bem sucedido."
    },
    "mailbox": {
      "desc": "Encontre notificações importantes, confirmações de encomendas e notícias na sua caixa de entrada."
    },
    "bankstatement": {
      "title": "Extrato de conta",
      "desc": "Aqui pode ver o seu saldo atual de pontos e os movimentos da conta. "
    }
  },
  "profile": {
    "title": "Dados Principais",
    "description": "Bem-vindo à sua área pessoal. Aqui pode atualizar ou ajustar os seus dados principais.",
    "personalData": "DADOS PESSOAIS",
    "password": "Senha",
    "password1": "Senha (à sua escolha)*",
    "password2": "Senha (repetição)*",
    "formOfAddress": "Título",
    "choose": "Selecione o título",
    "mr": "Sr.",
    "mrs": "Sra.",
    "diverse": "Outro",
    "firstname": " Primeiro Nome ",
    "surname": "Apelido",
    "position": "CARGO NA EMPRESA",
    "operatingData": "DADOS DE EMPRESA",
    "company": "Empresa",
    "legalForm": "Estrutura jurídica",
    "name": "Nome",
    "streetHouseNr": "Morada e número da porta",
    "street": "Rua",
    "postcode": "Código postal ",
    "location": "Cidade",
    "country": "País",
    "de": "Alemanha",
    "at": "Autriche",
    "ch": "Suisse",
    "nl": "Pays-Bas",
    "it": "Italie",
    "language": "Idioma",
    "french": "Francês",
    "italian": "Italiano",
    "german": "Alemão",
    "nederland": "Holandês",
    "openingHours": "Horário de funcionamento & contato",
    "phone": "Telefone",
    "website": "Site",
    "operatingIndicators": "Indicadores operacionais",
    "tireSale": "Venda anual de pneus em unidades (aprox.)",
    "tireSaleLabel": "Venda de pneus em grande quantidade",
    "inches": "Incluindo ≥ 18 polegadas (em %)",
    "inchesLabel": "Incluindo 18 polegadas ou mais em %",
    "segmentSale": "Vendas de pneus por segmento (Budget / Mid / Premium em %)",
    "premBudget": "Premium",
    "midBudget": "Médio",
    "budBudget": "Orçamento",
    "distribution": "DISTRIBUIÇÃO VERÃO / INVERNO / TODAS AS ESTAÇÕES (em %)",
    "tyreSizes": "Armazenagem por temporada (jogos de pneus)",
    "diffDeliveryAddres": "Adicionar um endereço de entrega",
    "yourEmailAddress": "Seu endereço de e-mail.",
    "clientNr": "Números de clientes",
    "clientNrSingular": "Número de cliente",
    "clientNrContent": "<span class='text-danger fw-bold'>IMPORTANTE - POR FAVOR, LEIA:</span><br /><br />Para atribuição de seus pedidos de pneus em um atacadista e/ou na Bridgestone, é imperativo que <b>você insira seus números de cliente respectivos.</b><br/><br/>Se você <b>tiver vários números de cliente em um atacadista e/ou na Bridgestone,</b> por exemplo, para sua loja principal e filiais, insira primeiro o número de cliente da loja principal.<br/><br/><b>As vendas dos outros números de cliente serão atribuídas à sua loja principal.</b>",
    "supplySource": "Fonte de fornecimento",
    "email": "Endereço de e-mail",
    "staffEmail": "Endereço de e-mail do participante",
    "registered": "Registrado em",
    "registeredAt": "Registrado em",
    "lastLogIn": "Último acesso",
    "details": "Detalhes",
    "inDetail": "{name} em detalhes",
    "bsClientNr": "O seu número de cliente Bridgestone",
    "bftClientNr": "Votre numéro de client Best4Tires",
    "acceptTerms": "Aceitar os termos e condições gerais de participação.",
    "newCustomerNumbersInfo": "<span class='text-danger fw-bold'>IMPORTANTE - POR FAVOR, LEIA:</span><br />Ao inserir o número de cliente pela primeira vez, seus dados serão verificados quanto à exatidão e aos nossos critérios de distribuição. A decisão de ativar o(s) número(s) de cliente e vinculá-lo(s) ao seu perfil pode levar até 5 dias úteis.",
    "selectWholeSaler": "no fornecedor (por favor escolha)",
    "cooperation": "MEMBRO / COOPERAÇÃO",
    "error100": "A soma dos valores deve ser igual a 100%.",
    "errorRange": "O valor deve estar entre 0 e 100",
    "btw_number_label": "o número de identificação de IVA da sua empresa",
    "btw_number": "Número de identificação de IVA",
    "mobile": "Número de celular",
    "mobile_label": "Seu número de celular",
    "iban_number": "Número IBAN da SUA EMPRESA",
    "iban_number_label": "Número IBAN",
    "yourCsNr": "Seus números de cliente",
    "yourLastName": "Seu sobrenome",
    "positionLabel": "Selecionar cargo",
    "yourFirstName": "Seu nome",
    "companyLabel": "Empresa",
    "numberOfSetsOfSeasonalTiresBudget": "Armazenagem por ano",
    "contactInfo": "Se você tiver alguma dúvida, entre em contato conosco:",
    "addAnotherCsNumber": "Adicionar um número de cliente bridgestone",
    "activityAndProfile": "SECTOR DE ACTIVIDADE E PERFIL"
  }
}
export default {
  messages,
};
