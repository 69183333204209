const messages = {
  "title": "Witamy w BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Masz już konto?</b><br/>Zaloguj się za pomocą adresu e-mail i hasła.<br/><br/><b>Nowy użytkownik?</b><br/>Jeśli jesteś nowym użytkownikiem, zarejestruj się.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Zaloguj się / Zarejestruj  się",
    "back": "Powrót do logowania",
    "changeLanguage": "Zmień język",
    "start": "Rozpocznij"
  },
  "languageSelect": {
    "label": "Wybór języka",
    "description": "Proszę wybrać swój język"
  }
}

export default {
  messages
}