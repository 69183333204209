import de from "./de";
import nl from "./nl";
import ch from "./ch";
import en from "./en";
import fr from "./fr";
import it from "./it";
//import pt from "./pt";
import API from "../config";

//let lang = {};
let selectedLang = null;
const setUpLang = () => {
  if (API.TENANCY) {
    if (API.TENANCY_LOCAL) {
      selectedLang = API.TENANCY_COUNTRY;
    } else {
      const url = window.location.href;
      const countryMatch = url.match(
        /https:\/\/(?:dev)?-plus-([a-z]{2})\.bsplus\.bridgestone\.eu/i
      );
      if (countryMatch && countryMatch[1]) {
        selectedLang = countryMatch[1];
      } else {
        console.error("Country code could not be extracted from the URL");
      }
    }
  }
  selectedLang = "en";
  //localStorage.setItem("lang", 'fr-FR');
  //localStorage.setItem("currentLocale", 'fr-FR');
  return selectedLang;
};

setUpLang();

export default { de, nl, ch, en, it, fr };
