const messages = {
    "title": "Profilul meu",
    "desc": "Bine ai venit în zona ta personală. Aici poți actualiza sau ajusta <br/>datele tale principale și poți accesa căsuța ta poștală.",
    "descStaff": "Bine ai venit în zona ta personală. Aici poți actualiza sau ajusta datele tale principale.",
    "buttons": {
        "showProfile": "Afișați profilul",
        "userProfile": "Mergi la date principale",
        "mailbox": "Mergi la căsuța poștală"
    },
    "categories": {
        "baseData": {
            "title": "Date principale",
            "desc": "Completează-ți informațiile personale și ale companiei pentru o înregistrare reușită."
        },
        "mailbox": {
            "desc": "Aici găsești mesaje și noutăți importante."
        },
        "bankstatement": {
            "title": "Punctele mele",
            "desc": "Aici poți verifica situația contului tău Bridgestone Plus și poți vedea punctele de recompensă colectate și utilizate."
        }
    },
    "profile": {
        "title": "Datele mele principale",
        "description": "Bine ai venit în zona ta personală. Aici poți actualiza sau ajusta <br/>datele tale principale și poți accesa căsuța ta poștală.",
        "personalData": "Date personale",
        "password": "Parolă",
        "password1": "Parolă (la alegere)*",
        "password2": "Parolă (repetați)*",
        "formOfAddress": "Titlu",
        "choose": "Alege titlul",
        "mr": "Dl.",
        "mrs": "Dna.",
        "diverse": "Diverse",
        "firstname": "Prenumele",
        "surname": "Numele de familie",
        "position": "Funcție",
        "operatingData": "Date ale companiei",
        "company": "Companie",
        "legalForm": "Formă juridică",
        "name": "Nume",
        "streetHouseNr": "Strada și numărul casei",
        "street": "Stradă",
        "postcode": "Cod poștal",
        "location": "Oraș",
        "country": "Țară",
        "de": "Germania",
        "at": "Austria",
        "ch": "Elveția",
        "nl": "Olanda",
        "it": "Italia",
        "language": "Limbă",
        "french": "Franceză",
        "italian": "Italiană",
        "german": "Germană",
        "nederland": "Olandeză",
        "openingHours": "Program & contact",
        "phone": "Telefon",
        "website": "Website",
        "operatingIndicators": "Indicatori de afaceri",
        "tireSale": "Vânzări anuale de anvelope (buc.)",
        "tireSaleLabel": "Vinde anvelope în cantități mari",
        "inches": "Dintre care ≥ 18 inch (în %)",
        "inchesLabel": "Dintre care 18 inch sau mai mari (în %)",
        "segmentSale": "Vânzări de anvelope pe segment (Procentaj buget / mediu / premium)",
        "premBudget": "Premium",
        "midBudget": "Mediu",
        "budBudget": "Buget",
        "distribution": "DISTRIBUȚIE VARĂ / IARNĂ / TOATE SEZOANELE (în %)",
        "tyreSizes": "Număr depozite sezoniere (seturi anvelope)",
        "diffDeliveryAddres": "Adaugă o adresă de livrare",
        "yourEmailAddress": "Adresa ta de e-mail",
        "clientNr": "Numere de client",
        "clientNrSingular": "Număr de client",
        "clientNrContent": "<span class=\"text-danger fw-bold\">IMPORTANT - CITEȘTE:</span><br /><br /> Pentru atribuirea comenzilor tale de anvelope la un distribuitor și/sau Bridgestone, este necesar să <b>introduci numerele tale de client respective.</b><br/><br/>Dacă <b>ai mai multe numere de client la un distribuitor și/sau Bridgestone,</b> cum ar fi pentru magazinul principal și filiale, introdu mai întâi numărul de client al magazinului principal.<br/><br/><b>Vânzările altor numere de client vor fi atribuite magazinului principal.</b>",
        "supplySource": "Sursă de aprovizionare",
        "email": "Adresa de e-mail",
        "staffEmail": "Adresa de e-mail a participantului",
        "registered": "Înregistrat pe",
        "registeredAt": "Înregistrat pe",
        "lastLogIn": "Ultima conectare",
        "details": "Detalii",
        "inDetail": "{name} în detaliu",
        "bsClientNr": "Numărul de client Bridgestone",
        "bftClientNr": "Numărul tău de client Best4Tires",
        "acceptTerms": "Acceptă termenii și condițiile de participare.",
        "newCustomerNumbersInfo": "<span class=\"text-danger fw-bold\">IMPORTANT - CITEȘTE:</span><br />La prima introducere a numărului de client, datele tale vor fi verificate pentru exactitate și conformitate cu criteriile noastre de distribuție. Decizia de a activa numărul de client și de a-l lega la profilul tău poate dura până la 5 zile lucrătoare.",
        "selectWholeSaler": "la furnizor (te rog alege)",
        "cooperation": "MEMBRU / COOPERARE",
        "error100": "Suma valorilor trebuie să fie 100%.",
        "errorRange": "Valoarea trebuie să fie între 0 și 100",
        "btw_number_label": "Numărul de identificare TVA al companiei tale",
        "btw_number": "Număr de identificare TVA",
        "mobile": "Număr de telefon",
        "mobile_label": "Numărul tău de telefon mobil",
        "iban_number": "Număr IBAN al companiei tale",
        "iban_number_label": "Număr IBAN",
        "yourCsNr": "Numerele tale de client",
        "yourLastName": "Numele tău",
        "positionLabel": "Selectează o funcție",
        "yourFirstName": "Prenumele tău",
        "companyLabel": "Companie",
        "numberOfSetsOfSeasonalTiresBudget": "Depozitare anuală",
        "contactInfo": "Dacă ai întrebări, te rugăm să ne contactezi:",
        "addAnotherCsNumber": "Adaugă încă un număr de client Bridgestone",
        "activityAndProfile": "ACTIVITATE ȘI PROFIL"
    }
}

export default {
    messages
}