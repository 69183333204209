// mixins/languageMixin.js
import languages from '@/data/languages.json'

export default {
  computed: {
    currentLocale() {
      return this.$i18n.locale // Gets the currently selected language
    },
    currentLanguageShort() {
      // Finds the language object matching the current locale and returns its 'short' code
      const currentLanguage = languages.find((lang) => lang.locale === this.$i18n.locale)
      return currentLanguage ? currentLanguage.short : null
    }
  },
  methods: {
    setLocale(locale) {
      const setting = 'locale'
      this.$i18n.locale = locale // Updates the locale globally
      this.$store.commit('CHANGE_SETTING', { setting, value: locale })
    }
  }
}
