const messages = {
  "title": "Velkommen til BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Har du allerede en konto?</b><br/>Log ind med din e-mailadresse og din adgangskode.<br/><br/><b>Er du ny her?</b><br/>Hvis du er en ny bruger, bedes du registrere dig.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Log ind / Registrer dig",
    "back": "Tilbage til login",
    "changeLanguage": "Skift sprog",
    "start": "Start"
  },
  "languageSelect": {
    "label": "Sprogvalg",
    "description": "Vælg venligst dit sprog "
  }
}

export default {
  messages
}