const messages = {
    "fleetWithOptions": "Você não pode escolher a frota se tiver selecionado opções.",
    "addProduct": "Seu produto foi adicionado ao carrinho.",
    "addProducts": "Seus produtos foram adicionados ao carrinho.",
    "profileSaved": "Os dados do seu perfil foram salvos.",
    "canAddProduct": "Você não tem pontos suficientes.",
    "customerNumberSaved": "Número do cliente salvo.",
    "customerNumberExist": "Número do cliente disponível.",
    "complaintForm": "Sua solicitação foi aceita. Aguarde uma resposta.",
    "openHourSaved": "O horário de funcionamento foi salvo.",
    "changeParentId": "Você não pode modificar a filial com o número do cliente principal se ela tiver filiais subordinadas.",
    "requestSent": "A solicitação foi enviada.",
    "completeProfileRequired": "Você deve completar seu perfil 100% para continuar comprando na loja.",
    "accessDenied": "Acesso negado.",
    "incomplete_address": "O endereço está incompleto. Por favor, verifique.",
    "waitMoment": "Aguarde um momento.",
    "downloadFinished": "Download concluído.",
    "selectOneField": "Selecione um campo.",
    "noVoucher": "O voucher não foi encontrado.",
    "cannotAddCsNumber": "Você não pode adicionar um número de cliente."
}

export default {
    messages
}
