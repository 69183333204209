const mutations = {
  SET_PERCENTAGE(state, payload) {
    state.profilePercentageNum = payload;
  },
  SET_PENDING(state, payload) {
    state.isPending = payload;
  },

  SET_USER(state, payload) {
    state.user = payload;
  },

  SET_ADDRESSES(state, payload) {
    state.addresses = payload;
  },

  SET_ADDRESS_SELECTED(state, payload) {
    state.addressSelected = payload;
  },

  SET_PROFILE(state, payload) {
    state.inUnknownGroup = payload.group.gka === "Unknown";
    state.profile = payload;
  },

  SET_ATTRIBUTE_OPTIONS(state, payload) {
    if (payload.option == -1) {
      const index = state.attribute_options.filter(
        (v) => v.attribute === payload.attribute
      );

      state.attribute_options.splice(index, 1);
    } else {
      state.attribute_options.push(payload);
    }
  },

  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },

  SET_PRODUCTS(state, payload) {
    state.products = payload;
  },

  SET_PRODUCTS_META(state, payload) {
    state.productsMeta = payload;
  },

  SET_PRODUCTS_PAGE_NUMBER(state, payload) {
    state.productsPageNumber = payload;
  },

  SET_PRODUCT(state, payload) {
    state.product = payload;
  },

  SET_BEST_SOLD_PRODUCTS(state, payload) {
    state.bestSoldProducts = payload;
  },

  SET_SEASONAL_PRODUCTS(state, payload) {
    state.seasonalProducts = payload;
  },

  SET_SOON_AFFORDABLE_PRODUCTS(state, payload) {
    state.soonAffordableProducts = payload;
  },

  SET_BANK_STATEMENT(state, payload) {
    state.bankStatement = payload;
  },
  SET_LAST_BANK_STATEMENT(state, payload) {
    state.lastBankStatement = payload;
  },
  SET_PRODUCT_REQUEST_SENT(state, payload) {
    state.productRequestSent = payload;
  },
  SET_CATEGORIES_REQUEST_SENT(state, payload) {
    state.categoriesRequestSent = payload;
  },
  SET_JOB_POSITIONS(state, payload) {
    state.jobPositions = payload;
  },
  SET_TRAININGS(state, payload) {
    state.trainings = payload;
  },
  SET_TRAINING(state, payload) {
    state.training = payload;
  },
  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
  SET_TRAINING_RESULT(state, payload) {
    state.trainingResult = payload;
  },
  SET_POINTS(state, payload) {
    state.hasPoints = payload;
  },
  SET_END_QUIZ_DATE(state, payload) {
    state.endQuizDate = payload;
  },
  SET_B_TRAININGS(state, payload) {
    state.btrainings = payload;
  },
  SET_B_TRAINING(state, payload) {
    state.btraining = payload;
  },
  SET_B_TRAINING_DETAILS(state, payload) {
    state.bTrainingDetails = payload;
  },
  SET_B_TRAINING_COSTS(state, payload) {
    state.bTrainingCosts = payload;
  },
  SET_B_TRAINING_TYPES(state, payload) {
    state.bTrainingTypes = payload;
  },
  SET_B_TRAINING_RESULT(state, payload) {
    state.bTrainingResult = payload;
  },
  SET_USER_SLIDES(state, payload) {
    state.userSlides = payload;
  },
  SET_TEXT_PAGES(state, payload) {
    state.textPages = payload;
  },
  SET_TEXT_PAGE(state, payload) {
    state.textPage = payload;
  },
  SET_PICKUP_LOCATIONS(state, payload) {
    state.pickupLocations = payload;
  },
  SET_WATCHED_VIDEO(state, payload) {
    state.watchedVideo = payload;
  },
  SET_MESSAGES(state, payload) {
    state.messages = payload;
  },
  SET_UNREAD_MESSAGES(state, payload) {
    state.unreadMessages = payload;
  },
  SET_TOAST(state, payload) {
    if (payload) {
      state.toast.push(payload);
    } else {
      state.toast = [];
    }
  },
  SET_USER_ORDERS(state, payload) {
    state.userOrders = payload;
  },
  SET_USER_ORDER(state, payload) {
    state.userOrder = payload;
  },
  SET_USER_PROMOTIONS(state, payload) {
    state.userPromotions = payload;
  },
  SET_NEWS_FEEDS(state, payload) {
    state.newsFeeds = payload;
  },
  SET_CURRENT_NEWS_FEED(state, payload) {
    state.currentNewsFeed = payload;
  },
  SET_USER_PRODUCTS(state, payload) {
    state.userProducts = payload;
  },
  SET_WHOLESALERS(state, payload) {
    state.wholesalers = payload;
  },
  SET_ALL_WHOLESALERS(state, payload) {
    state.allWholesalers = payload;
  },
  SET_COOPERATIONS(state, payload) {
    state.cooperations = payload;
  },
  SET_KICKBACK_INFO(state, payload) {
    state.kickbackInfo = payload;
  },
  SET_SINGLE_HUNTER(state, payload) {
    state.singleHunter = payload;
  },
  SET_PRICES(state, payload) {
    state.prices = payload;
  },
  SET_PRICE_CATEGORIES(state, payload) {
    state.priceCategories = payload;
  },
  SET_SINGLE_PRICE_LIST(state, payload) {
    state.singlePriceList = payload;
  },
  SET_DAMAGE_TYPES(state, payload) {
    state.damageTypes = payload;
  },
  SET_TYRE_POSITIONS(state, payload) {
    state.tyrePositions = payload;

    state.tyrePositionChildren = state.tyrePositions.flatMap((item) => [
      ...item.children,
    ]);
  },
  SET_VEHICLE_TYRES(state, payload) {
    state.vehicleTyres = payload;
  },
  SET_TYRE_WEARS(state, payload) {
    state.tyreWears = payload;
  },
  SET_SALUTATIONS(state, payload) {
    state.salutations = payload;
  },
  SET_POINTS_BY_CUSTOMER_NUMBER(state, payload) {
    state.pointsByCustomerNumber = payload;
  },
  SET_CERTIFICATE_TEXTS(state, payload) {
    state.certificateTexts = payload;
  },
  SET_MENU(state, payload) {
    state.menu = payload.map((item) => {
      return {
        text: item.title,
        name: item.name,
      };
    });
  },
  SET_MEDIA_CATEGORIES(state, payload) {
    state.mediaCategories = payload;
  },
  SET_SINGLE_MEDIA(state, payload) {
    state.singleMedia = payload;
  },
  SET_SINGLE_MEDIA_CATEGORY(state, payload) {
    state.singleMediaCategory = payload;
  },
  SET_ALL_DEALERS_NUMBER(state, payload) {
    state.allDealers = payload;
  },
  SET_WSH_SELECT(state, payload) {
    state.wshSelect = payload;
  },
  SET_DEFAULT_BANNERS(state, payload) {
    state.defaultBanners = payload;
  },
  SET_DEALER_APPS(state, payload) {
    state.dealerApps = payload;
  },
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  SET_MEDIA_FILES(state, payload) {
    state.mediaFiles = payload;
  },
  SET_BROCHURE_FILES(state, payload) {
    state.brochureFiles = payload;
  },
  SET_PAGINATION_LINKS(state, payload) {
    state.paginationLinks = payload;
  },
  SET_TOTAL_MEDIA_FILES(state, payload) {
    state.totalMediaFiles = payload;
  },
  SET_TYRE_LIMIT(state, payload) {
    state.tyreLimit = payload;
  },
  SET_PROMOTIONS(state, payload) {
    state.promotions = payload;
  },
  SET_BROCHURE_CATEGORIES(state, payload) {
    state.brochureCategories = payload;
  },
  SET_USER_CART(state, payload) {
    state.cart = payload;
  },
  SET_USER_MAIN_BOXES(state, payload) {
    state.mainBoxes = payload;
  },
  SET_FETCH_MENU(state, payload) {
    state.fetchMenu = payload;
  },
  SET_FETCH_LEFT_COLUMN(state, payload) {
    state.fetchLeftColumn = payload;
  },
  SET_CONDITION_MODAL(state, payload) {
    state.showConditionModal = payload;
  },
  SET_INITIAL_SURVEY_STEP(state, payload) {
    state.initialSurveyStep = payload;
  },
  SET_PARENT_ATTRIBUTES(state, payload) {
    state.parentAttributes = payload;
  },
  SET_INITIAL_PARENT_ATTRIBUTES(state, payload) {
    state.initialParentAttributes = payload;
  },
  SET_BUSSINESS_UNITS(state, payload) {
    state.bussinessUnits = payload;
  },
  SET_PROFILE_IS_PENDING(state, payload) {
    state.profileIsPending = payload;
  },
  SET_ME_IS_PENDING(state, payload) {
    state.meIsPending = payload;
  },

  // tenancy
  SET_IS_LITE_VERSION(state, value) {
    state.isLiteVersion = value;
  },
  SET_AVAILABLE_MODULES(state, modules) {
    state.availableModules = modules;
  },
  SET_TENANCY_ENABLED(state, value) {
    state.tenancyEnabled = value;
  },
  SET_TENANT_ID(state, tenantId) {
    state.tenantId = tenantId;
  },
  SET_CURRENT_LOCALE(state, data) {
    state.currentLocale = data;
  },
};

export { mutations };
