const messages = {
    "titleColorRev": "true",
    "buttons": {
        "pending": "In afwachting van",
        "open": "Open",
        "requestAccess": "Toegang aanvragen"
    },
    "my": "Mijn apps",
    "directRoute": "Uw directe toegang tot alle Bridgestone-webapplicaties",
    "welcomeMessage": "Welkom bij BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Profiel weergeven",
    "newMessages": "Nieuwe berichten in je inbox",
    "myApps": "Mijn appls",
    "allApps": "Alle apps",
    "myAppsDescription": "Alle Bridgestone webapplicaties waarvoor u al geregistreerd bent.",
    "allAppsDescription": "Alle Bridgestone webapplicaties die voor u interessant kunnen zijn. ",
    "myProfile": {
        "welcomeMessage": "Welkom in uw persoonlijke omgeving. Hier kun u uw gegevens bijwerken en uw inbox bekijken.",
        "masterData": "Gegevens",
        "completeDetails": "Vul uw persoonlijke gegevens en bedrijfsgegevens in voor een succesvolle registratie.",
        "goToMasterData": "Ga naar uw gegevens",
        "goToInbox": "Ga naar inbox"
    },
    "masterData": {
        "welcomeMessage": "Welkom in uw persoonlijke ruimte. Hier kun u uw gegevens bijwerken.",
        "personalDetails": "Persoonlijke gegevens",
        "companyDetails": "Bedrijfsgegevens",
        "emailAddress": "E-mailadres",
        "title": "Titel",
        "surname": "Achternaam",
        "firstName": "Voornaam",
        "jobPosition": "Functie",
        "streetAndHouseNumber": "Straat en huisnummer",
        "postalCode": "POSTCODE",
        "city": "Stad",
        "country": "Land",
        "legalStructure": "Wettelijke structuur",
        "customerNumber": "Bridgestone-klantnummer",
        "importantPleaseRead": "Belangrijk, gelieve te lezen",
        "customerNumberInfo": "Om je bandenbestellingen correct toe te wijzen aan groothandels en/of Bridgestone, is het essentieel dat je de respectieve klantnummers doorgeeft.",
        "multipleCustomerNumbers": "Als u meerdere klantnummers hebt bij een groothandel en/of Bridgestone, bijvoorbeeld voor uw hoofdbedrijf en uw filialen, geef dan eerst het klantnummer van het hoofdbedrijf door.",
        "salesAssignment": "De verkopen die aan de extra klantnummers gekoppeld zijn, worden dan toegewezen aan uw hoofdbedrijf.",
        "supplier": "Leverancier",
        "addAnotherCustomerNumber": "Een Bridgestone-klantnummer toevoegen",
        "save": "Opslaan",
        "delete": "Verwijderen"
    },
    "inbox": {
        "welcomeMessage": "Hier vind u belangrijke meldingen en nieuws.",
        "sender": "Afzender",
        "subject": "Onderwerp",
        "date": "Datum",
        "formConsulting": "Formulier Consulting - Bedankt voor het verwerken van het formulier",
        "back": "Terug"
    },
    "legalAndContact": {
        "cookieSettings": "Cookie-instellingen",
        "dataPolicy": "Gegevensbeleid",
        "termsOfUse": "Gebruiksvoorwaarden",
        "helpAndContact": "Hulp en contact"
    },
    "logInSignUp": {
        "logIn": "Inloggen",
        "signUp": "Registreren",
        "logInMessage": "Log in met uw e-mailadres en wachtwoord.",
        "signUpMessage": "Als u een nieuwe gebruiker bent, registreer je dan."
    }
}

export default {
    messages
}