const messages = {
    "fleetWithOptions": "Sa ei saa valida laevastikku, kui oled valinud valikud.",
    "addProduct": "Teie toode on ostukorvi lisatud.",
    "addProducts": "Teie tooted on ostukorvi lisatud.",
    "profileSaved": "Teie profiili andmed on salvestatud.",
    "canAddProduct": "Sul ei ole piisavalt punkte.",
    "customerNumberSaved": "Kliendi number salvestatud.",
    "customerNumberExist": "Kliendi number on saadaval.",
    "complaintForm": "Teie taotlus on vastu võetud. Palun oodake vastust.",
    "openHourSaved": "Avamisajad on salvestatud.",
    "changeParentId": "Te ei saa muuta haru, millel on ülemuse kliendi number, kui sellel on alluvad harud.",
    "requestSent": "Päring saadetud.",
    "completeProfileRequired": "Oma profiili täitmiseks peate täitma 100%, et jätkata poes ostlemist.",
    "accessDenied": "Juurdepääs keelatud.",
    "incomplete_address": "Aadress on puudulik. Palun kontrollige.",
    "waitMoment": "Palun oodake hetk.",
    "downloadFinished": "Allalaadimine lõppenud.",
    "selectOneField": "Valige väli.",
    "noVoucher": "Voucherit ei leitud.",
    "cannotAddCsNumber": "Te ei saa lisada kliendi numbrit."
}

export default {
    messages
}
