// tenantConfig.js
export const tenantConfigs = {
    tenants: {
        DEFAULT: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        DE: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: false,
            showLogo: false,
            modules: []
        },
        CH: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: false,
            showLogo: false,
            modules: []
        },
        NL: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--rotated",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        FR: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        PT: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        ES: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        BE: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        AT: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        FI: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        SE: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        DK: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        IE: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        GB: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        IT: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        RO: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        EE: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        LV: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        LT: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        CZ: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        HU: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        PL: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        },
        SK: {
            topLogo: require('@/assets/img/B_MarkLogo.svg'),
            logo: require('@/assets/img/bs_logo_solution.svg'),
            background: "background--default",
            showTopLogo: true,
            showLogo: true,
            modules: []
        }
    }
};
