const messages = {
    "title": "Hem",
    "welcomeMessage": "Välkommen till BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Hem",
    "search": "Sök",
    "sortBy": "Sortera efter",
    "seeMore": "Se mer om"
}

export default {
    messages
}
