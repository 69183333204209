const messages = {
    "titleColorRev": "true",
    "buttons": {
        "pending": "Čeká na vyřízení",
        "open": "Otevřít",
        "requestAccess": "Požádat o přístup"
    },
    "my": "Moje aplikace",
    "directRoute": "Vaše přímé odkazy na webové aplikace Bridgestone.",
    "welcomeMessage": "Vítejte v BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Zobrazit profil",
    "newMessages": "Nová zpráva ve schránce",
    "myApps": "Moje aplikace",
    "allApps": "Všechny aplikace",
    "myAppsDescription": "Všechny webové aplikace Bridgestone, ve kterých jste registrováni.",
    "allAppsDescription": "Všechny webové aplikace Bridgestone, které by pro vás mohly být relevantní.",
    "myProfile": {
        "welcomeMessage": "Vítejte ve své osobní oblasti. Zde můžete aktualizovat nebo upravit své údaje a zobrazit si svou schránku.",
        "masterData": "Základní data",
        "completeDetails": "Vyplňte své osobní a firemní údaje pro zajištění správné registrace.",
        "goToMasterData": "Přejít na moje data",
        "goToInbox": "Přejít do schránky"
    },
    "masterData": {
        "welcomeMessage": "Vítejte ve své osobní oblasti. Zde můžete aktualizovat nebo upravit své základní údaje.",
        "personalDetails": "Osobní údaje",
        "companyDetails": "Údaje o společnosti",
        "emailAddress": "E-mailová adresa",
        "title": "Oslovení",
        "surname": "Příjmení",
        "firstName": "Jméno",
        "jobPosition": "Pracovní pozice",
        "streetAndHouseNumber": "Adresa společnosti",
        "postalCode": "PSČ",
        "city": "Město",
        "country": "Země",
        "legalStructure": "Právní forma",
        "customerNumber": "Zákaznické číslo Bridgestone",
        "importantPleaseRead": "Důležité, prosím přečtěte si",
        "customerNumberInfo": "Aby bylo možné správně přiřadit vaše objednávky pneumatik různým dodavatelům, je nutné uvést vaše zákaznické číslo.",
        "multipleCustomerNumbers": "Pokud máte více zákaznických čísel u jednoho dodavatele, například pro vaši hlavní společnost a pobočky, nejprve uveďte číslo hlavní společnosti.",
        "salesAssignment": "Prodeje spojené s dalšími zákaznickými čísly budou přiřazeny vaší hlavní společnosti.",
        "supplier": "Dodavatel",
        "addAnotherCustomerNumber": "Přidat zákaznické číslo",
        "save": "Uložit",
        "delete": "Smazat"
    },
    "inbox": {
        "welcomeMessage": "Zde najdete oznámení a důležité novinky.",
        "sender": "Odesílatel",
        "subject": "Předmět",
        "date": "Datum",
        "formConsulting": "Formulář přijat - Děkujeme za jeho vyplnění",
        "back": "Zpět"
    },
    "legalAndContact": {
        "cookieSettings": "Nastavení cookies",
        "dataPolicy": "Zásady ochrany osobních údajů",
        "termsOfUse": "Podmínky používání",
        "helpAndContact": "Pomoc a kontakt"
    },
    "logInSignUp": {
        "logIn": "Přihlásit se",
        "signUp": "Registrovat se",
        "logInMessage": "Přihlaste se pomocí své e-mailové adresy a hesla.",
        "signUpMessage": "Pokud jste nový uživatel, prosím zaregistrujte se."
    }
}

export default {
    messages
}