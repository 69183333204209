<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <button type="button" class="btn-close" @click="$emit('close', false)">
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body"> </slot>
      </section>

      <section class="text-center py-3">
        <button type="button" class="btn btn-info" @click="$emit('close', false)">
          {{ $t("global.buttons.close") }}
        </button>
      </section>
    </div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #ffffff;
  box-shadow: 2px 2px 20px 1px;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  max-height: 300px;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #39c0ed;
  justify-content: space-between;
  padding: 15px;
  display: flex;
}

.modal-body {
  position: relative;
  padding: 0;
  margin: 20px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  color: #39c0ed;
  background: transparent;
}
</style>
