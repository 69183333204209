const messages = {
    "title": "Posteingang",
    "desc": "Willkommen im Posteingang. Hier findest du wichtige Mitteilungen und Neuigkeiten.",
    "newMessages": "Neue Nachrichten in deinem Posteingang",
    "link": "Nachrichten anzeigen",
    "showMessages": "Nachrichten anzeigen",
    "message": {
        "sender": "Absender",
        "thread": "Betreff",
        "date": "Datum"
    },
    "sender": {
        "system": "BRIDGESTONE PLUS"
    },
    "back": "Zurück"
}

export default {
    messages
}