import store from "@/store";
import config from "@/config";
import { TENANCY_SETTINGS } from "@/config";

/**
 * Maps country codes to locales.
 */
const countryToLocaleMap = {
    DK: { default: "da-DK", available: ["da-DK"] },
    FI: { default: "fi-FI", available: ["fi-FI"] },
    SE: { default: "sv-SE", available: ["sv-SE"] },
    AT: { default: "de-AT", available: ["de-AT"] },
    PT: { default: "pt-PT", available: ["pt-PT"] },
    ES: { default: "es-ES", available: ["es-ES"] },
    FR: { default: "fr-FR", available: ["fr-FR"] },
    BE: { default: "fr-BE", available: ["fr-BE", "nl-BE"] },
    DE: { default: "de-DE", available: ["de-DE"] },
    NL: { default: "nl-NL", available: ["nl-NL"] },
    CH: { default: "de-CH", available: ["de-CH", "fr-CH", "it-CH"] }, // Switzerland example
    GB: { default: "en-GB", available: ["en-GB"] },
    IE: { default: "en-IE", available: ["en-IE"] },
    IT: { default: "it-IT", available: ["it-IT"] },
    RO: { default: "ro-RO", available: ["ro-RO"] },
    EE: { default: "ee-EE", available: ["ee-EE"] },
    LT: { default: "lt-LT", available: ["lt-LT"] },
    LV: { default: "lv-LV", available: ["lv-LV"] },
    CZ: { default: "cz-CZ", available: ["cz-CZ"] },
    PL: { default: "pl-PL", available: ["pl-PL"] },
    HU: { default: "hu-HU", available: ["hu-HU"] },
    SK: { default: "sk-SK", available: ["sk-SK"] }
};

/**
 * Extracts the country code from the provided URL.
 * @param {string} url - The URL to extract the country code from.
 * @returns {string|null} - The extracted country code or null if not found.
 */
const extractCountryCode = (url) => {
    if (!TENANCY_SETTINGS.TENANCY) {
        console.warn("TENANCY is not enabled. No country code will be extracted.");
        return null;
    }

    if (TENANCY_SETTINGS.TENANCY_LOCAL) {
        return TENANCY_SETTINGS.TENANCY_COUNTRY;
    }
    const countryMatch = url.match(/https:\/\/(?:dev-)?plus-([a-z]{2})\.bsplus\.bridgestone\.eu/i);
    if (countryMatch && countryMatch[1]) {
        return countryMatch[1].toUpperCase();
    } else {
        //console.error("Country code could not be extracted from the URL.");
        return null;
    }
};

/**
 * Constructs the base URL by replacing the tenant placeholder with the country code.
 * @param {string} tenant - The country code to use.
 * @returns {string} - The constructed base URL.
 */
export function getBaseURL(tenant) {
    if (!tenant) {
        console.warn("No tenant provided. Returning default base URL.");
        return config.URL;
    }

    let baseURL = `${config.URL}`;
    if (TENANCY_SETTINGS.TENANCY) {
        tenant = tenant.toLowerCase();
        baseURL = baseURL.replace("{tenant}", tenant);
    }
    return baseURL;
}

/**
 * Loads the tenant configuration into the store based on the detected country code.
 * @param {string} countryCode - The detected country code.
 * @returns {Promise<void>}
 */
const loadTenantConfig = async (countryCode) => {
    if (!countryCode) {
        console.error("No country code provided. Cannot load tenant configuration.");
        return;
    }
    try {
        await store.dispatch("loadTenantConfig", countryCode);
        console.log("Loaded Tenant Config from Store:", {
            // liteVersion: store.getters.isLiteVersion,
            availableModules: store.getters.availableModules,
            // tenancyEnabled: store.getters.tenancyEnabled,
            tenantId: store.getters.tenantId,
        });
    } catch (error) {
        console.error(`Error loading tenant configuration for country: ${countryCode}`, error);
    }
};

/**
 * Detects the country code, sets up locale, and loads the tenant configuration.
 * Stores the default locale in localStorage if it is not already set.
 * @returns {Promise<{countryCode: string, baseURL: string, defaultLocale: string, availableLocales: string[]}>}
 */
const detectCountryAndLoadConfig = async () => {
    const url = window.location.href;
    //const url ='https://plus-pt.bsplus.bridgestone.eu'
    const countryCode = extractCountryCode(url);

    // Validate if the country code exists in the map
    if (!countryCode || !countryToLocaleMap[countryCode]) {
        throw new Error(`Country code ${countryCode || "undefined"} is not defined in countryToLocaleMap.`);
    }

    const countryLocales = countryToLocaleMap[countryCode];
    const defaultLocale = countryLocales.default;
    const availableLocales = countryLocales.available;

    console.log("Detected Country Code / Tenant:", countryCode);
    console.log("Default Locale:", defaultLocale);
    console.log("Available Locales:", availableLocales);

    // Check if a locale is already set in localStorage
    const storedLocale = localStorage.getItem("currentLocale");
    if (!storedLocale) {
        // If no locale is set, store the default locale
        console.log(`No locale found in localStorage. Setting default: ${defaultLocale}`);
        localStorage.setItem("currentLocale", defaultLocale);
    } else {
        console.log(`Locale already set in localStorage: ${storedLocale}`);
    }

    // Load tenant configuration from the store
    await loadTenantConfig(countryCode);

    const baseURL = getBaseURL(countryCode);
    console.log("Constructed Base URL:", baseURL);

    return { countryCode, baseURL, defaultLocale, availableLocales };
};

export { detectCountryAndLoadConfig, extractCountryCode, loadTenantConfig, countryToLocaleMap };
