const messages = {
    "title": "Acasă",
    "welcomeMessage": "Bine ați venit la  BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Acasă",
    "search": "Căutare",
    "sortBy": "Sortare după",
    "seeMore": "Vedeți mai multe"
}

export default {
    messages
}
