<template>
  <div v-if="isDutch">
    <MDBRow>
      <MDBCol col="12" md="6" xl="4" class="mb-4" v-for="(data, i) in wholesalerData" :key="i">
        <Card :data="data" :linkText="data.linkText" :simpleLink="true" target="_blank" class="h-100" />
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import Card from "@/components/General/Card/index.vue";
import i18n from "@/plugins/i18n";
import { computed } from "vue";

const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");

const wholesalerData = [
  {
    name: null,
    link: "mailto:info@vandenban.nl",
    description: `Ravenseweg 13M, 3223 LM Hellevoetsluis<br/><b>E-Mail:</b> info@vandenban.nl<br/>
<b>${i18n.global.t("account.profile.phone")}:</b> 0181-331444`,
    image: require("@/assets/img/contact/wholesalers/vdb_logo.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: null,
    link: "mailto:sales@rbi.nl",
    description: `Nijverheidsweg 50, 3771 ME Barneveld<br/><b>E-Mail:</b> sales@rbi.nl<br/>
<b>${i18n.global.t("account.profile.phone")}:</b> 088-2254018`,
    image: require("@/assets/img/contact/wholesalers/redjik_logo.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: null,
    link: "mailto:info@inter-sprint.nl",
    description: `Distriboulevard 25, 4782 PV Moerdijk<br/><b>E-Mail:</b> info@inter-sprint.nl<br/>
<b>${i18n.global.t("account.profile.phone")}:</b> 0168-392000`,
    image: require("@/assets/img/contact/wholesalers/inter_sprint_logo.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: null,
    link: "mailto:verkoop@bandenexpress.nl",
    description: `Nijverheidsweg 50, 3771 ME Barneveld<br/><b>E-Mail:</b> verkoop@bandenexpress.nl<br/>
<b>${i18n.global.t("account.profile.phone")}:</b> 088-2055516`,
    image: require("@/assets/img/contact/wholesalers/baden_express_logo.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: null,
    link: "mailto:info@tyrenet.nl",
    description: `De Heinen 28, 5371 MJ Ravenstein<br/><b>E-Mail:</b> info@tyrenet.nl<br/>
<b>${i18n.global.t("account.profile.phone")}:</b> 0486-415116`,
    image: require("@/assets/img/contact/wholesalers/tyre_net_logo.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: null,
    link: "mailto:info@wheeltyre.nl",
    description: `Distriboulevard 15, 4761 RZ Zevenbergen (Gem. Moerdijk)<br/><b>E-Mail:</b> info@wheeltyre.nl<br/>
<b>${i18n.global.t("account.profile.phone")}:</b> 06-15500920`,
    image: require("@/assets/img/contact/wholesalers/wheel_tyre_logo.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
];
</script>

<style scoped lang="scss">
.cto {
  max-width: 328px;
  width: 100%;
}
</style>
