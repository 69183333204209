<template>
  <MDBContainer class="p-0">
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle class="mb-5">{{
          $t("pageName.orderSummary")
          }}</MDBCardTitle>
        <MDBCardText class="pb-0">
          <h6 class="mb-4">{{ $t("shop.selectedAddress") }}</h6>
          <div class="row mb-3">
            <div class="col-md-8">
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("account.profile.streetHouseNr") }}</div>
                <div class="col-md-8">{{ address.street }}</div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("account.profile.postcode") }}:</div>
                <div class="col-md-8">{{ address.postal_code }}</div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("account.profile.location") }}</div>
                <div class="col-md-8">{{ address.city }}</div>
              </div>
              <div class="row gx-2">
                <div class="col-md-4">{{ $t("account.profile.country") }}</div>
                <div class="col-md-8">
                  {{ $t(`profile.${address.country}`) }}
                </div>
              </div>
            </div>
          </div>
          <h6>{{ $t("shop.selectedProducts") }}</h6>
          <div class="my-3" v-if="cart?.items?.length > 0">
            <MDBTable>
              <thead>
                <tr>
                  <th scope="col">{{ $t("product") }}</th>
                  <th scope="col">{{ $t("attributes") }}</th>
                  <th scope="col">{{ $t("quantity") }}</th>
                  <th scope="col">{{ $t("shop.cart.pointsSum") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in cart.items" :key="`product_${i}`">
                  <th scope="row">
                    <router-link :to="{
                      name: 'productDetails',
                      params: {
                        id: item.product.id,
                      },
                    }">
                      {{ item.product.name }}
                    </router-link>
                  </th>
                  <th scope="row">
                    {{ showAttributes(item) }}
                  </th>
                  <th scope="row">
                    {{ item.quantity }}
                  </th>
                  <th scope="row">{{ item.points }}</th>
                </tr>
                <tr>
                  <th scope="row" colspan="2"></th>
                  <th class="border border-y-white" scope="row" colspan="2">
                    <p>
                      <strong>{{ cart.total }} </strong>
                      {{ $t("shop.pointsTotal") }}
                    </p>
                    <p>
                      <strong>{{ user.points - cart.total }} </strong>
                      {{ $t("shop.cartRemainPoints") }}
                    </p>
                  </th>
                </tr>
              </tbody>
            </MDBTable>
          </div>
        </MDBCardText>
      </MDBCardBody>
      <MDBCardFooter class="text-muted">
        <MDBBtn color="success" @click="createOrder()" :disabled="!overPoints">{{ $t("shop.placeOrder") }}</MDBBtn>
        <MDBBtn color="primary" @click="back">{{ $t("back") }}</MDBBtn>
      </MDBCardFooter>
    </MDBCard>
  </MDBContainer>
</template>

<script setup>
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  MDBBtn,
  MDBTable,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const store = useStore();
const router = useRouter();
store.dispatch("me");
store.dispatch("loadUnreadMessages");
store.dispatch("fetchUserShopCart");

const cart = computed(() => store.getters.getCart);
const addresses = computed(() => store.getters.getUserAddresses);
const address = computed(() => {
  let selectedAddress = store.getters.getSelectedAddress;
  let selected = addresses.value.find((item) => item.id == selectedAddress);

  return selected;
});

const user = computed(() => store.getters.getMe);
const overPoints = computed(() => {
  return user.value.points >= cart.value?.total;
});

const back = () => {
  router.go(-1);
};

const showAttributes = (product) => {
  if (product.product_variant) {
    if (product.product_variant.title) {
      return product.product_variant.title
    } else {
      return JSON.parse(product.product_variant.attribute_option)
        .map((item) => `${item.label}: ${item.option}`)
        .join(", ")
    }
  }
  return "-";
};

const createOrder = async () => {
  const orderData = {
    token: cart.value.token,
    address_id: address.value.id,
  };

  const response = await store.dispatch("createOrder", orderData);

  if (response && response.status != 200) {
    toast(response.data.message, {
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    router.push({ name: "orderComplete" });
  }
};
</script>

<style lang="scss" scoped>
.border-y-white {
  border-right-color: #fff !important;
  border-left-color: #fff !important;
}
</style>
