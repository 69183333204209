const messages = {
    "fleetWithOptions": "Nemôžete vybrať flotilu, ak ste vybrali možnosti.",
    "addProduct": "Váš produkt bol pridaný do košíka.",
    "addProducts": "Vaše produkty boli pridané do košíka.",
    "profileSaved": "Údaje o profile boli uložené.",
    "canAddProduct": "Nemáte dostatok bodov.",
    "customerNumberSaved": "Číslo zákazníka bolo uložené.",
    "customerNumberExist": "Číslo zákazníka je dostupné.",
    "complaintForm": "Vaša žiadosť bola prijatá. Počkajte prosím na odpoveď.",
    "openHourSaved": "Otváracie hodiny boli uložené.",
    "changeParentId": "Nemôžete zmeniť pobočku s nadradeným zákazníckym číslom, ak má podriadené pobočky.",
    "requestSent": "Žiadosť bola odoslaná.",
    "completeProfileRequired": "Musíte vyplniť svoj profil na 100 %, aby ste mohli pokračovať v nakupovaní v obchode.",
    "accessDenied": "Prístup odmietnutý.",
    "incomplete_address": "Adresa je neúplná. Skontrolujte ju, prosím.",
    "waitMoment": "Počkajte chvíľu.",
    "downloadFinished": "Sťahovanie dokončené.",
    "selectOneField": "Vyberte pole.",
    "noVoucher": "Poukažka nebola nájdená.",
    "cannotAddCsNumber": "Nemôžete pridať zákaznícke číslo."
}

export default {
    messages
}
