const messages = {
  "title": "Vitajte v BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Máte už účet?</b><br/>Prihláste sa pomocou svojej e-mailovej adresy a hesla.<br/><br/><b>Ste tu nový?</b><br/>Ak ste nový používateľ, zaregistrujte sa prosím.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Prihlásiť sa / Registrovať",
    "back": "Späť na prihlásenie",
    "changeLanguage": "Zmeniť jazyk",
    "start": "Začať"
  },
  "languageSelect": {
    "label": "Výber jazyka",
    "description": "Vyberte si prosím svoj jazyk"
  }
}

export default {
  messages
}