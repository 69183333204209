const messages = {
  seeAll: "Ver todo",
  clear: "Borrar",
  back: "Atrás",
  save: "Guardar",
  close: "Cerrar",
  of: "De",
  to: "à",
  copyrights: "© {date} Bridgestone Solutions. Todos los derechos reservados.",
  pageNotFound: "Page non trouvée",
  accessDenied: "Accès refusé",
  search: "Buscar",
  buttons: {
    export: "Export",
    delete: "Borrar",
    save: "Guardar",
    cancel: "Cancelar",
    next: "Continuar",
    back: "Atrás",
    skip: "Omitir",
    send: "Enviar",
    readNow: "Read now",
    more: "Más información en",
    clear: "Borrar",
    close: "Cerrar",
  },
  tooltips: {
    info: "¿Necesitas ayuda? 00 00 00 00",
  },
  confirmation: {
    header: "Confirmation Required",
    message: "Do you want to delete this record?",
  },
  nav: {
    dashboard: "Inicio",
    account: "Mi perfil",
    mailbox: "Bandeja de entrada",
    singleMailbox: "Mensaje",
    news_feeds: "News feeds",
    stammdaten: "Mis datos",
  },
  menu: {
    account: "Mi perfil",
    plus: 'Sobre Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soutien à la vente",
    mailbox: "Bandeja de entrada",
    about: "Sobre BS Plus",
    trainingAndCourses: "Formations et cours",
    externalTraining: "Formations externes",
    productInfo: "Informations sur les produits",
    productBrochures: "Brochures sur les produits",
    priceLists: "Listes de prix",
    price: "Pricat",
    complaintForm: "Formulaire de réclamation",
    sales_support: "Soutien à la vente",
    contact_service: "CONTACTOS Y SERVICIOS",
    logOut: "Desconectarse",
    news: "Notícias",
    news_feeds: "Notícias",
  },
};

export default {
  messages,
};
