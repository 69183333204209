const messages = {
    "fleetWithOptions": "No puedes elegir la flota si has seleccionado opciones.",
    "addProduct": "Tu producto ha sido añadido al carrito.",
    "addProducts": "Tus productos han sido añadidos al carrito.",
    "profileSaved": "Los datos de tu perfil han sido guardados.",
    "canAddProduct": "No tienes suficientes puntos.",
    "customerNumberSaved": "Número de cliente guardado.",
    "customerNumberExist": "Número de cliente disponible.",
    "complaintForm": "Tu solicitud ha sido aceptada. Por favor, espera una respuesta.",
    "openHourSaved": "El horario de apertura ha sido guardado.",
    "changeParentId": "No puedes modificar la sucursal con el número de cliente superior si tiene sucursales subordinadas.",
    "requestSent": "La solicitud ha sido enviada.",
    "completeProfileRequired": "Debes completar tu perfil al 100% para continuar comprando en la tienda.",
    "accessDenied": "Acceso denegado.",
    "incomplete_address": "La dirección está incompleta. Por favor, revísala.",
    "waitMoment": "Espera un momento.",
    "downloadFinished": "Descarga completada.",
    "selectOneField": "Por favor selecciona un campo.",
    "noVoucher": "El bono no fue encontrado.",
    "cannotAddCsNumber": "No puedes añadir un número de cliente."
}

export default {
    messages
}
