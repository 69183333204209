const messages = {
    "fleetWithOptions": "Jūs nevarat izvēlēties floti, ja esat izvēlējies opcijas.",
    "addProduct": "Jūsu produkts ir pievienots grozam.",
    "addProducts": "Jūsu produkti ir pievienoti grozam.",
    "profileSaved": "Jūsu profila dati ir saglabāti.",
    "canAddProduct": "Jums nav pietiekami daudz punktu.",
    "customerNumberSaved": "Klienta numurs saglabāts.",
    "customerNumberExist": "Klienta numurs pieejams.",
    "complaintForm": "Jūsu pieprasījums ir pieņemts. Lūdzu, uzgaidiet atbildi.",
    "openHourSaved": "Darba laiks ir saglabāts.",
    "changeParentId": "Jūs nevarat mainīt filiāli ar vecāka klienta numuru, ja tai ir pakļautās filiāles.",
    "requestSent": "Pieprasījums nosūtīts.",
    "completeProfileRequired": "Lai turpinātu iepirkties veikalā, ir jāaizpilda profils 100%.",
    "accessDenied": "Piekļuve liegta.",
    "incomplete_address": "Adrese ir nepilnīga. Lūdzu, pārbaudiet.",
    "waitMoment": "Lūdzu, uzgaidiet brīdi.",
    "downloadFinished": "Lejupielāde pabeigta.",
    "selectOneField": "Izvēlieties lauku.",
    "noVoucher": "Kupons nav atrasts.",
    "cannotAddCsNumber": "Jūs nevarat pievienot klienta numuru."
}

export default {
    messages
}
