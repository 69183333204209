const messages = {
    "title": "Inicio",
    "welcomeMessage": "Bienvenido a BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Inicio",
    "search": "Buscar",
    "sortBy": "Ordenar por",
    "seeMore": "Ver más "
}

export default {
    messages
}
