const messages = {
    "fleetWithOptions": "Nemůžete vybrat flotilu, pokud jste vybrali možnosti.",
    "addProduct": "Váš produkt byl přidán do košíku.",
    "addProducts": "Vaše produkty byly přidány do košíku.",
    "profileSaved": "Údaje o profilu byly uloženy.",
    "canAddProduct": "Nemáte dostatek bodů.",
    "customerNumberSaved": "Číslo zákazníka bylo uloženo.",
    "customerNumberExist": "Číslo zákazníka je k dispozici.",
    "complaintForm": "Váš požadavek byl přijat. Počkejte prosím na odpověď.",
    "openHourSaved": "Otevírací hodiny byly uloženy.",
    "changeParentId": "Nemůžete změnit pobočku s nadřízeným zákaznickým číslem, pokud má podřízené pobočky.",
    "requestSent": "Požadavek byl odeslán.",
    "completeProfileRequired": "Musíte vyplnit profil na 100 %, abyste mohli pokračovat v nákupu v obchodě.",
    "accessDenied": "Přístup byl odepřen.",
    "incomplete_address": "Adresa je neúplná. Zkontrolujte prosím.",
    "waitMoment": "Počkejte chvíli.",
    "downloadFinished": "Stahování dokončeno.",
    "selectOneField": "Vyberte pole.",
    "noVoucher": "Pouzdro nebylo nalezeno.",
    "cannotAddCsNumber": "Nemůžete přidat zákaznické číslo."
}

export default {
    messages
}
