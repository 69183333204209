<template>
  <div v-if="isDebugMode" class="debug">
    <p><strong>Debug Info:</strong></p>
    <p>Tenant ID: {{ tenantId }}</p>
    <p>Available Modules: {{ availableModules }}</p>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

// Vuex Store
const store = useStore();

// Vuex Debug Variables
const availableModules = computed(() => store.getters.availableModules);
const tenantId = computed(() => store.getters.tenantId);

// Debug Mode (Optional: Enable/Disable Debug View)
const isDebugMode = false; // Set to false to hide the debug section
</script>

<style scoped>
.debug {
  z-index: 1000;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.debug p {
  margin: 0;
  font-size: 10px;
}
</style>
