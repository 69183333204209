const messages = {
    "title": "Mi perfil",
    "desc": "Bienvenido a tu área personal. Aquí puedes actualizar o ajustar tus <br/>datos y ver tu bandeja de entrada.",
    "descStaff": "Bienvenido a tu área personal. Aquí puedes actualizar o ajustar tus datos.",
    "buttons": {
        "showProfile": "Ver perfil",
        "userProfile": "IR A MIS DATOS",
        "mailbox": "IR A BANDEJA DE ENTRADA"
    },
    "categories": {
        "baseData": {
            "title": "Mis datos",
            "desc": "Completa tus datos personales y los de tu empresa para registrarte correctamente."
        },
        "mailbox": {
            "desc": "Recibe notificaciones importantes, confirmaciones de pedidos y noticias en tu bandeja de entrada."
        },
        "bankstatement": {
            "title": "Mis puntos",
            "desc": "Aquí puedes consultar el estado de tu cuenta personal Bridgestone Plus y ver los puntos de recompensa que has recibido y utilizado."
        }
    },
    "profile": {
        "title": "Mis Datos",
        "description": "Bienvenido a tu área personal. Aquí puedes actualizar o ajustar tus datos.",
        "personalData": "DATOS PERSONALES",
        "password": "Contraseña",
        "password1": "Contraseña (opcional)*",
        "password2": "Contraseña (repetir)*",
        "formOfAddress": "Título",
        "choose": "Seleccionar título",
        "mr": "Sr.",
        "mrs": "Sra.",
        "diverse": "Otro",
        "firstname": "Nombre",
        "surname": "Apellido",
        "position": "CARGO EN LA EMPRESA",
        "operatingData": "DATOS DE LA EMPRESA",
        "company": "Empresa",
        "legalForm": "Estructura legal",
        "name": "Nombre",
        "streetHouseNr": "Dirección de la empresa",
        "street": "Calle",
        "postcode": "Código postal",
        "location": "Ciudad",
        "country": "País",
        "de": "Alemania",
        "at": "Austria",
        "ch": "Suiza",
        "nl": "Países Bajos",
        "it": "Italia",
        "language": "Idioma",
        "french": "Francés",
        "italian": "Italiano",
        "german": "Alemán",
        "nederland": "Holandés",
        "openingHours": "Horas de servicio y contacto",
        "phone": "Teléfono",
        "website": "Sitio web",
        "operatingIndicators": "Indicadores operativos clave",
        "tireSale": "Ventas anuales de neumáticos aprox. en piezas",
        "tireSaleLabel": "Venta de neumáticos en gran cantidad",
        "inches": "De los cuales ≥ 18 pulgadas (en %)",
        "inchesLabel": "De los cuales 18 pulgadas o más en %",
        "segmentSale": "Ventas de neumáticos por segmento (participación Presupuesto / Medio / Premium en %)",
        "premBudget": "Premium",
        "midBudget": "Medio",
        "budBudget": "Presupuesto",
        "distribution": "DISTRIBUCIÓN VERANO / INVIERNO / TODAS LAS ESTACIONES (en %)",
        "tyreSizes": "Número de depósitos por temporada (juegos de neumáticos)",
        "diffDeliveryAddres": "Agregar una dirección de entrega",
        "yourEmailAddress": "Tu correo electrónico.",
        "clientNr": "Números de clientes",
        "clientNrSingular": "Número de cliente",
        "clientNrContent": "<span class='text-danger fw-bold'>IMPORTANTE - POR FAVOR LEER:</span><br /><br /> Para asignar correctamente tus pedidos de neumáticos a un distribuidor y/o Bridgestone, es imperativo que <b>indiques tus números de cliente respectivos.</b><br/><br/>Si tienes <b>varios números de cliente con un distribuidor y/o Bridgestone,</b> por ejemplo para tu tienda principal y sucursales, indica primero el número de cliente de la tienda principal.<br/><br/><b>Las ventas de los otros números de cliente se asignarán a tu tienda principal.</b>",
        "supplySource": "Fuente de suministro",
        "email": "Correo electrónico",
        "staffEmail": "Correo electrónico del participante",
        "registered": "Registrado el",
        "registeredAt": "Registrado el",
        "lastLogIn": "Último inicio de sesión",
        "details": "Detalles",
        "inDetail": "{name} en detalle",
        "bsClientNr": "Tu número de cliente Bridgestone",
        "bftClientNr": "Tu número de cliente Best4Tires",
        "acceptTerms": "Aceptar términos y condiciones de participación.",
        "newCustomerNumbersInfo": "<span class='text-danger fw-bold'>IMPORTANTE - POR FAVOR LEER:</span><br />Al introducir el número de cliente por primera vez, tus datos serán verificados en cuanto a su exactitud y nuestros criterios de distribución. La decisión de activar el/los número(s) de cliente y vincularlo(s) a tu perfil puede tardar hasta 5 días hábiles.",
        "selectWholeSaler": "en el proveedor (por favor elige)",
        "cooperation": "MIEMBRO / COOPERACIÓN",
        "error100": "La suma de los valores debe ser igual al 100%.",
        "errorRange": "El valor debe estar entre 0 y 100",
        "btw_number_label": "el número de identificación fiscal de tu empresa",
        "btw_number": "Número de identificación fiscal",
        "mobile": "Número de teléfono",
        "mobile_label": "Tu número de teléfono móvil",
        "iban_number": "Número IBAN de TU EMPRESA",
        "iban_number_label": "Número IBAN",
        "yourCsNr": "Tus números de cliente",
        "yourLastName": "Tu apellido",
        "positionLabel": "Seleccionar un puesto",
        "yourFirstName": "Tu nombre",
        "companyLabel": "Empresa",
        "numberOfSetsOfSeasonalTiresBudget": "Almacenamiento anual",
        "contactInfo": "Si tienes alguna pregunta, por favor contáctanos:",
        "addAnotherCsNumber": "Añadir número de cliente Bridgestone",
        "activityAndProfile": "RELACIÓN COMERCIAL Y PERFIL"
    }
}
export default {
    messages
}