const messages = {
  "termsAndConditions": "Podmienky a pravidlá",
  "accept": "Prijať",
  "decline": "Odmietnuť",
  "dataModal": "Aby sme mohli túto ponuku prispôsobiť vašim záujmom, prosíme vás o stručné uvedenie vašich produktových záujmov alebo oblastí podnikania.",
  "scrollToAccept": "Pre pokračovanie prosím posuňte stránku nadol a prijmite naše podmienky",
  "welcomeMessage": "Vitajte v Bridgestone Plus",
  "tailorOffer": "Aby sme mohli túto ponuku prispôsobiť vašim záujmom, prosíme vás o stručné uvedenie vašich produktových záujmov alebo oblastí podnikania.",
  "selectAreas": "Vyberte si prosím z nasledujúcich oblastí",
  "multipleAnswers": "(môže byť viac odpovedí):",
  "personalPreferences": "Osobné preferencie",
  "carTyres": "Pneumatiky pre autá, SUV a úžitkové vozidlá",
  "motorcyclesTyres": "Pneumatiky pre motocykle",
  "truckBusTyres": "Pneumatiky pre nákladné autá a autobusy",
  "agricultureTyres": "Pneumatiky pre poľnohospodárske stroje",
  "otrTyres": "OTR pneumatiky",
  "save": "Uložiť",
  "businessRelationship": "Aký je váš obchodný vzťah s Bridgestone?",
  "selectRelationship": "Vyberte si prosím*:",
  "tradingPartner": "Som obchodný partner, ktorý...",
  "tradingPartnerDirect": "... objednáva priamo od Bridgestone",
  "tradingPartnerIndirect": "... objednáva nepriamo (napríklad cez veľkoobchod)",
  "tradingPartnerBoth": "... objednáva priamo aj nepriamo",
  "fleetResponsibleDirect": "Som správca flotily, ktorý...",
  "fleetResponsibleIndirect": "... objednáva nepriamo (cez obchod alebo veľkoobchod)",
  "fleetResponsibleBoth": "... objednáva priamo aj nepriamo",
  "noBusinessRelationship": "<b>Nemám žiadne obchodné vzťahy s Bridgestone</b> (ani priamo, ani nepriamo)",
  "yourCustomerNumber": "Vaše zákaznícke číslo Bridgestone*:",
  "enterCustomerNumber": "Zadajte svoje zákaznícke číslo",
  "waitPersonalisedView": "Prosím, čakajte chvíľu, generuje sa vaše personalizované zobrazenie BRIDGESTONE PLUS.",
  "personalised": "PERSONALIZOVANÉ",
  "plusInfo": "Tu nájdete informácie o vašom účte Plus a vašej schránke Plus.",
  "continue": "Pokračovať",
  "skip": "Preskočiť",
  "oneOfTwo": "1 z 2",
  "openApps": "Aplikácie, na ktoré ste už registrovaní, môžete otvoriť priamo. Môžete tiež požiadať o prístup k ďalším aplikáciám, ktoré by pre vás mohli byť relevantné.",
  "requiredFields": "Vyplňte prosím povinné polia: Titul, Krstné meno, Priezvisko, Názov spoločnosti",
  "stage": "krok",
  "chooseOne": "(Prosím, zaškrtnite aspoň jednu možnosť)",
  "required": "*povinné polia",
  "lengthCsNumber": "Dĺžka zákazníckeho čísla: 6–10 číslic"
}

export default {
  messages,
};
