<template>
  <MDBNavbarItem v-if="availableLocales.length > 1">
    <span v-for="(locale, index) in availableLocales" :key="locale" class="cursor--pointer"
      @click="setLanguage(locale)">
      {{ formattedLocales[index] }}
      <span v-if="index < availableLocales.length - 1"> / </span>
    </span>
  </MDBNavbarItem>
</template>

<script setup>
import { countryToLocaleMap } from '@/utils/tenantUtils'
import { MDBNavbarItem } from 'mdb-vue-ui-kit'
import { ref, watchEffect, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n' // Import useI18n to interact with i18n
// Vuex Store
const store = useStore()
// Tenant Configuration
const tenant = ref(false)

const { locale } = useI18n() // Get the reactive locale property from i18n

// Watch effect for tenant and locale updates
watchEffect(() => {
  tenant.value = store.getters.tenantId
})

// Available locales for the tenant
const availableLocales = computed(() => {
  return countryToLocaleMap[tenant.value]?.available || []
})

// Computed property to format locales to display only language part in uppercase
const formattedLocales = computed(() => {
  return availableLocales.value.map((loc) => loc.split("-")[0].toUpperCase())
})

const setLanguage = (newLocale) => {
  locale.value = newLocale // Update the i18n locale
  localStorage.setItem("currentLocale", newLocale)
  store.commit("SET_CURRENT_LOCALE", newLocale);
}
</script>