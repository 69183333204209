const messages = {
  greeting: 'Hello',
  cancel: 'Cancel',
  update: 'Update',
  change: 'Change',
  save: 'Save',
  changeLanguage: 'Change Language',
  ok: 'OK',
  at: 'at',
  by: 'by',
  seeAll: 'See All',
  buttons: {
    export: 'Export',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel'
  },
  confirmation: {
    header: 'Confirmation Required',
    message: 'Do you want to delete this record?'
  }
}

export default {
  messages
}
