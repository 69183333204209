const messages = {
    "titleColorRev": "true",
    "buttons": {
        "pending": "Väntande",
        "open": "Öppna",
        "requestAccess": "Begär åtkomst"
    },
    "my": "Mina appar",
    "directRoute": "Din direkta väg till alla Bridgestones webbapplikationer.",
    "welcomeMessage": "Välkommen till BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Visa profil",
    "newMessages": "Nya meddelanden i din inkorg",
    "myApps": "Mina appar",
    "allApps": "Alla appar ",
    "myAppsDescription": "Alla Bridgestones webbapplikationer som du redan är registrerad för.",
    "allAppsDescription": "Alla Bridgestones webbapplikationer som kan vara av intresse för dig. ",
    "myProfile": {
        "welcomeMessage": "Välkommen till ditt personliga område. Här kan du uppdatera eller justera dina masterdata och se din inkorg.",
        "masterData": "Masterdata",
        "completeDetails": "Fyll i dina person- och företagsuppgifter för en lyckad registrering.",
        "goToMasterData": "Gå till Masterdata",
        "goToInbox": "Gå till inkorgen "
    },
    "masterData": {
        "welcomeMessage": "Välkommen till ditt personliga område. Här kan du uppdatera eller justera dina stamdata.",
        "personalDetails": "Personuppgifter",
        "companyDetails": "Företagsuppgifter",
        "emailAddress": "E-postadress",
        "title": "Titel",
        "surname": "Efternamn",
        "firstName": "Förnamn",
        "jobPosition": "Arbetsposition",
        "streetAndHouseNumber": "gatu- och husnummer",
        "postalCode": "Postnummer",
        "city": "Stad",
        "country": "Land",
        "legalStructure": "Juridisk struktur",
        "customerNumber": "Bridgestone-kundnummer",
        "importantPleaseRead": "Viktigt vänligen läs",
        "customerNumberInfo": "För att kunna koppla dina däckbeställningar till grossister och/eller Bridgestone på rätt sätt är det viktigt att du anger dina respektive kundnummer.",
        "multipleCustomerNumbers": "Om du har flera kundnummer hos en grossist och/eller Bridgestone, t.ex. för din huvudverksamhet och dina filialer, vänligen ange kundnumret för huvudverksamheten först.",
        "salesAssignment": "Den försäljning som är kopplad till de extra kundnumren kommer sedan att tilldelas din huvudverksamhet.",
        "supplier": "Leverantör",
        "addAnotherCustomerNumber": "Lägg till ytterligare ett  Bridgestone kundnummer",
        "save": "Spara",
        "delete": "Radera"
    },
    "inbox": {
        "welcomeMessage": "Här hittar du viktiga meddelanden och nyheter.",
        "sender": "Avsändare",
        "subject": "Ämne",
        "date": "Datum",
        "formConsulting": "Form Consulting - Tack för att du behandlar formuläret",
        "back": "Tillbaka"
    },
    "legalAndContact": {
        "cookieSettings": "Cookie-inställningar",
        "dataPolicy": "Datapolicy",
        "termsOfUse": "Användningstid",
        "helpAndContact": "Hjälp och kontakt"
    },
    "logInSignUp": {
        "logIn": "Logga in",
        "signUp": "Registrera dig",
        "logInMessage": "Logga in med din e-postadress och ditt lösenord.",
        "signUpMessage": "Om du är en ny användare, vänligen registrera dig."
    }
}

export default {
    messages
}