const messages = {
  title:
    'Tervetuloa BRIDGESTONE <span class="text-danger">PLUS</span> -palveluun',
  termsAndConditions: "Säännöt ja ehdot",
  accept: "Hyväksy ",
  decline: "Hylkää",
  dataModal:
    "Jotta voisimme räätälöidä tämän tarjouksen kiinnostuksen kohteidesi mukaan, pyydämme sinua kertomaan mitkä ovat kiinnostuksesi kohteena olevat tuotteet tai liiketoiminta-alueet.",
  scrollToAccept: "Vieritä alaspäin hyväksyäksesi ehtomme ja jatkaaksesi.",
  welcomeMessage: "Tervetuloa Bridgestone Plus -palveluun",
  tailorOffer:
    "Jotta voisimme räätälöidä tämän tarjouksen kiinnostuksen kohteidesi mukaan, pyydämme sinua kertomaan mitkä ovat kiinnostuksesi kohteena olevat tuotteet tai liiketoiminta-alueet.",
  selectAreas: "Valitse* seuraavista alueista",
  multipleAnswers: "(useat valinnat mahdollisia):",
  personalPreferences: "Henkilökohtaiset valinnat",
  carTyres: "Autonrenkaat, maastoauto, maasturi, utlitity",
  motorcyclesTyres: "Moottoripyörän renkaat",
  truckBusTyres: "Kuorma- ja linja-auton renkaat",
  agricultureTyres: "Maatalousrenkaat",
  otrTyres: "Työkonerenkaat",
  save: "Tallenna",
  businessRelationship: "Mikä on liikesuhteesi Bridgestoneen?",
  selectRelationship: "Valitse*:",
  tradingPartner: "Olen liikekumppani, joka...",
  tradingPartnerDirect: "... tilaa suoraan Bridgestonelta",
  tradingPartnerIndirect: "... tilaa epäsuorasti (esim. tukkukaupan kautta).",
  tradingPartnerBoth: "... tilaa sekä suoraan että epäsuorasti",
  fleetResponsibleDirect: "Olen kalustovastaava, joka ... ",
  fleetResponsibleIndirect:
    "... tilaa epäsuorasti (kaupan tai tukkukaupan kautta) ",
  fleetResponsibleBoth: "... tilaa suoraan ja epäsuorasti",
  noBusinessRelationship:
    "<b>Minulla ei ole liikesuhdetta Bridgestoneen <b>(ei suoraan eikä välillisesti)",
  yourCustomerNumber: "Bridgestonen asiakasnumerosi*:",
  enterCustomerNumber: "Kirjoita asiakasnumerosi",
  waitPersonalisedView:
    "Odota hetki, niin saat henkilökohtaisen näkymän BRIDGESTONE PLUS:sta.",
  personalised: "HENKILÖKOHTAINEN",
  plusInfo: "Täältä löydät tietoja Plus-tilistäsi ja Plus-postilaatikostasi.",
  continue: "Jatka",
  skip: "Ohita",
  oneOfTwo: "1/2",
  openApps:
    "Voit avata suoraan sovellukset, joihin olet jo rekisteröitynyt. Voit myös pyytää pääsyä muihin sovelluksiin, jotka voivat olla sinulle tärkeitä.",
  requiredFields: "Täytä vaaditut kentät: titteli, etunimi, sukunimi, etunimi.",
  stage: "Askel",
  chooseOne: "(Rastita vähintään 1 ruutu)",
  required: "*pakolliset kentät",
  lengthCsNumber: "Asiakasnumeron pituus: 6-10 numeroa.",
};

export default {
  messages,
};
