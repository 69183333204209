const messages = {
    "title": "Início",
    "welcomeMessage": "Bem-vindo ao BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Início",
    "search": "Pesquisar",
    "sortBy": "Filtrar por",
    "seeMore": "Ver mais"
}

export default {
    messages
}
