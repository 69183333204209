import global from '@/locales/pl-PL/global'
import error403 from '@/locales/pl-PL/errors/403'
import error404 from '@/locales/pl-PL/errors/404'
import error500 from '@/locales/pl-PL/errors/500'
import error503 from '@/locales/pl-PL/errors/503'
import error504 from '@/locales/pl-PL/errors/504'
import maintenance from '@/locales/pl-PL/auth/maintenance'
import toast from '@/locales/pl-PL/toast/toast'
import login from '@/locales/pl-PL/auth/login'
import onboarding from '@/locales/pl-PL/auth/onboarding'
import dashboard from '@/locales/pl-PL/dashboard/dashboard'
import account from '@/locales/pl-PL/account/account'
import mailbox from '@/locales/pl-PL/account/mailbox'
import myApps from '@/locales/pl-PL/my-apps/myApps'

//prettier-ignore
const messages = {
  global: global.messages,
  error403: error403.messages,
  error404: error404.messages,
  error500: error500.messages,
  error503: error503.messages,
  error504: error504.messages,
  maintenance: maintenance.messages,
  toast: toast.messages,
  login: login.messages,
  onboarding: onboarding.messages,
  dashboard: dashboard.messages,
  account: account.messages,
  mailbox: mailbox.messages,
  myApps: myApps.messages,
}

export default {
  locale: 'pl-PL',
  messages
}
