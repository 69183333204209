const messages = {
    "fleetWithOptions": "Du kan inte välja flottan om du har valt alternativ.",
    "addProduct": "Din produkt har lagts till i kundvagnen.",
    "addProducts": "Dina produkter har lagts till i kundvagnen.",
    "profileSaved": "Dina profiluppgifter har sparats.",
    "canAddProduct": "Du har inte tillräckligt med poäng.",
    "customerNumberSaved": "Kundnumret har sparats.",
    "customerNumberExist": "Kundnumret finns tillgängligt.",
    "complaintForm": "Din begäran har accepterats. Vänta på ett svar.",
    "openHourSaved": "Öppettider har sparats.",
    "changeParentId": "Du kan inte ändra filialen med det överordnade kundnumret om den har underordnade filialer.",
    "requestSent": "Begäran har skickats.",
    "completeProfileRequired": "Du måste fylla i din profil 100% för att kunna fortsätta handla i butiken.",
    "accessDenied": "Åtkomst nekad.",
    "incomplete_address": "Adressen är ofullständig. Kontrollera den.",
    "waitMoment": "Vänta ett ögonblick.",
    "downloadFinished": "Nedladdning klar.",
    "selectOneField": "Välj ett fält.",
    "noVoucher": "Vouchern hittades inte.",
    "cannotAddCsNumber": "Du kan inte lägga till ett kundnummer."
}

export default {
    messages
}
