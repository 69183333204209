const messages = {
    "titleColorRev": "true",
    "buttons": {
        "pending": "w toku",
        "open": "Otwarty",
        "requestAccess": "Żądanie dostępu"
    },
    "my": "Moje Aplikacje",
    "directRoute": "Bezpośredni dostęp do wszystkich aplikacji internetowych Bridgestone.",
    "welcomeMessage": "Witamy w BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Pokaż Profil",
    "newMessages": "Nowa wiadomość w skrzynce odbiorczej",
    "myApps": "Moje Aplikacje",
    "allApps": "Wszystkie Aplikacje",
    "myAppsDescription": "Wszystkie aplikacje internetowe Bridgestone, do których jesteś zarejestrowany.",
    "allAppsDescription": "Wszystkie aplikacje internetowe Bridgestone, które mogą Cię zainteresować.",
    "myProfile": {
        "welcomeMessage": "Witamy w obszarze osobistym. Tutaj możesz aktualizować lub dostosowywać swoje dane podstawowe i przeglądać skrzynkę odbiorczą.",
        "masterData": "Dane podstawowe",
        "completeDetails": "Uzupełnij swoje dane osobowe i dane firmy w celu pomyślnej rejestracji.",
        "goToMasterData": "Przejdź do danych głównych",
        "goToInbox": "Przejdź do skrzynki odbiorczej "
    },
    "masterData": {
        "welcomeMessage": "Witamy w obszarze osobistym. Tutaj możesz zaktualizować lub dostosować swoje dane podstawowe.",
        "personalDetails": "Dane Osobowe",
        "companyDetails": "Szczegóły firmy",
        "emailAddress": "Adres E-mail",
        "title": "Tytuł",
        "surname": "Nazwisko",
        "firstName": "Imię",
        "jobPosition": "Stanowisko",
        "streetAndHouseNumber": "Ulica i numer domu",
        "postalCode": "Kod Pocztowy",
        "city": "Miasto",
        "country": "Kraj",
        "legalStructure": "Struktura Prawna",
        "customerNumber": "Numer Klienta Bridgestone",
        "importantPleaseRead": "Ważne, prosimy o zapoznanie się",
        "customerNumberInfo": "Aby prawidłowo przypisać zamówienia opon do hurtowników i/lub Bridgestone, konieczne jest podanie odpowiednich numerów klientów.",
        "multipleCustomerNumbers": "W przypadku posiadania wielu numerów klienta u hurtownika i/lub Bridgestone, na przykład dla głównej firmy i oddziałów, należy najpierw podać numer klienta dla głównej firmy.",
        "salesAssignment": "Sprzedaż powiązana z dodatkowymi numerami klientów zostanie następnie przypisana do głównej działalności.",
        "supplier": "Dostawca",
        "addAnotherCustomerNumber": "Dodaj kolejny numer klienta Bridgestone",
        "save": "Oszczędzać",
        "delete": "Usunąć"
    },
    "inbox": {
        "welcomeMessage": "Tutaj znajdziesz ważne powiadomienia i wiadomości.",
        "sender": "Nadawca",
        "subject": "Podmiot",
        "date": "Data",
        "formConsulting": "Form Consulting - Dziękujemy za przetworzenie formularza.",
        "back": "Wróć"
    },
    "legalAndContact": {
        "cookieSettings": "Ustawienia Plików Cookie",
        "dataPolicy": "Polityka Danych",
        "termsOfUse": "Okres Użytkowania",
        "helpAndContact": "Pomoc i Kontakt"
    },
    "logInSignUp": {
        "logIn": "Zaloguj się",
        "signUp": "Zarejestruj się",
        "logInMessage": "Zaloguj się za pomocą adresu e-mail i hasła.",
        "signUpMessage": "Jeśli jesteś nowym użytkownikiem, zarejestruj się."
    }
}

export default {
    messages
}