const messages = {
    "title": "Bandeja de entrada",
    "desc": "Aquí puedes encontrar notificaciones y noticias importantes.",
    "newMessages": "Nuevo mensaje en la bandeja",
    "link": "Mostrar los mensajes",
    "showMessages": "Mostrar los mensajes",
    "message": {
        "sender": "Remitente",
        "thread": "Asunto",
        "date": "Fecha"
    },
    "sender": {
        "system": "BRIDGESTONE PLUS"
    },
    "back": "Atrás"
}

export default {
    messages
}