const messages = {
    "title": "Saapuneet-kansio",
    "desc": "Täältä löydät tärkeitä ilmoituksia ja uutisia.",
    "newMessages": "Uudet viestit saapuneet-kansiossa",
    "link": "Näytä viestit",
    "showMessages": "Näytä viestit",
    "message": {
        "sender": "Lähettäjä",
        "thread": "Aihe",
        "date": "Päivämäärä"
    },
    "sender": {
        "system": "BRIDGESTONE PLUS"
    },
    "back": "Takaisin"
}

export default {
    messages
}