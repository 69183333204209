import AuthLayout from "../layouts/Auth.vue";

export default [
  {
    path: '/',
    component: AuthLayout,
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
        },
        component: () => import('@/views/system/auth/login.vue')
      },
      {
        path: '/maintenance',
        name: 'maintenance',
        meta: {
        },
        component: () => import('@/views/system/auth/maintenance.vue')
      }
    ]
  }
]
