const messages = {
    "titleColorRev": "pravda",
    "buttons": {
        "pending": "Čaká na spracovanie",
        "open": "Otvorené",
        "requestAccess": "Požiadať o prístup"
    },
    "my": "Moje aplikácie",
    "directRoute": "Vaše priame odkazy na webové aplikácie Bridgestone.",
    "welcomeMessage": "Vitajte v BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Zobraziť profil",
    "newMessages": "Nová správa v schránke",
    "myApps": "Moje aplikácie",
    "allApps": "Všetky aplikácie",
    "myAppsDescription": "Všetky webové aplikácie Bridgestone, v ktorých ste zaregistrovaní.",
    "allAppsDescription": "Všetky webové aplikácie Bridgestone, ktoré by pre vás mohli byť relevantné.",
    "myProfile": {
        "welcomeMessage": "Vitajte vo svojej osobnej oblasti. Tu môžete aktualizovať alebo upraviť svoje údaje a zobraziť svoju schránku.",
        "masterData": "Základné údaje",
        "completeDetails": "Vyplňte svoje osobné a firemné údaje na zaistenie správnej registrácie.",
        "goToMasterData": "Prejsť na moje údaje",
        "goToInbox": "Prejsť do schránky"
    },
    "masterData": {
        "welcomeMessage": "Vitajte vo svojej osobnej oblasti. Tu môžete aktualizovať alebo upraviť svoje základné údaje.",
        "personalDetails": "Osobné údaje",
        "companyDetails": "Údaje o firme",
        "emailAddress": "E-mailová adresa",
        "title": "Titul",
        "surname": "Priezvisko",
        "firstName": "Meno",
        "jobPosition": "Pracovná pozícia",
        "streetAndHouseNumber": "Adresa firmy",
        "postalCode": "PSČ",
        "city": "Mesto",
        "country": "Krajina",
        "legalStructure": "Právna forma",
        "customerNumber": "Zákaznícke číslo Bridgestone",
        "importantPleaseRead": "Dôležité, prosím, prečítajte si",
        "customerNumberInfo": "Aby bolo možné správne priradiť vaše objednávky pneumatík rôznym predajcom, je potrebné uviesť vaše zákaznícke číslo.",
        "multipleCustomerNumbers": "Ak máte viac zákazníckych čísel u jedného predajcu, napríklad pre vašu hlavnú firmu a pobočky, najprv uveďte číslo hlavnej firmy.",
        "salesAssignment": "Predaje spojené s ďalšími zákazníckymi číslami budú priradené vašej hlavnej firme.",
        "supplier": "Dodávateľ",
        "addAnotherCustomerNumber": "Pridať zákaznícke číslo",
        "save": "Uložiť",
        "delete": "Vymazať"
    },
    "inbox": {
        "welcomeMessage": "Tu nájdete upozornenia a dôležité novinky.",
        "sender": "Odosielateľ",
        "subject": "Predmet",
        "date": "Dátum",
        "formConsulting": "Formulár prijatý - Ďakujeme za jeho vyplnenie",
        "back": "Späť"
    },
    "legalAndContact": {
        "cookieSettings": "Nastavenie cookies",
        "dataPolicy": "Zásady ochrany osobných údajov",
        "termsOfUse": "Podmienky používania",
        "helpAndContact": "Pomoc a kontakt"
    },
    "logInSignUp": {
        "logIn": "Prihlásiť sa",
        "signUp": "Zaregistrovať sa",
        "logInMessage": "Prihláste sa pomocou svojej e-mailovej adresy a hesla.",
        "signUpMessage": "Ak ste nový používateľ, prosím, zaregistrujte sa."
    }
}
export default {
    messages
}