<template>
  <div id="operating_data">
    <h4 class="mb-3 text-uppercase">{{ $t("account.profile.operatingData") }}</h4>

    <label for="company" class="label px-3 text-uppercase">
      {{ $t("account.profile.company") }}{{ requiredCompany ? '*' : '' }}
    </label>
    <Field type="text" class="form-control" :placeholder="$t('account.profile.companyLabel')"
      :aria-label="$t('account.profile.company')" aria-describedby="basic-addon1" v-model="models.company" id="company"
      :label="$t('account.profile.company')" :rules="{ required: props.requiredCompany }" name="company" />
    <div>
      <ErrorMessage name="company" class="text-danger text-small text-end m-0 pt-1" />
    </div>

    <label for="streetHouseNr" class="label px-3 text-uppercase">
      {{ $t("account.profile.streetHouseNr") }}{{ requiredStreet ? '*' : '' }}
    </label>
    <Field type="text" class="form-control" :placeholder="$t('account.profile.streetHouseNr')"
      :aria-label="$t('account.profile.streetHouseNr')" aria-describedby="basic-addon1" v-model="models.street"
      id="streetHouseNr" :label="$t('account.profile.streetHouseNr')" :rules="{ required: props.requiredStreet }"
      name="streetHouseNr" />
    <div>
      <ErrorMessage name="streetHouseNr" class="text-danger text-small text-end m-0 pt-1" />
    </div>
    <MDBRow>
      <MDBCol col="4">
        <label for="postcode" class="label px-3 text-uppercase">
          {{ $t("account.profile.postcode") }}{{ requiredPostalCode ? '*' : '' }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.postcode')"
          :aria-label="$t('account.profile.postcode')" aria-describedby="basic-addon1" v-model="models.postal_code"
          id="postcode" :label="$t('account.profile.postcode')" :rules="{ required: props.requiredPostalCode }"
          name="postcode" />
        <div>
          <ErrorMessage name="postcode" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </MDBCol>
      <MDBCol col="8">
        <label for="location" class="label px-3 text-uppercase">
          {{ $t("account.profile.location") }}{{ requiredCity ? '*' : '' }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.location')"
          :aria-label="$t('account.profile.location')" aria-describedby="basic-addon1" v-model="models.city" id="location"
          :label="$t('account.profile.location')" :rules="{ required: props.requiredCity }" name="location" />
        <div>
          <ErrorMessage name="location" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </MDBCol>
    </MDBRow>
    <MDBRow v-if="isGermany">
      <MDBCol col="12">
        <label for="land" class="label px-3 text-uppercase">
          {{ $t("account.profile.country") }}
        </label>
        <select class="form-select" :aria-label="$t('account.profile.country')" id="land" v-model="models.country">
          <option disabled :value="null">{{ $t("account.profile.choose") }}</option>
          <option value="fe" v-if="isGermany">
            {{ $t("account.profile.french") }}
          </option>
          <option value="it" v-if="isGermany">
            {{ $t("account.profile.italian") }}
          </option>
          <option value="de" v-if="isGermany">
            {{ $t("account.profile.german") }}
          </option>
          <option value="nl" v-if="isDutch">
            {{ $t("account.profile.nederland") }}
          </option>
        </select>
      </MDBCol>
    </MDBRow>
    <MDBRow v-if="models.country === 'ch'">
      <MDBCol col="12">
        <label for="sprache" class="from-label">{{
          $t("account.profile.language")
          }}</label>
      </MDBCol>
      <MDBCol col="12" v-if="isGermany">
        <select class="form-select" aria-label="Sprache" id="sprache" v-model="models.lang">
          <option disable :value="null">{{ $t("account.profile.choose") }}</option>
          <option value="fe" v-if="isGermany">
            {{ $t("account.profile.french") }}
          </option>
          <option value="it" v-if="isGermany">
            {{ $t("account.profile.italian") }}
          </option>
          <option value="de" v-if="isGermany">
            {{ $t("account.profile.german") }}
          </option>
          <option value="nl" v-if="isDutch">
            {{ $t("account.profile.nederland") }}
          </option>
        </select>
      </MDBCol>
    </MDBRow>
    <MDBRow v-if="isDutch">
      <MDBCol col="12">
        <label for="mobile_phone" class="label px-3 text-uppercase">
          {{ $t("account.profile.mobile") }}*
        </label>
        <Field type="number" class="form-control" :placeholder="$t('account.profile.mobile_label')"
          :aria-label="$t('account.profile.mobile_label')" aria-describedby="basic-addon1" v-model="models.mobile_phone"
          id="mobile_phone" :rules="{ required: true, min: 9 }" :label="$t('account.profile.mobile')" min="1"
          name="mobile_phone" />
        <div>
          <ErrorMessage name="mobile_phone" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </MDBCol>

      <MDBCol col="12">
        <label for="iban_number" class="label px-3 text-uppercase">
          {{ $t("account.profile.iban_number") }}{{ requiredSection ? '*' : '' }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.iban_number_label')"
          :aria-label="$t('account.profile.iban_number_label')" aria-describedby="basic-addon1" v-model="models.iban_number"
          id="iban_number" :rules="{ required: props.requiredSection }" :label="$t('account.profile.iban_number_label')"
          name="iban_number" :disabled="models.admin_do_match || models.iban_status?.statusMatch == 'MATCH'
            " />

        <div>{{ $t("kickbackInfo.withdraw") }}</div>
        <div>
          <ErrorMessage name="iban_number" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </MDBCol>

      <MDBCol col="12">
        <label for="btw_number" class="label px-3 text-uppercase">
          {{ $t("account.profile.btw_number_label") }}*
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.btw_number')"
          :aria-label="$t('account.profile.btw_number')" aria-describedby="basic-addon1" v-model="models.vat"
          :rules="{ required: props.requiredSection }" name="btw_number" :disabled="models.admin_do_match || models.iban_status?.statusMatch == 'MATCH'
            " :label="$t('account.profile.btw_number')" />
        <div>{{ $t("kickbackInfo.withdraw") }}</div>
        <div>
          <ErrorMessage name="btw_number" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { Field, ErrorMessage } from "vee-validate";
import { ref, reactive, watch, defineExpose, defineEmits, computed, defineProps } from "vue";
import { useStore } from "vuex";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";

const store = useStore();
const emit = defineEmits(["diffAddress"]);

const props = defineProps({
  requiredSection: { type: Boolean, default: false },
  requiredCompany: { type: Boolean, default: false },
  requiredStreet: { type: Boolean, default: false },
  requiredCountry: { type: Boolean, default: false },
  requiredCity: { type: Boolean, default: false },
  requiredPostalCode: { type: Boolean, default: false },
});

const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");
const isGermany = computed(() => LANG === "de");

const indexToPercentage = [
  "company",
  "street",
  "country",
  "city",
  "postal_code",
];

const models = reactive({
  id: null,
  user_id: null,
  company: null,
  street: null,
  postal_code: null,
  city: null,
  country: null,
  lang: LANG,
  is_main: 1,
  is_residence: 0,
  mobile_phone: null,
  iban_number: null,
  vat: null,
  iban_status: [],
  admin_do_match: false,
});

const diffDeliveryAddress = ref(false);

const percentage = ref(0);

watch(
  () => {
    if (isDutch.value) {
      indexToPercentage.push("mobile_phone");
      indexToPercentage.push("iban_number");
      indexToPercentage.push("vat");
    }
    return indexToPercentage.map((item) => models[item]);
  },
  (newVal) => {
    percentage.value =
      (newVal.filter((i) => i).length / indexToPercentage.length) * 100;
  }
);

watch(
  () => store.getters.getMe,
  (newVal) => {
    models.country = newVal.country;
    models.user_id = newVal.id;
    models.lang = newVal.lang;
    models.vat = newVal.vat?.toUpperCase().replace(/\s+/g, "");
  }
);

watch(
  () => store.getters.getUserAddresses,
  (newVal) => {
    const maindAddress = newVal.filter((item) => {
      return item.is_main == 1;
    });

    const deliveryAddress = newVal.filter((item) => {
      return item.is_residence == 1 && item.is_main == 0;
    });
    if (deliveryAddress.length > 0) {
      diffDeliveryAddress.value = true;
    }

    if (maindAddress.length !== 0) {
      models.id = maindAddress[0].id;
      models.city = maindAddress[0].city;
      models.postal_code = maindAddress[0].postal_code;
      models.street = maindAddress[0].street;
    }
  }
);

watch(
  () => store.getters.getProfile,
  (newVal) => {
    models.company = newVal.company;
    models.street = newVal.street;
    models.postal_code = newVal.postal_code;
    models.city = newVal.city;
    if (isDutch.value) {
      models.mobile_phone = newVal.mobile_phone;
      models.iban_number = newVal.iban_number.toUpperCase().replace(/\s+/g, "");
      models.iban_number = newVal.iban_number.toUpperCase().replace(/\./g, "");
      models.admin_do_match = newVal.admin_do_match;
      models.iban_status = newVal.iban_status;
    }
  }
);

watch(diffDeliveryAddress, (newVal) => {
  emit("diffAddress", newVal);
});

defineExpose({
  models,
  percentage,
});
</script>
