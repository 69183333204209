const messages = {
  title: 'Oops! Why you’re here?',
  description:
    'We are very sorry for inconvenience. It looks like you’re try to\n' +
    'access a page that either has been deleted or never existed.',
  backHomeButton: 'Back To Dashboard'
}

export default {
  messages
}
