const messages = {
  "title": "Schránka",
  "desc": "Tu nájdete dôležité správy a novinky.",
  "newMessages": "Nové správy vo vašej schránke",
  "link": "Zobraziť správy",
  "showMessages": "Zobraziť správy",
  "message": {
    "sender": "Odosielateľ",
    "thread": "Predmet",
    "date": "Dátum"
  },
  "sender": {
    "system": "BRIDGESTONE PLUS"
  },
  "back": "Späť"
}

export default {
  messages,
};
