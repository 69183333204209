const messages = {
    "fleetWithOptions": "Sie können die Flotte nicht auswählen, wenn Sie Optionen ausgewählt haben.",
    "addProduct": "Ihr Produkt wurde dem Warenkorb hinzugefügt.",
    "addProducts": "Ihre Produkte wurden dem Warenkorb hinzugefügt.",
    "profileSaved": "Ihre Profildaten wurden gespeichert.",
    "canAddProduct": "Sie haben nicht genügend Punkte.",
    "customerNumberSaved": "Kundennummer gespeichert.",
    "customerNumberExist": "Kundennummer verfügbar.",
    "complaintForm": "Ihre Anfrage wurde akzeptiert. Bitte warten Sie auf eine Antwort.",
    "openHourSaved": "Die Öffnungszeiten wurden gespeichert.",
    "changeParentId": "Sie können die Filiale mit der übergeordneten Kundennummer nicht ändern, wenn sie untergeordnete Filialen hat.",
    "requestSent": "Die Anfrage wurde gesendet.",
    "completeProfileRequired": "Sie müssen Ihr Profil zu 100 % ausfüllen, um weiter im Shop einkaufen zu können.",
    "accessDenied": "Zugriff verweigert.",
    "incomplete_address": "Die Adresse ist unvollständig. Bitte überprüfen Sie sie.",
    "waitMoment": "Einen Moment warten.",
    "downloadFinished": "Download abgeschlossen.",
    "selectOneField": "Wählen Sie ein Feld aus.",
    "noVoucher": "Der Gutschein wurde nicht gefunden.",
    "cannotAddCsNumber": "Sie können keine Kundennummer hinzufügen."
}

export default {
    messages
}
