const messages = {
  "termsAndConditions": "Regulamin",
  "accept": "Zaakceptuj",
  "decline": "Odrzuć",
  "dataModal": "Aby móc dostosować tę ofertę do zainteresowań użytkownika, prosimy o krótkie określenie zainteresowań produktowych lub obszarów działalności.",
  "scrollToAccept": "Aby kontynuować, przewiń w dół, aby zaakceptować nasze warunki",
  "welcomeMessage": "Witamy w Bridgestone Plus",
  "tailorOffer": "Aby móc dostosować tę ofertę do zainteresowań użytkownika, prosimy o krótkie określenie zainteresowań produktowych lub obszarów działalności.",
  "selectAreas": "Wybierz* spośród następujących obszarów",
  "multipleAnswers": "(możliwe wielokrotne odpowiedzi):",
  "personalPreferences": "Preferencje osobiste",
  "carTyres": "Opony do samochodów osobowych, SUV-ów, pojazdów użytkowych",
  "motorcyclesTyres": "Opony motocyklowe",
  "truckBusTyres": "Opony do samochodów ciężarowych i autobusów",
  "agricultureTyres": "Opony do pojazdów rolniczych",
  "otrTyres": "Opony OTR",
  "save": "Zapisz",
  "businessRelationship": "Jakie są Twoje relacje biznesowe z Bridgestone?",
  "selectRelationship": "Wybierz*:",
  "tradingPartner": "Jestem partnerem handlowym, który...",
  "tradingPartnerDirect": "... zamawia bezpośrednio od Bridgestone",
  "tradingPartnerIndirect": "... zamawia pośrednio (np. poprzez dystrybutorów)",
  "tradingPartnerBoth": "... zamawia zarówno bezpośrednio, jak i pośrednio",
  "fleetResponsibleDirect": "Jestem odpowiedzialny za flotę, która jest... ",
  "fleetResponsibleIndirect": "... zamawia pośrednio (poprzez dystrybutorów lub sprzedaż hurtową) ",
  "fleetResponsibleBoth": "... zamawia bezpośrednio i pośrednio",
  "noBusinessRelationship": "<b>Nie mam żadnych relacji biznesowych z Bridgestone</b> (ani bezpośrednio, ani pośrednio)\"",
  "yourCustomerNumber": "Numer klienta Bridgestone*:",
  "enterCustomerNumber": "Wprowadź numer klienta",
  "waitPersonalisedView": "Poczekaj chwilę, Twój spersonalizowany widok BRIDGESTONE PLUS zostanie wygenerowany.",
  "personalised": "SPERSONALIZOWANY",
  "plusInfo": "Tutaj znajdziesz informacje o swoim koncie Plus i skrzynce pocztowej Plus.",
  "continue": "Kontynuuj",
  "skip": "Pomiń",
  "oneOfTwo": "1 z 2",
  "openApps": "Możesz bezpośrednio otwierać aplikacje, w których jesteś już zarejestrowany. Możesz także poprosić o dostęp do innych aplikacji, które mogą być dla Ciebie istotne.",
  "requiredFields": "Proszę wypełnić obowiązkowe pola: Tytuł, Imię, Nazwisko",
  "stage": "Krok",
  "chooseOne": "(Prosimy o zaznaczenie co najmniej 1 pola)",
  "required": "*pola obowiązkowe",
  "lengthCsNumber": "Długość numeru klienta: 6-10 cyfr"
}

export default {
  messages,
};
