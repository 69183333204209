const messages = {
    "fleetWithOptions": "Negalite pasirinkti parko, jei pasirinkote parinktis.",
    "addProduct": "Jūsų produktas buvo pridėtas į krepšelį.",
    "addProducts": "Jūsų produktai buvo pridėti į krepšelį.",
    "profileSaved": "Jūsų profilio duomenys buvo išsaugoti.",
    "canAddProduct": "Neturite pakankamai taškų.",
    "customerNumberSaved": "Kliento numeris išsaugotas.",
    "customerNumberExist": "Kliento numeris yra prieinamas.",
    "complaintForm": "Jūsų užklausa priimta. Prašome palaukti atsakymo.",
    "openHourSaved": "Darbo valandos išsaugotos.",
    "changeParentId": "Negalite pakeisti filialo, kurio tėvo kliento numeris turi pavaldžias šakas.",
    "requestSent": "Užklausa išsiųsta.",
    "completeProfileRequired": "Norėdami tęsti apsipirkimą parduotuvėje, turite 100% užpildyti savo profilį.",
    "accessDenied": "Prieiga uždrausta.",
    "incomplete_address": "Adresas nebaigtas. Prašome patikrinti.",
    "waitMoment": "Prašome palaukti.",
    "downloadFinished": "Atsisiuntimas baigtas.",
    "selectOneField": "Pasirinkite lauką.",
    "noVoucher": "Kuponas nerastas.",
    "cannotAddCsNumber": "Negalite pridėti kliento numerio."
}

export default {
    messages
}
