<template>
  <div v-if="models.customerNumbers.length > 0">
    <MDBRow v-for="(data, index) in models.customerNumbers" :key="index">
      <MDBCol col="12" md="5">
        <input v-model="data.customer_number" type="text" min="1" class="form-control" :placeholder="`${$t('her')} ${$t(
          'account.profile.clientNrSingular'
        )}`" :aria-label="`${$t('her')} ${$t(
          'account.profile.clientNrSingular'
        )}`" aria-describedby="basic-addon1" />
      </MDBCol>
      <MDBCol col="12" md="4">
        <input v-model="data.expected_name" type="text" class="form-control" :placeholder="`${$t(
          'account.profile.selectWholeSaler'
        )}`" :aria-label="`${$t(
          'account.profile.selectWholeSaler'
        )}`" aria-describedby="basic-addon1" />
      </MDBCol>
      <MDBCol col="12" md="3">
        <MDBBtn @click="saveCustomerNumber(data)" type="button" color="danger"
          class="d-flex justify-content-center align-items-center"
          :disabled="!data.customer_number || !data.expected_name"><span>{{ $t("global.buttons.send") }}</span>
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  </div>

  <MDBRow>
    <MDBCol col="12" md="6">
      <div class="pt-2">
        <MDBBtn type="button" color="danger" @click="addNewNumberFields">{{
          $t("buttons.moreCustomerNumbers")
          }}</MDBBtn>
      </div>
    </MDBCol>
  </MDBRow>

  <MDBModal v-model="modal" tabindex="-1" centered size="xl" @hide="hideModal">
    <ConfirmCsNumber :data="csNumberData" @close="(e) => hideModal()" />
  </MDBModal>
</template>

<script setup>
import { MDBRow, MDBCol, MDBBtn, MDBModal } from "mdb-vue-ui-kit";

import { useStore } from "vuex";
import { computed, defineExpose, reactive, watch, ref } from "vue";
import ConfirmCsNumber from "@/components/User/ProfileForms/ConfirmCsNumber.vue";

const store = useStore();
const me = computed(() => store.getters.getMe);

const models = reactive({
  customerNumbers: [
    {
      customer_number: "",
      wholesaler_id: null,
      expected_name: null
    },
  ],
});
const modal = ref(false);
const csNumberData = ref(null);

const hideModal = () => {
  modal.value = false;
};

const addNewNumberFields = () => {
  models.customerNumbers.push({
    customer_number: "",
    wholesaler_id: null,
    expected_name: null
  });
};

const saveCustomerNumber = async (payload) => {
  csNumberData.value = {
    expected_name: payload.expected_name,
    customer_number: payload.customer_number,
    dealer_id: me.value.plainId,
    id: payload?.id ?? null,
  };
  modal.value = true;
};

watch(me, async (newVal) => {
  if (newVal) {
    models.customerNumbers = [
      {
        customer_number: "",
        wholesaler_id: null,
      },
    ];
  }
});

defineExpose({
  models,
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

#client-nr {
  padding: 9px 18px 9px 9px;
  background: $secondaryGray;

  h5 {
    margin-bottom: 30px;
  }
}
</style>
