const messages = {
  "seeAll": "Vedi tutto",
  "clear": "Cancella",
  "back": "Indietro",
  "save": "Salva",
  "close": "Chiudi",
  "of": "Di",
  "to": "A",
  "copyrights": "© {date} Bridgestone Solutions. Tutti i diritti riservati.",
  "pageNotFound": "Pagina non trovata",
  "accessDenied": "Accesso negato",
  "search": "Cerca",
  "buttons": {
    "export": "Esporta",
    "delete": "Elimina",
    "save": "Salva",
    "cancel": "Annulla",
    "next": "Avanti",
    "back": "Indietro",
    "skip": "Salta",
    "send": "Invia"
  },
  "tooltips": {
    "info": "Hai bisogno di aiuto? 00 00 00 00"
  },
  "confirmation": {
    "header": "Conferma richiesta",
    "message": "Vuoi eliminare questa voce?"
  },
  "nav": {
    "dashboard": "Home",
    "account": "Profilo",
    "mailbox": "Posta in arrivo",
    "singleMailbox": "Messaggi"
  },
  "menu": {
    "account": "Profilo",
    "plus": "Informazioni su Bridgestone <span class=\"text-danger\">Plus</span>",
    "salesSupport": "Supporto alle vendite",
    "mailbox": "Posta in arrivo",
    "about": "Informazioni su BS Plus",
    "trainingAndCourses": "Formazione e corsi",
    "externalTraining": "Formazione esterna",
    "productInfo": "Informazioni sul prodotto",
    "productBrochures": "Brochure dei prodotti",
    "priceLists": "Listini prezzi",
    "price": "Prezzo",
    "complaintForm": "Modulo di reclamo",
    "sales_support": "Supporto alle vendite",
    "contact_service": "Informazioni di contatto e servizio",
    "logOut": "Disconnetti",
    "news": "Notizie"
  }
}

export default {
  messages,
};
