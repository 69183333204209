<template>
  <div id="indicators_data">
    <h4 class="my-3 text-uppercase">{{ $t("account.profile.operatingIndicators") }}</h4>

    <MDBRow>
      <MDBCol col="12" md="4">
        <label for="tireSalesNumber" class="label px-3 text-uppercase">
          {{ $t("account.profile.tireSale") }}
        </label>
        <MDBInput
          type="text"
          @input="(event) => numberOnly(event, 'tireSalesNumber')"
          class="form-control"
          :placeholder="$t('account.profile.tireSaleLabel')"
          aria-label="XXX"
          aria-describedby="basic-addon1"
          id="tireSalesNumber"
          v-model="models.tireSalesNumber"
        />
      </MDBCol>
      <MDBCol col="12" md="4">
        <label for="davon" class="label px-3 text-uppercase">
          {{ $t("account.profile.inches") }}
        </label>
        <MDBInput
          type="text"
          @input="
            (event) => {
              numberOnly(event, 'overEighteenInch');
            }
          "
          class="form-control"
          :placeholder="$t('account.profile.inchesLabel')"
          :aria-label="$t('account.profile.inchesLabel')"
          aria-describedby="basic-addon1"
          id="davon"
          v-model="models.overEighteenInch"
        >
          <span class="trailing">%</span>
        </MDBInput>
        <span v-if="errors.no100inch" class="text-danger">
          {{ $t("account.profile.errorRange") }}
        </span>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol col="12">
        <label class="label px-3 text-uppercase">
          {{ $t("account.profile.segmentSale") }}
        </label>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol col="12" md="2">
        <MDBInput
          type="text"
          @input="(event) => numberOnly(event, 'budgetPremium')"
          class="form-control"
          :placeholder="$t('account.profile.premBudget')"
          :aria-label="$t('account.profile.premBudget')"
          aria-describedby="basic-addon1"
          id="budgetPremium"
          v-model="models.budgetPremium"
        >
          <span class="trailing">%</span>
        </MDBInput>
      </MDBCol>
      <MDBCol col="12" md="2">
        <MDBInput
          type="text"
          @input="(event) => numberOnly(event, 'budgetMid')"
          class="form-control"
          :placeholder="$t('account.profile.midBudget')"
          :aria-label="$t('account.profile.midBudget')"
          aria-describedby="basic-addon1"
          id="budgetMid"
          v-model="models.budgetMid"
        >
          <span class="trailing">%</span>
        </MDBInput>
      </MDBCol>
      <MDBCol col="12" md="2">
        <MDBInput
          type="text"
          @input="(event) => numberOnly(event, 'budgetLow')"
          class="form-control"
          :placeholder="$t('account.profile.budBudget')"
          :aria-label="$t('account.profile.budBudget')"
          aria-describedby="basic-addon1"
          id="budgetLow"
          v-model="models.budgetLow"
        >
          <span class="trailing">%</span>
        </MDBInput>
      </MDBCol>
    </MDBRow>
    <MDBRow v-if="errors.no100budget">
      <MDBCol col="12">
        <span class="text-danger">
          {{ $t("account.profile.error100") }}
        </span>
      </MDBCol>
    </MDBRow>

    <MDBRow v-if="isGermany">
      <MDBCol col="12">
        <label for="anzahl" class="label px-3 text-uppercase">
          {{ $t("account.profile.tyreSizes") }}
        </label>
      </MDBCol>
      <MDBCol col="12" md="4" class="pr-0">
        <input
          type="text"
          @input="
            (event) => numberOnly(event, 'numberOfSetsOfSeasonalTiresBudget')
          "
          class="form-control"
          :placeholder="$t('account.profile.numberOfSetsOfSeasonalTiresBudget')"
          :aria-label="$t('account.profile.numberOfSetsOfSeasonalTiresBudget')"
          aria-describedby="basic-addon1"
          v-model="models.numberOfSetsOfSeasonalTiresBudget"
        />
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol col="12">
        <label for="verteilung" class="label px-3 text-uppercase">
          {{ $t("account.profile.distribution") }}
        </label>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol col="12" md="2">
        <MDBInput
          type="text"
          @input="(event) => numberOnly(event, 'distributionSummer')"
          class="form-control"
          :placeholder="$t('summerIn')"
          :aria-label="$t('summerIn')"
          aria-describedby="basic-addon1"
          v-model="models.distributionSummer"
        >
          <span class="trailing">%</span>
        </MDBInput>
      </MDBCol>
      <MDBCol col="12" md="2">
        <MDBInput
          type="text"
          @input="(event) => numberOnly(event, 'distributionWinter')"
          class="form-control"
          :placeholder="$t('winterIn')"
          :aria-label="$t('winterIn')"
          aria-describedby="basic-addon1"
          v-model="models.distributionWinter"
        >
          <span class="trailing">%</span>
        </MDBInput>
      </MDBCol>
      <MDBCol col="12" md="2">
        <MDBInput
          type="text"
          @input="(event) => numberOnly(event, 'distributionAll')"
          class="form-control"
          :placeholder="$t('allSeasonIn')"
          :aria-label="$t('allSeasonIn')"
          aria-describedby="basic-addon1"
          v-model="models.distributionAll"
        >
          <span class="trailing">%</span>
        </MDBInput>
      </MDBCol>
    </MDBRow>
    <MDBRow v-if="errors.no100distribution">
      <MDBCol col="12">
        <span class="text-danger">
          {{ $t("account.profile.error100") }}
        </span>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { reactive, ref, watch, defineExpose, computed } from "vue";
import { useStore } from "vuex";
import { MDBRow, MDBCol, MDBInput } from "mdb-vue-ui-kit";

const store = useStore();
const LANG = localStorage.getItem("lang");
const isGermany = computed(() => LANG === "de");

const percentage = ref(0);

const models = reactive({
  tireSalesNumber: 0,
  overEighteenInch: 0,
  budgetLow: 0,
  budgetMid: 0,
  budgetPremium: 0,
  distributionSummer: 0,
  distributionWinter: 0,
  distributionAll: 0,
  numberOfSetsOfSeasonalTiresBudget: 0,
});

const errors = reactive({
  no100budget: false,
  no100distribution: false,
  no100inch: false,
});

const numberOnly = (event, model) => {
  models[model] = event.target.value.replace(/\D+/g, "");
  event.target.value = event.target.value.replace(/\D+/g, "");
};

const sumNotEq100 = (array) => {
  let initial = 0;
  return (
    array.reduce((acc, cur) => parseInt(acc) + parseInt(cur), initial) !== 100
  );
};

const range = (min, max, value) => {
  return min <= value && value <= max;
};

watch(
  () => ({ ...models }),
  (newVal) => {
    let numerator, denominator;
    if (isGermany.value) {
      numerator = Object.values(newVal).filter((i) => i).length;
      denominator = Object.keys(models).length;
    } else {
      numerator = Object.values(newVal).filter((i) => i).length;
      denominator = Object.keys(models).length - 1;
    }

    percentage.value = (numerator / denominator) * 100;
  }
);

watch(
  [() => models.budgetLow, () => models.budgetMid, () => models.budgetPremium],
  (arg) => {
    errors.no100budget = sumNotEq100(arg);
  }
);

watch(
  () => models.overEighteenInch,
  (newVal) => {
    errors.no100inch = !range(0, 100, newVal);
  }
);

watch(
  [
    () => models.distributionSummer,
    () => models.distributionWinter,
    () => models.distributionAll,
  ],
  (arg) => {
    errors.no100distribution = sumNotEq100(arg);
  }
);

watch(
  () => store.getters.getProfile,
  (newVal) => {
    models.tireSalesNumber = newVal.tireSalesNumber;
    models.overEighteenInch = newVal.overEighteenInch;
    models.budgetLow = newVal.budgetLow;
    models.budgetMid = newVal.budgetMid;
    models.budgetPremium = newVal.budgetPremium;
    models.distributionSummer = newVal.distributionSummer;
    models.distributionWinter = newVal.distributionWinter;
    models.distributionAll = newVal.distributionAll;
    models.numberOfSetsOfSeasonalTiresBudget =
      newVal.numberOfSetsOfSeasonalTiresBudget;
  }
);

defineExpose({
  models,
  percentage,
  errors,
});
</script>

<style lang="scss" scoped>
:deep(.form-outline .form-control ~ .form-notch .form-notch-leading),
:deep(.form-outline .form-control ~ .form-notch .form-notch-trailing) {
  border-radius: 0;
  border: 1px solid #616161;
}

:deep(.form-outline .form-control ~ .form-notch .form-notch-leading) {
  border-right: none;
}

:deep(.form-outline .form-control ~ .form-notch .form-notch-trailing) {
  border-left: none;
}
</style>
