const messages = {
    "title": "Min profil",
    "desc": "Velkommen til dit personlige område. Her kan du opdatere eller justere dine <br/>oplysninger og se din indbakke.",
    "descStaff": "Velkommen til dit personlige område. Her kan du opdatere eller justere dine oplysninger.",
    "buttons": {
        "showProfile": "Vis profil",
        "userProfile": "GÅ TIL STAMDATA",
        "mailbox": "GÅ TIL INDBAKKE"
    },
    "categories": {
        "baseData": {
            "title": "Stamdata",
            "desc": "Udfyld dine personlige oplysninger og virksomhedsoplysninger for korrekt registrering."
        },
        "mailbox": {
            "desc": "Modtag vigtige meddelelser, ordrebekræftelser og nyheder i din indbakke."
        },
        "bankstatement": {
            "title": "Kontoudtog",
            "desc": "Her kan du se status for din personlige Bridgestone Plus-konto og tjekke dine optjente og brugte belønningspoint."
        }
    },
    "profile": {
        "title": "Stamdata",
        "description": "Velkommen til dit personlige område. Her kan du opdatere eller justere dine oplysninger.",
        "personalData": "PERSONLIGE DATA",
        "password": "Adgangskode",
        "password1": "Adgangskode (valgfrit)*",
        "password2": "Adgangskode (gentag)*",
        "formOfAddress": "Titel",
        "choose": "Vælg titel",
        "mr": "Hr.",
        "mrs": "Fr.",
        "diverse": "Andet",
        "firstname": "Fornavn",
        "surname": "Efternavn",
        "position": "STILLING I VIRKSOMHEDEN",
        "operatingData": "VIRKSOMHEDSDATA",
        "company": "Virksomhed",
        "legalForm": "Juridisk struktur",
        "name": "Navn",
        "streetHouseNr": "Virksomhedsadresse",
        "street": "Gade",
        "postcode": "Postnummer",
        "location": "By",
        "country": "Land",
        "de": "Tyskland",
        "at": "Østrig",
        "ch": "Schweiz",
        "nl": "Holland",
        "it": "Italien",
        "language": "Sprog",
        "french": "Fransk",
        "italian": "Italiensk",
        "german": "Tysk",
        "nederland": "Hollandsk",
        "openingHours": "Åbningstider & kontakt",
        "phone": "Telefon",
        "website": "Websted",
        "operatingIndicators": "Driftsnøgletal",
        "tireSale": "Årligt dæksalg ca. i stykker",
        "tireSaleLabel": "Salg af dæk i store mængder",
        "inches": "Heraf ≥ 18 tommer (i %)",
        "inchesLabel": "Heraf 18 tommer eller mere i %",
        "segmentSale": "Dæksalg pr. segment (andel Budget / Mellem / Premium i %)",
        "premBudget": "Premium",
        "midBudget": "Mellem",
        "budBudget": "Budget",
        "distribution": "FORDELING SOMMER / VINTER / HELÅR (i %)",
        "tyreSizes": "Antal sæsonopbevaringspladser (sæt dæk)",
        "diffDeliveryAddres": "Tilføj en leveringsadresse",
        "yourEmailAddress": "Din e-mailadresse.",
        "clientNr": "Kundenumre",
        "clientNrSingular": "Kundenummer",
        "clientNrContent": "<span class='text-danger fw-bold'>VIGTIGT - LÆS VENLIGST:</span><br /><br /> For korrekt tildeling af dine dækkøb hos en grossist og/eller Bridgestone er det nødvendigt, at du <b>angiver dine respektive kundenumre.</b><br/><br/>Hvis du har <b>flere kundenumre hos en grossist og/eller Bridgestone,</b> f.eks. for din hovedbutik og filialer, skal du først angive hovedbutikkens kundenummer.<br/><br/><b>Salget fra de andre kundenumre vil derefter blive tilskrevet din hovedbutik.</b>",
        "supplySource": "Forsyningskilde",
        "email": "E-mailadresse",
        "staffEmail": "Deltagerens e-mailadresse",
        "registered": "Registreret den",
        "registeredAt": "Registreret den",
        "lastLogIn": "Sidste login",
        "details": "Detaljer",
        "inDetail": "{name} i detaljer",
        "bsClientNr": "Dit Bridgestone kundenummer",
        "bftClientNr": "Dit Best4Tires kundenummer",
        "acceptTerms": "Accepter vilkår og betingelser for deltagelse.",
        "newCustomerNumbersInfo": "<span class='text-danger fw-bold'>VIGTIGT - LÆS VENLIGST:</span><br />Ved første indtastning af kundenummer bliver dine oplysninger verificeret for nøjagtighed og opfyldelse af vores distributionskriterier. Beslutningen om at aktivere kundenummer(ene) og forbinde dem med din profil kan tage op til 5 arbejdsdage.",
        "selectWholeSaler": "hos leverandøren (vælg venligst)",
        "cooperation": "MEDLEM / SAMARBEJDE",
        "error100": "Summen af værdierne skal være lig med 100%.",
        "errorRange": "Værdien skal være mellem 0 og 100",
        "btw_number_label": "dit virksomheds momsnummer",
        "btw_number": "Momsregistreringsnummer",
        "mobile": "Telefonnummer",
        "mobile_label": "Dit mobiltelefonnummer",
        "iban_number": "Din virksomheds IBAN-nummer",
        "iban_number_label": "IBAN-nummer",
        "yourCsNr": "Dine kundenumre",
        "yourLastName": "Dit efternavn",
        "positionLabel": "Vælg en stilling",
        "yourFirstName": "Dit fornavn",
        "companyLabel": "Virksomhed",
        "numberOfSetsOfSeasonalTiresBudget": "Årlig opbevaring",
        "contactInfo": "Hvis du har spørgsmål, kontakt os venligst:",
        "addAnotherCsNumber": "Tilføj Bridgestone kundenummer",
        "activityAndProfile": "FORRETNINGFORHOLD OG PROFIL"
    }
}

export default {
    messages
}