<template>
  <MDBCol col="lg-3" class="app-box">
    <MDBCardBody class="app-box-body">
      <MDBCardTitle class="title">
        <template v-if="props.app.logo == '1'">
          <img :src="imageSrc" alt="logo" class="app-logo" />
        </template>
        <template v-else>
          Bridgestone <span class="selection">{{ props.app.name }}</span>
        </template>
      </MDBCardTitle>
      <MDBCardText class="desc" v-html="props.app.has_translations ? props.app.translated_data[currentLocale] : computedDescription"></MDBCardText>
      <MDBCardFooter class="footer">
        <MDBBtn v-if="props.app.status_for_user === 0" color="warning" @click="requestAccess(props.app.id)">{{
          $t("myApps.buttons.requestAccess") }}
        </MDBBtn>
        <MDBBtn v-if="props.app.status_for_user === 1" color="info">{{ $t("myApps.buttons.pending") }}
        </MDBBtn>
        <MDBBtn v-if="props.app.status_for_user === 2" color="success success-black" @click="openUrl(props.app.url)">{{
          $t("myApps.buttons.open") }}
        </MDBBtn>
      </MDBCardFooter>
    </MDBCardBody>
  </MDBCol>
</template>

<script setup>
import { useStore } from "vuex"
import { MDBCol, MDBBtn, MDBCardText, MDBCardFooter, MDBCardTitle, MDBCardBody } from "mdb-vue-ui-kit"
import { computed, defineProps } from "vue"
import { AppService } from "@/services/app.service"

// Initialize Vuex store
const store = useStore();

// Define component props
const props = defineProps({
  app: {
    type: Object,
    required: true
  },
});

// Computed property to dynamically generate the image source
const imageSrc = computed(() => {
  try {
    return require(`@/assets/img/apps/${props.app.code_name}.png`);
  } catch (e) {
    console.warn(`Image not found for code_name: ${props.app.code_name}`);
    return '@/assets/img/apps/default.png'; // Fallback image
  }
});

// Retrieve the current user from Vuex store
const user = computed(() => store.getters.getMe);
const currentLocale = computed(() => store.getters.getCurrentLocale);

// Computed property to determine the correct description to display
const computedDescription = computed(() => {
  const { has_translations, translated_data, description } = props.app;
  if (has_translations && translated_data) {
    // Attempt to get the translated description for the current locale
    const translatedDescription = translated_data[currentLocale];
    // Fallback to the default description if translation is missing
    return translatedDescription || description || '-';
  }

  // If no translations are available, use the default description
  return description || '-';
});

// Function to open a URL in a new tab
const openUrl = (url) => {
  window.open(url, '_blank');
};

// Function to request access to an app
const requestAccess = async (appId) => {
  try {
    const data = {
      app_id: appId,
      user_id: user.value.plainId
    };
    await AppService.accessRequest(data);
    await store.dispatch("loadDealerApps");
    // Optionally, you can provide user feedback here (e.g., success message)
  } catch (error) {
    console.error("Error requesting access:", error);
    // Optionally, handle the error (e.g., show an error message)
  }
};
</script>


<style lang="scss" scoped>
@import "@/styles/_variables.scss";

#apps-component {
  .app-logo {
    max-height: 50px;
  }

  .app-box {
    margin-bottom: 30px;

    .app-box-body {
      padding: 20px;
      display: block;
      border: 1px solid #D9D9D9;

      .title {
        font-weight: bold;
        height: 50px;
        font-size: 14px;
        text-transform: uppercase;
        display: block;
        padding: 0 10px;
        text-align: center;

        .selection {
          color: #EC1C24
        }
      }

      .desc {
        display: block;
        font-size: 14px;
        margin-top: 19px;
        border-top: 1px solid #D9D9D9;
        padding: 20px 10px 0;
        height: 120px;
        max-height: 400px;

        span {
          display: block !important;
          font-weight: bold !important;
          color: #EC1C24 !important;
        }
      }

      .footer {
        margin-top: 30px;
        display: block;
        font-size: 18px;
        text-transform: uppercase;
        text-align: right;

        .success-black {
          background-color: #000 !important;
          color: #fff !important;
        }
      }
    }
  }
}
</style>
