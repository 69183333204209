<template>
  <button type="button" class="btn btn-danger text-uppercase" @click="showAddressForm = !showAddressForm">
    {{ $t("buttons.newAddress") }}
  </button>
  <div v-if="showAddressForm">
    <VeeForm class="form user-form" @submit="onSubmit">
      <div>
        <label for="company" class="label px-3 text-uppercase pt-5">
          {{ $t("account.profile.company") }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.company')" :aria-label="$t('account.profile.company')"
          aria-describedby="basic-addon1" v-model="company_name" name="setCompanyName" :rules="{ required: true }"
          :label="$t('account.profile.company')" id="company" />
        <ErrorMessage name="setCompanyName" class="text-danger text-small text-end m-0 pt-1" />
      </div>

      <div>
        <label for="first_name" class="label px-3 text-uppercase pt-1">
          {{ $t("contact.form.contactPerson") }} {{ $t("account.profile.firstname") }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.firstname')"
          :aria-label="$t('account.profile.firstname')" aria-describedby="basic-addon1" v-model="first_name" name="first_name"
          :rules="{ required: true }" :label="$t('account.profile.firstname')" id="first_name" />
        <ErrorMessage name="first_name" class="text-danger text-small text-end m-0 pt-1" />
      </div>

      <div>
        <label for="last_name" class="label px-3 text-uppercase pt-1">
          {{ $t("contact.form.contactPerson") }} {{ $t("account.profile.surname") }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.surname')" :aria-label="$t('account.profile.surname')"
          aria-describedby="basic-addon1" v-model="last_name" name="last_name" :rules="{ required: true }"
          :label="$t('account.profile.surname')" id="last_name" />
        <ErrorMessage name="last_name" class="text-danger text-small text-end m-0 pt-1" />
      </div>

      <div>
        <label for="street" class="label px-3 text-uppercase pt-1">
          {{ $t("account.profile.streetHouseNr") }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.streetHouseNr')"
          :aria-label="$t('account.profile.streetHouseNr')" aria-describedby="basic-addon1" v-model="street" name="setStreet"
          :rules="{ required: true }" :label="$t('account.profile.streetHouseNr')" id="street" />
        <ErrorMessage name="setStreet" class="text-danger text-small text-end m-0 pt-1" />
      </div>

      <div>
        <label for="postal_code" class="label px-3 text-uppercase pt-1">
          {{ $t("account.profile.postcode") }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.postcode')"
          :aria-label="$t('account.profile.postcode')" aria-describedby="basic-addon1" v-model="postal_code"
          name="setPostalCode" :rules="{ required: true }" :label="$t('account.profile.postcode')" id="postal_code" />
        <ErrorMessage name="setPostalCode" class="text-danger text-small text-end m-0 pt-1" />
      </div>

      <div>
        <label for="city" class="label px-3 text-uppercase pt-1">
          {{ $t("account.profile.location") }}
        </label>
        <Field type="text" class="form-control" :placeholder="$t('account.profile.location')"
          :aria-label="$t('account.profile.location')" aria-describedby="basic-addon1" v-model="city" name="setCity"
          :rules="{ required: true }" :label="$t('account.profile.location')" id="city" />
        <ErrorMessage name="setCity" class="text-danger text-small text-end m-0 pt-1" />
      </div>

      <div>
        <label for="country" class="label px-3 text-uppercase">
          {{ $t("account.profile.country") }}
        </label>
        <Field name="setCountry" as="select" class="form-select" :aria-label="$t('account.profile.country')" v-model="country"
          id="country" :rules="{ required: true }">
          <option disabled :value="null">{{ $t("account.profile.choose") }}</option>
          <option value="fe" v-if="isGermany || isSwitzerland">
            {{ $t("account.profile.french") }}
          </option>
          <option value="it" v-if="isGermany || isSwitzerland">
            {{ $t("account.profile.italian") }}
          </option>
          <option value="de" v-if="isGermany || isSwitzerland">
            {{ $t("account.profile.german") }}
          </option>
          <option value="nl" v-if="isDutch">
            {{ $t("account.profile.nederland") }}
          </option>
        </Field>

        <ErrorMessage name="setCountry" class="text-danger text-small text-end m-0 pt-1" />
      </div>

      <MDBBtn type="submit" color="danger" :disabled="loading" class="d-flex justify-content-center align-items-center">
        <span>{{ $t("global.buttons.send") }}</span>
        <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
      </MDBBtn>
    </VeeForm>
  </div>
</template>

<script setup>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { MDBBtn } from "mdb-vue-ui-kit";
import Loader from "@/components/General/Loader/index.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");

const store = useStore();
const showAddressForm = ref(false);
const company_name = ref(null);
const partner_name = ref(null);
const street = ref(null);
const postal_code = ref(null);
const first_name = ref(null);
const last_name = ref(null);
const city = ref(null);
const country = ref(LANG);

const loading = computed(() => store.getters.getPending);
const user = computed(() => store.getters.getMe);

const onSubmit = async () => {
  const data = {
    user_id: user.value.plainId,
    street: street.value,
    postal_code: postal_code.value,
    city: city.value,
    country: country.value,
    company_name: company_name.value,
    partner_name: first_name.value + " " + last_name.value,
    first_name: first_name.value,
    last_name: last_name.value,
  };

  await store.dispatch("storeAddress", data);
  await store.dispatch("loadUserAddresses");

  clearForm();
};

const clearForm = () => {
  street.value = null;
  postal_code.value = null;
  city.value = null;
  country.value = null;
  company_name.value = null;
  partner_name.value = null;
  showAddressForm.value = false;
};
</script>
