<template>
  <div class="border-top">
    <p class="copyrights font-10">
      <span class="d-none d-lg-inline">
        {{ $t("global.copyrights", { date: new Date().getFullYear() }) }}
      </span>
      <span v-if="textPages.length > 0" class="w-100">
        <span v-for="(textPage, index) in textPages" :key="index" class="d-block d-sm-inline">
          <router-link v-if="!textPage.external_link[storedLocale]" :to="{
            name: 'text_page',
            params: {
              id: textPage.id,
              slug: textTransform.convertToSlug(textPage.title[storedLocale]),
            },
          }" class="link hide-mobile-border font-10">
            {{ textPage.title[storedLocale] }}
          </router-link>
          <a v-else target="_blank" :href="textPage.external_link[storedLocale]"
            class="link hide-mobile-border font-10">{{
              textPage.title[storedLocale] }}</a>
        </span>
      </span>

      <span class="d-block d-lg-none">
        {{ $t("global.copyrights", { date: new Date().getFullYear() }) }}
      </span>
      <span class="d-none">{{ npmVersion }}</span>
    </p>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";
import { version } from "./../../../package.json";
import textTransform from "@/plugins/textTransform";

const store = useStore();
const storedLocale = computed(() => store.getters.getCurrentLocale);

const npmVersion = version;

onMounted(async () => {
  if (localStorage.getItem("npmVersion") != npmVersion) {
    localStorage.setItem("npmVersion", npmVersion);
    window.location.reload();
  } else {
    localStorage.setItem("npmVersion", npmVersion);
  }

  setInterval(() => {
    if (localStorage.getItem("npmVersion") != npmVersion) {
      localStorage.setItem("npmVersion", npmVersion);
      window.location.reload(true);
    }
  }, 10000);
});

const textPages = computed(() => store.getters.getTextPages);
</script>

<style scoped lang="scss">
@import "../../styles/variables";

.border-top {
  border-top: 1px solid $lightGray;
  margin-top: 35px;

  @media screen and (max-width: $mdBreakpoint) {
    margin-top: 0;
  }
}

.link {
  color: $lightGray;
  border-left: 1px solid $lightGray;
  padding-left: 8px;
  margin-left: 8px;
}

.hide-mobile-border {
  @media screen and (max-width: $lgBreakpoint) {
    border-left: 0 solid $lightGray;
    padding-left: 0;
    margin-left: 0;
  }
}

.copyrights {
  color: $lightGray;
  padding-top: 3px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
}
</style>
