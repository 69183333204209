import messages from "@/i18n/index";
import { createI18n } from "vue-i18n";

const lang = localStorage.getItem("currentLocale");
const i18n = createI18n({
  locale: lang,
  fallbackLocale: lang,
  messages,
  legacy: false,
});

export default i18n;
