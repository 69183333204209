<template>
  <VeeForm @submit="handleSubmit()" class="form" v-if="!isDone">
    <div v-for="(question, index) in questionsData" :key="index">
      <Radio v-bind:props="question" v-bind="{ question, validate }"
        @validation-effect="(e) => handleValidationEffect(e, index)" />
    </div>

    <MDBRow>
      <MDBCol>
        <MDBBtn type="submit" color="danger" :disabled="loading"
          class="d-flex justify-content-center align-items-center"><span>{{ $t("global.buttons.send") }}</span>
          <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
        </MDBBtn>
      </MDBCol>
    </MDBRow>
  </VeeForm>
  <Summary :training="trainingData" @store-points="handleSubmit()" @download-certificate="downloadCertificate()"
    v-else />
</template>

<script setup>
import { Form as VeeForm } from "vee-validate";
import { useRoute } from "vue-router";
import Radio from "@/components/Trainings/Form/Inputs/Radio.vue";
import Summary from "@/components/Trainings/Form/Result/Summary.vue";
import Loader from "@/components/General/Loader/index.vue";
import { MDBCol, MDBRow, MDBBtn } from "mdb-vue-ui-kit";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { PDFDocument, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { TrainingService } from "@/services/training.service";
import { ProfileService } from "@/services/profile.service";
import i18n from "@/plugins/i18n";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const store = useStore();
const route = useRoute();
const profile = computed(() => store.getters.getProfile);
let showSuccessModal = ref(false);
const loading = computed(() => store.getters.getPending);
const certificates = computed(() => store.getters.getCertificateTexts);
const training = computed(() => store.getters.getTraining);

const fetchData = () => {
  store.dispatch("loadTrainingById", route.params.id);
  store.dispatch("loadQuestions", route.params.id);
  store.dispatch("loadCertificateTexts", route.params.id);
};

fetchData();

const fetchTrainingResult = async () => {
  if (!profile.value || !profile.value.name || !profile.value.forename) {
    return false;
  }

  const data = { trainingId: route.params.id, userId: user.value.id };
  await store.dispatch("loadTrainingResult", data);
  const response = await ProfileService.userProfileData(user.value.id);

  profile.value = response.data.data;
};

const downloadCertificate = async () => {
  if (!profile.value || !profile.value.name || !profile.value.forename) {
    toast.clearAll();

    toast(i18n.global.t("fillProfile"), {
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.TOP_RIGHT,
    });
    return false;
  }
  const url = `${window.location.origin}/files/blank-verkaufsstarke-certificate.pdf`;

  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const dinproBoldUrl = `${window.location.origin}/fonts/DINPro/DINPro-Bold.otf`;
  const dinproBold = await fetch(dinproBoldUrl).then((res) =>
    res.arrayBuffer()
  );
  pdfDoc.registerFontkit(fontkit);
  const dinproBoldFont = await pdfDoc.embedFont(dinproBold);

  const dinproMediumUrl = `${window.location.origin}/fonts/DINPro/DINPro-Medium.otf`;
  const dinproMedium = await fetch(dinproMediumUrl).then((res) =>
    res.arrayBuffer()
  );
  pdfDoc.registerFontkit(fontkit);
  const dinproMediumFont = await pdfDoc.embedFont(dinproMedium);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];
  const { height } = firstPage.getSize();
  firstPage.drawText(i18n.global.t("certificate.header"), {
    x: 40,
    y: height / 2 + 180,
    size: 50,
    font: dinproBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(`${profile.value.forename} ${profile.value.name}`, {
    x: 40,
    y: height / 2 + 125,
    size: 20,
    font: dinproBoldFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(i18n.global.t("certificate.atCourse"), {
    x: 40,
    y: height / 2 + 85,
    size: 15,
    font: dinproMediumFont,
    color: rgb(0, 0, 0),
  });

  if (training.value.title.trim().split(/\s+/).length > 5) {
    firstPage.drawText(
      `${training.value.title.split(" ").slice(0, 5).join(" ").trim()}`,
      {
        x: 40,
        y: height / 2 + 65,
        size: 15,
        font: dinproBoldFont,
        color: rgb(0, 0, 0),
      }
    );
    firstPage.drawText(
      `${training.value.title
        .split(" ")
        .slice(5, training.value.title.trim().split(/\s+/).length + 1)
        .join(" ")
        .trim()}`,
      {
        x: 40,
        y: height / 2 + 50,
        size: 15,
        font: dinproBoldFont,
        color: rgb(0, 0, 0),
      }
    );
  } else {
    firstPage.drawText(`${training.value.title}`, {
      x: 40,
      y: height / 2 + 55,
      size: training.value.title.length > 40 ? 15 : 20,
      font: dinproBoldFont,
      color: rgb(0, 0, 0),
    });
  }

  firstPage.drawText(i18n.global.t("certificate.takePart"), {
    x: 40,
    y: height / 2 + 30,
    size: 15,
    font: dinproMediumFont,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(i18n.global.t("certificate.contains"), {
    x: 40,
    y: height / 2 - 35,
    size: 14,
    font: dinproBoldFont,
    color: rgb(0, 0, 0),
  });

  const data = new Date(endQuizDate.value);

  firstPage.drawText(
    `Frankfurt am Main, den ${data.getDate()}.${data.getMonth() + 1
    }.${data.getFullYear()}`,
    {
      x: 40,
      y: height / 2 - 180,
      size: 11,
      font: dinproMediumFont,
      color: rgb(0, 0, 0),
    }
  );

  if (certificates.value.length > 0) {
    for (let i = 0; i < certificates.value.length; i++) {
      firstPage.drawText(certificates.value[i].text, {
        x: certificates.value[i].positionX,
        y: certificates.value[i].positionY,
        size: certificates.value[i].size,
        font: dinproMediumFont,
        color: rgb(0, 0, 0),
      });
    }
  }

  const pdfBytes = await pdfDoc.save();

  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = setCertificateName(training.value.title);
  link.click();
  URL.revokeObjectURL(link.href);

  const certificateData = {
    trainingId: route.params.id,
    userId: user.value.id,
    file: await fileToBase64(blob),
  };

  await TrainingService.sendTrainingCertificate(certificateData);
};

const fileToBase64 = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const setCertificateName = (str) => {
  return (
    "Zertifikat_" +
    String(str)
      .normalize("NFKD") // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
      .replace(/\s+/g, "-") // replace spaces with hyphens
      .replace(/-+/g, "-")
  ); // remove consecutive hyphens
};

const trainingData = computed(() => store.getters.getTraining);
const questionsData = computed(() => store.getters.getQuestions);
const user = computed(() => store.getters.getMe);

const isDone = computed(() => store.getters.getTrainingResult);
const endQuizDate = computed(() => store.getters.getEndQuizDate);

fetchTrainingResult();

let correctAnswers = ref([]);

const validate = ref(0);

const handleSubmit = async () => {
  validate.value = validate.value + 1;

  if (correctAnswers.value.length == questionsData.value.length) {
    const data = {
      user_id: user.value.id,
      training_id: trainingData.value.id,
      attempt: validate.value,
      calculated_points: trainingData.value.points,
    };

    const response = await store.dispatch("storeResult", data);

    if (response) {
      showSuccessModal.value = true;
      await fetchTrainingResult();
      await store.dispatch("me");
    }
  }
};

const handleValidationEffect = (correctAnswer, key) => {
  if (!correctAnswer && correctAnswers.value.includes(key)) {
    correctAnswers.value = correctAnswers.value.filter((item) => item !== key);
  } else if (correctAnswer && !correctAnswers.value.includes(key)) {
    correctAnswers.value.push(key);
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";
</style>
