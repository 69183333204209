<template>
  <MDBRow v-if="show" class="mt-5">
    <MDBCol col="lg-12">
      <div id="apps-component" class="bg-white">
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle v-if="$t('myApps.titleColorRev') === 'false'" class="panel-title">Bridgestone <span
                class="selection">{{ $t("myApps.my") }}</span></MDBCardTitle>
            <MDBCardTitle v-else class="panel-title"><span class="selection">{{ $t("myApps.my") }}</span>
              Bridgestone</MDBCardTitle>
            <MDBCardText>
              <MDBRow>
                <MDBCol col="lg-3" class="explain"><strong>{{ $t("myApps.directRoute") }}</strong></MDBCol>
                <MDBCol col="lg-3" class="explain"><strong>{{ $t("myApps.myApps") }}</strong>: {{
                  $t("myApps.myAppsDescription") }}</MDBCol>
                <MDBCol col="lg-3" class="explain"><strong>{{ $t("myApps.allApps") }}</strong>: {{
                  $t("myApps.allAppsDescription") }}</MDBCol>
              </MDBRow>
              <MDBRow class="mt-3">
                <MDBCol col="lg-12">
                  <div class="app-tabs">
                    <MDBTabs v-model="activeTabId1">
                      <!-- Tabs navs -->
                      <MDBTabNav tabsClasses="mb-3 app-tabs">
                        <MDBTabItem tabId="myApps" href="myApps">{{ $t("myApps.myApps") }}</MDBTabItem>
                        <MDBTabItem tabId="allApps" href="allApps">{{ $t("myApps.allApps") }}</MDBTabItem>
                      </MDBTabNav>
                      <!-- Tabs navs -->
                      <!-- Tabs content -->
                      <MDBTabContent>
                        <MDBTabPane tabId="myApps">
                          <MDBRow>
                            <template v-for="(app, index) in dealerApps" :key="index">
                              <AppCard v-if="app.status_for_user === 1 || app.status_for_user === 2" :app="app" />
                            </template>
                          </MDBRow>
                        </MDBTabPane>
                        <MDBTabPane tabId="allApps">
                          <MDBRow>
                            <AppCard v-for="(app, index) in dealerApps" :key="index" :app="app" />
                          </MDBRow>
                        </MDBTabPane>
                      </MDBTabContent>
                      <!-- Tabs content -->
                    </MDBTabs>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { useStore } from "vuex";
import { ref, onMounted, computed } from "vue";
import {
  MDBRow,
  MDBCol,
  MDBTabs,
  MDBTabNav,
  MDBTabContent,
  MDBTabItem,
  MDBTabPane, MDBCardText, MDBCardTitle, MDBCardBody, MDBCard
} from 'mdb-vue-ui-kit';

import AppCard from '@/components/AppsPanel/AppCard.vue';

const store = useStore();
const dealerApps = computed(() => store.getters.getDealerApps);
const activeTabId1 = ref('myApps');
const show = ref(false);

onMounted(async () => {
  await store.dispatch("loadDealerApps");
  show.value = true;
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

#apps-component {
  color: $darkGray;
  font-family: "BridgestoneType", Arial, sans-serif;
  padding: 20px;

  .panel-title {
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;

    .selection {
      color: $dangerRed
    }
  }

  .app-tabs {
    li.nav-item {
      a.nav-link {
        background-color: $silverWhite;
        border: 1px solid $gainsboro !important;
        border-bottom: 0 !important;

        &.active {
          background-color: $silverWhite;
          border: 1px solid $gainsboro !important;
          border-bottom: 0 !important;
        }
      }
    }
  }
}
</style>