const messages = {
  "title": "Tervetuloa BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Onko sinulla jo tili?</b><br/>Kirjaudu sisään sähköpostiosoitteellasi ja salasanallasi.<br/><br/><b>Oletko uusi täällä?</b><br/>Jos olet uusi käyttäjä, rekisteröidythän.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Kirjaudu sisään / Rekisteröidy",
    "back": "Palaa sisäänkirjautumiseen",
    "changeLanguage": "Vaihda kieli",
    "start": "Aloita"
  },
  "languageSelect": {
    "label": "Kielen valinta",
    "description": "Valitse kielesi, ole hyvä"
  }
}

export default {
  messages
}