<template>
  <CategoryTop small :title="$t('mailbox.title')" :breadcrumbs-parent-routes="['account', 'mailbox']"
    :description="$t('mailbox.desc')" />
  <div class="mail-reader position-relative" v-if="message">
    <MDBRow class="sender-row">
      <MDBCol col="12" sm="3" xl="2">
        <div class="d-flex align-items-center">
          <p class="msg-label text-uppercase fw-bold mb-0 d-flex align-items-center">
            {{ $t("mailbox.message.sender") }}:
          </p>
        </div>
      </MDBCol>
      <MDBCol col="12" sm="9" xl="10" class="px-0">
        <div class="d-flex justify-content-between align-items-center h-100 mx-0">
          <div class="d-flex align-items-center accent-blue msg-label fw-bold mb-0 px-3">
            {{ $t(`mailbox.sender.${message.threads.sender}`) }}
          </div>

          <div class="d-flex justify-content-end align-items-center buttons-container">
            <button v-if="index !== 0" type="button" class="btn font-18 py-1" @click="openMessage(index - 1)">
              <MDBIcon icon="chevron-left" size="1x" />
            </button>
            <span>{{ index + 1 }} {{ $t(`global.of`).toLowerCase() }} {{ length }}</span>
            <button v-if="index + 1 !== length" type="button" class="btn font-18 pe-1" @click="openMessage(index + 1)">
              <MDBIcon icon="chevron-right" size="1x" />
            </button>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
    <MDBRow class="topic-row">
      <MDBCol col="12" sm="3" xl="2">
        <div class="d-flex align-items-center">
          <p class="msg-label text-uppercase fw-bold mb-0 d-flex align-items-center">
            {{ $t("mailbox.message.thread") }}:
          </p>
        </div>
      </MDBCol>
      <MDBCol col="12" sm="9" xl="10" class="d-flex align-items-center accent-blue">
        <div class="d-flex justify-content-between align-items-center h-100 mx-0 msg-label fw-bold">
          {{ message.messages.title[currentLocale] }}
        </div>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol sm="8" xl="10">
        <div v-html="message.messages.content[currentLocale]" class="mail-reader-content"></div>
      </MDBCol>
      <MDBCol sm="4" xl="2">
        <div class="mail-reader-date">
          {{ parseDate(message.created_at) }}
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, watch } from "vue";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import dates from "@/plugins/dates";
import { useI18n } from "vue-i18n";
const { t } = useI18n() // Import the translation function from i18n

const store = useStore();
const route = useRoute();
const router = useRouter();

const currentLocale = computed(() => store.getters.getCurrentLocale);

store.dispatch("loadMessages");

const messages = computed(() => store.getters.getMessages);

const message = computed(() => {
  return store.getters.getMessages.find((item) => item.id == route.params.id);
});

const index = computed(() => {
  return store.getters.getMessages.findIndex(
    (item) => item.id == route.params.id
  );
});

const length = computed(() => {
  return store.getters.getMessages.length;
});

const openMessage = (id) => {
  let messageId = messages.value[id].id;
  router.push({ name: "singleMailbox", params: { id: messageId } });
};

const parseDate = (date) => {
  let time = calculateTime(date)

  return `${dates.parseDate(date)} (${time})`;
};

const calculateTime = (date) => {
  let messageDate = new Date(date);
  let now = Date.now();

  let seconds = parseInt((now - messageDate) / 1000);
  let minutes = parseInt((now - messageDate) / 60000);
  let hours = parseInt((now - messageDate) / 3600000);
  let days = parseInt((now - messageDate) / (24 * 3600 * 1000));

  if (days > 0) {
    return t("mailbox.time.daysAgo", { time: days });
  }

  if (hours > 0) {
    return t("mailbox.time.hoursAgo", { time: hours });
  }

  if (minutes > 0) {
    return t("mailbox.time.minutesAgo", { time: minutes });
  }

  if (seconds > 0) {
    return t("mailbox.time.secondsAgo", { time: seconds });
  }
}

watch(
  () => route.params.id,
  (newVal) => {
    if (newVal) {
      store.dispatch("setMessageIsRead", newVal);
    }
  }
);
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.mail-reader {
  background: white;
  padding: 25px;
  min-height: 250px;
}

.mail-reader-date,
.mail-reader-content {
  padding-top: 15px;
}

.mail-reader-content {
  border-top: 1px solid $borderLightGray;
}

.msg-label {
  font-size: 18px;
  height: 100%;
}

.back-link {
  padding-left: 24px;
}

.sender-row {
  height: 47px;
}

.topic-row {
  height: 62px;
}

.accent-blue {
  background: $accentLightBlue;
}
</style>
