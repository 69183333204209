const messages = {
    "title": "Domů",
    "welcomeMessage": "Vítejte v BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Domů",
    "search": "Hledat",
    "sortBy": "Seřadit podle",
    "seeMore": "Zobrazit více"
}
export default {
    messages
}
