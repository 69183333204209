const messages = {
  seeAll: "Weergeven alles",
  clear: "Verwijderen",
  back: "Terug",
  save: "Opslaan",
  close: "Sluiten",
  of: "Van",
  to: "tot",
  copyrights: "© {date} Bridgestone Solutions. Alle rechten voorbehouden.",
  pageNotFound: "Pagina niet gevonden",
  accessDenied: "Toegang geweigerd",
  search: "Zoeken",
  buttons: {
    export: "Exporteren",
    delete: "Verwijderen",
    save: "Opslaan",
    cancel: "Annuleren",
    next: "Doorgaan",
    back: "TERUG",
    skip: "Overslaan",
    send: "Versturen",
    clear: "Verwijderen",
  },
  tooltips: {
    info: "Hulp nodig? Contacteer service.consommateur@take-off.fr",
  },
  confirmation: {
    header: "Bevestiging vereist",
    message: "Wilt u dit record verwijderen?",
  },
  nav: {
    dashboard: "Startpagina",
    account: "Mijn profiel",
    mailbox: "Inbox",
    singleMailbox: "Bericht",
    stammdaten: "Referentiegegevens",
  },
  menu: {
    account: "Mijn profiel",
    plus: 'Over Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Verkoopondersteuning",
    mailbox: "Inbox",
    about: "Over BS Plus",
    trainingAndCourses: "Trainingen en cursussen",
    externalTraining: "Externe trainingen",
    productInfo: "Productinformatie",
    productBrochures: "Productbrochures",
    priceLists: "Prijslijsten",
    price: "Pricat",
    complaintForm: "Klachtenformulier",
    sales_support: "Verkoopondersteuning",
    contact_service: "Contact & Service",
    logOut: "Uitloggen",
    news: "Nieuws",
  },
};

export default {
  messages,
};
