const messages = {
    "titleColorRev": "true",
    "buttons": {
        "pending": "Pendente",
        "open": "Ouvrir",
        "requestAccess": "Demande d'accès "
    },
    "my": "Mes applications",
    "directRoute": "Votre accès direct à toutes les applications web Bridgestone",
    "welcomeMessage": "Bienvenue sur BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Afficher le profil",
    "newMessages": "Nuevo mensaje en la bandeja",
    "myApps": "Mes applications",
    "allApps": "Toutes les applications ",
    "myAppsDescription": "toutes les applications web Bridgestone pour lesquelles vous êtes déjà membre",
    "allAppsDescription": "toutes les applications web Bridgestone susceptibles de vous intéresser",
    "myProfile": {
        "welcomeMessage": "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou adapter vosdonnées de référence et consulter votre boîte de réception.",
        "masterData": "Données de référence",
        "completeDetails": "Complétez vos informations personnelles et celles de votre entreprise pour une inscription réussie.",
        "goToMasterData": "Accéder à vos données de référence",
        "goToInbox": "Aller à la boîte de réception "
    },
    "masterData": {
        "welcomeMessage": "Bienvenue dans votre espace personnel. Ici, vous pouvez mettre à jour ou adapter vos données de référence.",
        "personalDetails": "Données personnelles",
        "companyDetails": "Coordonnées de l'entreprise",
        "emailAddress": "Addresse e-mail",
        "title": "Titre de civilité",
        "surname": "Nom de famille",
        "firstName": "Prénom",
        "jobPosition": "Nom",
        "streetAndHouseNumber": "Numéro et nom de rue",
        "postalCode": "CODE POSTAL",
        "city": "Ville",
        "country": "Pays",
        "legalStructure": "Structure juridique",
        "customerNumber": "Número de cliente Bridgestone",
        "importantPleaseRead": "Importante, veuillez lire",
        "customerNumberInfo": "Afin d'attribuer correctement vos commandes de pneus aux grossistes et/ou à Bridgestone, il est essentiel que vous fournissiez vos numéros de client respectifs.",
        "multipleCustomerNumbers": "Si vous avez plusieurs numéros de client auprès d'un grossiste et/ou de Bridgestone, par exemple pour votre entreprise principale et vos succursales, veuillez d'abord indiquer le numéro de client de l'entreprise principale.",
        "salesAssignment": "Les ventes associées aux numéros de clients supplémentaires seront attribuées à votre entreprise principale.",
        "supplier": "Fournisseur",
        "addAnotherCustomerNumber": "Ajoutez un autre numéro de client Bridgestone",
        "save": "Sauvegarder",
        "delete": "Supprimer"
    },
    "inbox": {
        "welcomeMessage": "Vous y trouverez des notifications et des nouvelles importantes.",
        "sender": "Expéditeur",
        "subject": "Objet",
        "date": "Date",
        "formConsulting": "Vérification du formulaire - Veuillez traiter le formulaire",
        "back": "Retour"
    },
    "legalAndContact": {
        "cookieSettings": "Paramètres des cookies",
        "dataPolicy": "Politique des données",
        "termsOfUse": "Conditions d'utilisation",
        "helpAndContact": "Aide et contact"
    },
    "logInSignUp": {
        "logIn": "Se connecter",
        "signUp": "S'inscrire",
        "logInMessage": "Connectez-vous avec votre e-mail et votre mot de passe.",
        "signUpMessage": "Si vous êtes un nouvel utilisateur, veuillez vous inscrire."
    }
}

export default {
    messages
}