const messages = {
    "fleetWithOptions": "Et voi valita kalustoa, jos olet valinnut vaihtoehtoja.",
    "addProduct": "Tuotteesi on lisätty ostoskoriin.",
    "addProducts": "Tuotteesi on lisätty ostoskoriin.",
    "profileSaved": "Profiilitietosi on tallennettu.",
    "canAddProduct": "Sinulla ei ole tarpeeksi pisteitä.",
    "customerNumberSaved": "Asiakasnumero tallennettu.",
    "customerNumberExist": "Asiakasnumero saatavilla.",
    "complaintForm": "Pyyntösi on hyväksytty. Odota vastausta.",
    "openHourSaved": "Aukioloajat on tallennettu.",
    "changeParentId": "Et voi muokata toimipistettä, jolla on ylempi asiakasnumero, jos sillä on alaisia toimipisteitä.",
    "requestSent": "Pyyntö on lähetetty.",
    "completeProfileRequired": "Sinun on täytettävä profiilisi 100 %, jotta voit jatkaa ostoksia kaupassa.",
    "accessDenied": "Pääsy evätty.",
    "incomplete_address": "Osoite on puutteellinen. Tarkista se.",
    "waitMoment": "Odota hetki.",
    "downloadFinished": "Lataus valmis.",
    "selectOneField": "Valitse kenttä.",
    "noVoucher": "Kuponkia ei löytynyt.",
    "cannotAddCsNumber": "Et voi lisätä asiakasnumeroa."
}

export default {
    messages
}
