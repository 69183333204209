const messages = {
    "fleetWithOptions": "Non puoi scegliere la flotta se hai selezionato opzioni.",
    "addProduct": "Il tuo prodotto è stato aggiunto al carrello.",
    "addProducts": "I tuoi prodotti sono stati aggiunti al carrello.",
    "profileSaved": "I dati del tuo profilo sono stati salvati.",
    "canAddProduct": "Non hai abbastanza punti.",
    "customerNumberSaved": "Numero cliente salvato.",
    "customerNumberExist": "Numero cliente disponibile.",
    "complaintForm": "La tua richiesta è stata accettata. Attendi una risposta.",
    "openHourSaved": "Gli orari di apertura sono stati salvati.",
    "changeParentId": "Non puoi modificare la filiale con il numero cliente superiore se ha filiali subordinate.",
    "requestSent": "La richiesta è stata inviata.",
    "completeProfileRequired": "Devi completare il tuo profilo al 100% per continuare a fare acquisti nel negozio.",
    "accessDenied": "Accesso negato.",
    "incomplete_address": "L'indirizzo è incompleto. Controllalo per favore.",
    "waitMoment": "Aspetta un momento.",
    "downloadFinished": "Download completato.",
    "selectOneField": "Seleziona un campo.",
    "noVoucher": "Il voucher non è stato trovato.",
    "cannotAddCsNumber": "Non puoi aggiungere un numero cliente."
}

export default {
    messages
}
