const messages = {
  title: "Caixa de entrada ",
  desc: "Aqui pode encontrar notificações e notícias importantes.",
  newMessages: "Tem novas mensagens na sua caixa de entrada",
  link: "MOSTRAR MENSAGENS",
  showMessages: "MOSTRAR MENSAGENS",
  message: {
    sender: "Remetente",
    thread: "Assunto",
    date: "Data",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Voltar atrás",
  time: {
    secondsAgo: "Há {time} segundos",
    minutesAgo: "Há {time} minutos",
    hoursAgo: "Há {time} horas",
    daysAgo: "Há {time} dias",
  },
};

export default {
  messages,
};
