<template>
  <div>
    <MDBRow class="mb-3">
      <MDBCol>
        <div class="sub-title text-uppercase">
          <span class="text-danger">/ 1. PLUS:</span>
          &nbsp;<span class="text-dark-gray">{{
            $t("about.sections.first.title")
            }}</span>
        </div>
        <div class="logo-section mt-4">
          <img class="menu-logo" src="@/assets/img/logo.png" alt="logo" />
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol lg="6" class="pt-1"><img src="@/assets/img/about/card1.png" alt="logo" /></MDBCol>
      <MDBCol lg="6" class="pt-1"><img src="@/assets/img/about/card2.png" alt="logo" /></MDBCol>
      <MDBCol lg="6" class="pt-1"><img src="@/assets/img/about/card3.png" alt="logo" /></MDBCol>
      <MDBCol lg="6" class="pt-1"><img src="@/assets/img/about/card4.png" alt="logo" /></MDBCol>
    </MDBRow>

    <MDBRow class="py-3 px-2">
      <MDBCol>
        <h6 class="d-flex justify-content-center align-items-center my-2">
          <img src="@/assets/img/tyre.png" alt="logo" class="pr-2" />
          <div v-html="$t('about.fullPremium')" class="px-3"></div>
        </h6>
      </MDBCol>
    </MDBRow>

    <MDBRow class="mb-3">
      <MDBCol>
        <div class="logo-section mt-2">
          <img class="menu-logo" src="@/assets/img/fs_logo_r.png" alt="logo" />
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="mb-3">
      <MDBCol lg="3" md="6" class="pt-1"><img src="@/assets/img/about/firestone1.png" alt="logo" /></MDBCol>
      <MDBCol lg="3" md="6" class="pt-1"><img src="@/assets/img/about/firestone2.png" alt="logo" /></MDBCol>
      <MDBCol lg="3" md="6" class="pt-1"><img src="@/assets/img/about/firestone3.png" alt="logo" /></MDBCol>
      <MDBCol lg="3" md="6" class="pt-1"><img src="@/assets/img/about/firestone4.png" alt="logo" /></MDBCol>
    </MDBRow>

    <MDBRow class="mb-3">
      <MDBCol>
        <div class="d-flex justify-content-center align-items-center my-2">
          <MDBBtn type="button" color="danger" @click="goToProductInfo">{{
            $t("buttons.toProductInfo")
            }}</MDBBtn>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="bg-white py-3">
      <MDBCol col="12" class="mt-3">
        <div class="sub-title text-uppercase">
          <span class="text-danger">/ 2. PLUS:</span>
          &nbsp;<span class="text-dark-gray">{{
            $t("about.sections.second.title")
            }}</span>
        </div>
      </MDBCol>

      <MDBCol col="12"><img src="@/assets/img/rewards_ch.png" alt="logo" /></MDBCol>
      <MDBCol col="12" v-if="!loading && (isTyreDealer || isStaff)"><img src="@/assets/img/about/points_calc_ch.png"
          alt="logo" /></MDBCol>
      <MDBCol col="12" v-if="!loading && (isCarDealer || isStaff)"><img src="@/assets/img/about/points_calc_ch.png"
          alt="logo" /></MDBCol>
      <MDBCol col="12">
        <div class="d-flex justify-content-center align-items-center my-2 mb-4">
          <MDBBtn type="button" color="danger" @click="goToAccount">{{
            $t("buttons.toAccount")
            }}</MDBBtn>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="mb-3">
      <MDBCol col="12">
        <div class="sub-title text-uppercase">
          <span class="text-danger">/ 3. PLUS:</span>
          &nbsp;<span class="text-dark-gray">{{
            $t("about.sections.third.title")
            }}</span>
        </div>
        <p>{{ $t("about.sections.third.description") }}</p>
      </MDBCol>

      <MDBCol col="12">
        <div class="sub-title text-uppercase">
          <span class="text-danger">/</span>
          &nbsp;<span class="text-dark-gray">{{
            $t("about.sections.third.subtitle")
            }}</span>
        </div>
      </MDBCol>

      <MDBCol col="12" md="6" class="d-flex justify-content-center align-items-center">{{
        $t("about.sections.third.pointInfo") }}</MDBCol>
      <MDBCol col="12" md="6">
        <div class="parallelogram d-flex justify-content-center align-items-center">
          <span class="text-light px-3 fw-bold">{{
            $t("about.sections.third.numberInfo")
            }}</span>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="mb-3">
      <MDBCol>
        <div class="logo-section mt-4">
          <img class="menu-logo" src="@/assets/img/logo.png" alt="logo" />
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="mb-3">
      <MDBCol col="12" class="pt-1"><img src="@/assets/img/about/sales_promotions_ch.png" alt="logo" /></MDBCol>
    </MDBRow>

    <MDBRow class="mb-3">
      <MDBCol>
        <div class="logo-section mt-2">
          <img class="menu-logo" src="@/assets/img/fs_logo_r.png" alt="logo" />
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="mb-5">
      <MDBCol>
        <img class="menu-logo" src="@/assets/img/about/firestone_banner_ch.png" alt="logo" />
      </MDBCol>
    </MDBRow>
    <MDBRow class="mb-5">
      <MDBCol>
        <div class="d-flex justify-content-center align-items-center my-2">
          <MDBBtn type="button" color="danger" @click="goToSalesPromotions">{{
            $t("buttons.toSalesPromotions")
            }}</MDBBtn>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="mb-3 bg-white pt-3">
      <MDBCol col="12" class="pb-4">
        <div class="sub-title text-uppercase">
          <span class="text-danger text-uppercase">/ 4. PLUS:</span>
          &nbsp;<span class="text-dark-gray">{{
            $t("about.sections.fourth.title")
            }}</span>
        </div>
        <p v-html="$t('about.sections.fourth.description')"></p>
      </MDBCol>

      <MDBCol col="12" md="6">
        <h4 class="pb-3">
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3">{{
            $t("about.sections.fourth.list.program")
            }}</span>
        </h4>
        <h4 class="pb-3">
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3">{{ $t("about.sections.fourth.list.shop") }}</span>
        </h4>
        <h4 class="pb-3">
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3">{{
            $t("about.sections.fourth.list.priceList")
            }}</span>
        </h4>
        <h4 class="pb-3">
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3">{{
            $t("about.sections.fourth.list.productInfo")
            }}</span>
        </h4>
      </MDBCol>
      <MDBCol col="12" md="6">
        <h4 class="pb-3">
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3">{{
            $t("about.sections.fourth.list.salePromotion")
            }}</span>
        </h4>
        <h4 class="pb-3">
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3">{{
            $t("about.sections.fourth.list.onlineTrainings")
            }}</span>
        </h4>
        <h4 class="pb-3">
          {{ $t("about.sections.fourth.list.andMore") }}
        </h4>
      </MDBCol>
      <MDBCol col="12" class="pt-3"><img src="@/assets/img/img_trenner.png" alt="logo" /></MDBCol>

      <MDBCol col="12" class="py-5">
        <div class="sub-title text-uppercase">
          <span class="text-danger">/</span>
          &nbsp;<span class="text-dark-gray">{{
            $t("about.sections.fourth.title2")
            }}</span>
        </div>
        <p>{{ $t("about.sections.fourth.description2") }}</p>
      </MDBCol>
      <MDBCol col="12" md="4" class="d-flex flex-column">
        <div>{{ $t("about.sections.points.title") }}</div>
        <div>
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3 fw-bold">{{
            $t("about.sections.points.list.one")
            }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3 fw-bold">{{
            $t("about.sections.points.list.two")
            }}</span>
        </div>
        <div>
          <span><img src="@/assets/img/vector.png" alt="logo" /><img src="@/assets/img/vector.png" alt="logo" /></span>
          <span class="px-3 fw-bold">{{
            $t("about.sections.points.list.three")
            }}</span>
        </div>
        <div class="pt-3">
          <span class="fw-bold">{{
            $t("about.sections.fourth.list.andMore")
            }}</span>
        </div>
      </MDBCol>
      <MDBCol col="12" md="4" class="d-flex flex-column">
        <div class="fw-bold">
          {{ $t("about.sections.points.subtitle") }}
        </div>
        <div v-html="$t('about.sections.points.description')"></div>
      </MDBCol>
      <MDBCol col="12" md="4"><img src="@/assets/img/40points.png" alt="logo" /></MDBCol>
      <MDBCol col="12" class="d-flex justify-content-center align-items-center py-3">
        <img src="@/assets/img/online_trainings.png" alt="logo" />
      </MDBCol>

      <MDBCol class="my-3">
        <div class="d-flex justify-content-center align-items-center my-2">
          <MDBBtn type="button" color="danger" @click="goToOnlineTrainings">{{
            $t("buttons.toOnlineTrainings")
            }}</MDBBtn>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";

const router = useRouter();
const store = useStore();
const isDealer = ref(false);
const isCarDealer = ref(false);
const isTyreDealer = ref(false);

store.dispatch("me");

const goToProductInfo = () => {
  router.push({
    name: "productInfo",
  });
};

const goToAccount = () => {
  router.push({
    name: "account",
  });
};

const goToSalesPromotions = () => {
  router.push({
    name: "salesPromotions",
  });
};

const goToOnlineTrainings = () => {
  router.push({
    name: "onlineTrainings",
  });
};

const loading = computed(() => store.getters.getPending);

const user = computed(() => store.getters.getMe);
const profile = computed(() => store.getters.getProfile);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "dealer")) {
    isDealer.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isCarDealer.value = true;
    isTyreDealer.value = true;
  }
});

watch(profile, async (newVal) => {
  if (
    newVal &&
    profile.value.group &&
    profile.value.group.parent?.gka === "CarDealer"
  ) {
    isCarDealer.value = true;
  }
  if (
    newVal &&
    profile.value.group &&
    profile.value.group.parent?.gka === "TyreDealer"
  ) {
    isTyreDealer.value = true;
  }
});
</script>

<style scoped lang="scss">
@import "@/styles/variables";
@import "@/styles/about";
</style>
