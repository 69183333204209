const messages = {
  "title": "Bem-vindo ao BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Já possui uma conta?</b><br/>Inicie sessão com o seu endereço de correio eletrónico e a sua palavra-passe.<br/><br/><b>É novo aqui?</b><br/>Se é um novo utilizador, por favor registe-se.<br/><br/><b>",
  "info": "",
  "buttons": {
    "action": "Iniciar sessão / Registar-se",
    "back": "Retour à la connexion",
    "changeLanguage": "Changer de langue",
    "start": "Commencer"
  },
  "languageSelect": {
    "label": "Sélection de la langue",
    "description": "Veuillez choisir votre langue"
  }
}

export default {
  messages
}