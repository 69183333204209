const messages = {
  title: "Inbox",
  desc: "Welkom in je inbox. Hier vind u belangrijke meldingen en nieuws.",
  newMessages: "Nieuwe berichten in je inbox",
  link: "Berichten weergeven",
  showMessages: "Berichten weergeven",
  message: {
    sender: "Afzender",
    thread: "Onderwerp",
    date: "Datum",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Terug ",
  time: {
    secondsAgo: "{time} seconden geleden",
    minutesAgo: "{time} minuten geleden",
    hoursAgo: "{time} uur geleden",
    daysAgo: "{time} dagen geleden",
  },
};

export default {
  messages,
};
