const messages = {
    "titleColorRev": "verdadero",
    "buttons": {
        "pending": "Pendiente",
        "open": "Abrir",
        "requestAccess": "Solicitar acesso "
    },
    "my": "Mis aplicaciones",
    "directRoute": "Tus links directos a las aplicaciones web de Bridgestone.",
    "welcomeMessage": "Bienvenido a BRIDGESTONE PLUS <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "showProfile": "Ver perfil",
    "newMessages": "Nuevo mensaje en la bandeja",
    "myApps": "Mis aplicaciones",
    "allApps": "Todas las aplicaciones",
    "myAppsDescription": "todas las aplicaciones web de Bridgestone en las que estás registrado.",
    "allAppsDescription": "todas las aplicaciones web de Bridgestone que pueden ser de tu interés.",
    "myProfile": {
        "welcomeMessage": "Bienvenido a tu área personal. Aquí puedes actualizar o ajustar tus datos y ver tu bandeja de entrada.",
        "masterData": "Mis datos ",
        "completeDetails": "Completa tus datos personales y de empresa para asegurar un registro correcto.",
        "goToMasterData": "Ir a mis datos",
        "goToInbox": "Ir a la bandeja de entrada"
    },
    "masterData": {
        "welcomeMessage": "Te damos la bienvenida a tu área personal. Aquí puedes actualizar o modificar tus datos.",
        "personalDetails": "Datos personales",
        "companyDetails": "Datos de la empresa",
        "emailAddress": "Dirección de correo electrónico",
        "title": "Título",
        "surname": "Apellido",
        "firstName": "Nombre",
        "jobPosition": "Nombre",
        "streetAndHouseNumber": "Dirección de la empresa",
        "postalCode": "Código Postal",
        "city": "Ciudad",
        "country": "País",
        "legalStructure": "Estructura legal",
        "customerNumber": "Número de cliente Bridgestone",
        "importantPleaseRead": "Importante, por favor leer",
        "customerNumberInfo": "Para asignar correctamente tus pedidos de neumáticos a los distintos Distribuidores, es fundamental que proporciones tu número de cliente.",
        "multipleCustomerNumbers": "Si tienes varios números de cliente con un Distribuidor, por ejemplo, para tu negocio principal y tus sucursales, proporciona primero el número de cliente del negocio principal.",
        "salesAssignment": "Las ventas asociadas a los números de cliente adicionales se asignarán a tu negocio principal.",
        "supplier": "Proveedor",
        "addAnotherCustomerNumber": "Añadir número de cliente ",
        "save": "Guardar",
        "delete": "Borrar"
    },
    "inbox": {
        "welcomeMessage": "Aquí puedes encontrar notificaciones y noticias importantes.",
        "sender": "Remitente",
        "subject": "Asunto",
        "date": "Fecha",
        "formConsulting": "Formulario recibido - Gracias por completarlo",
        "back": "Atrás"
    },
    "legalAndContact": {
        "cookieSettings": "Configuración de cookies",
        "dataPolicy": "Política de datos",
        "termsOfUse": "Condiciones de uso",
        "helpAndContact": "Ayuda y contacto"
    },
    "logInSignUp": {
        "logIn": "Iniciar sesión",
        "signUp": "Registrarse",
        "logInMessage": "Iniciar sesión con tu dirección de correo electrónico y contraseña.",
        "signUpMessage": "Si eres un usuario nuevo, por favor regístrate."
    }
}

export default {
    messages
}