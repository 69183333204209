const messages = {
  "title": "Benvenuto in BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Hai già un account?</b><br/>Accedi con il tuo indirizzo email e password.<br/><br/><b>Sei nuovo qui?</b><br/>Se sei un nuovo utente, registrati.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Accedi / Registrati",
    "back": "Torna al login",
    "changeLanguage": "Cambia lingua",
    "start": "Inizia"
  },
  "languageSelect": {
    "label": "Selezione della lingua",
    "description": "Seleziona la tua lingua"
  }
}

export default {
  messages
}