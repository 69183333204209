const messages = {
    "fleetWithOptions": "Nu puteți selecta flota dacă ați selectat opțiuni.",
    "addProduct": "Produsul tău a fost adăugat în coș.",
    "addProducts": "Produsele tale au fost adăugate în coș.",
    "profileSaved": "Datele profilului au fost salvate.",
    "canAddProduct": "Nu ai suficiente puncte.",
    "customerNumberSaved": "Număr de client salvat.",
    "customerNumberExist": "Număr de client disponibil.",
    "complaintForm": "Solicitarea dvs. a fost acceptată. Vă rugăm să așteptați un răspuns.",
    "openHourSaved": "Orele de deschidere au fost salvate.",
    "changeParentId": "Nu puteți modifica sucursala cu numărul de client superior dacă are sucursale subordonate.",
    "requestSent": "Solicitarea a fost trimisă.",
    "completeProfileRequired": "Trebuie să completați profilul 100% pentru a continua cumpărăturile în magazin.",
    "accessDenied": "Acces refuzat.",
    "incomplete_address": "Adresa este incompletă. Vă rugăm să verificați.",
    "waitMoment": "Așteptați un moment.",
    "downloadFinished": "Descărcare finalizată.",
    "selectOneField": "Selectați un câmp.",
    "noVoucher": "Voucherul nu a fost găsit.",
    "cannotAddCsNumber": "Nu puteți adăuga un număr de client."
}

export default {
    messages
}
