const messages = {
  title: "Indbakke",
  desc: "Her kan du finde vigtige meddelelser og nyheder.",
  newMessages: "Nye beskeder i din indbakke",
  link: "Vis beskeder",
  showMessages: "Vis beskeder",
  message: {
    sender: "Afsender",
    thread: "Emne",
    date: "Dato",
  },
  sender: {
    system: "BRIDGESTONE PLUS",
  },
  back: "Tilbage",
  time: {
    secondsAgo: "for {time} sekunder siden",
    minutesAgo: "for {time} minutter siden",
    hoursAgo: "for {time} timer siden",
    daysAgo: "for {time} dage siden",
  },
};

export default {
  messages,
};
