<template>
  <MDBContainer>
    <Menu />
    <MDBRow>
      <MDBCol col="lg-3" :class="`column-order justify-content-start ${props.switchOrderMobile ? 'order-lg-1 order-2 mt-lg-0 mt-5' : null
        }`">
        <LeftColumnLV v-if="isLite" />
        <LeftColumn v-else />
        <MDBRow>
          <MDBCol class="my-3">
            <h5>&nbsp;</h5>
          </MDBCol>
        </MDBRow>
        <template v-if="!isWholesaler && !isWholesalerStaff">
          <PointBox />
          <KickbackBox />
        </template>
      </MDBCol>
      <MDBCol col="lg-9" :class="`${props.switchOrderMobile ? 'order-lg-2 order-1' : null}`">
        <props.content class="mb-3 mb-lg-4 welcome" />
        <props.data />
      </MDBCol>
    </MDBRow>
    <Footer />
  </MDBContainer>
</template>

<script setup>
import { MDBCol, MDBRow, MDBContainer } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import Menu from "@/components/Layout/Menu.vue";
import Footer from "@/components/Layout/Footer.vue";
import LeftColumn from "@/components/Layout/LeftColumn.vue";
import LeftColumnLV from "@/components/Layout/LeftColumnLV.vue";
import KickbackBox from "@/components/Dashboard/KickbackBox.vue";
import PointBox from "@/components/Dashboard/PointBox.vue";
import { defineProps, ref, watch, computed, onMounted } from "vue";

const store = useStore();

const props = defineProps({
  content: Object,
  switchOrderMobile: Boolean,
  data: Object,
});

const user = computed(() => store.getters.getMe);
const isLite = computed(() => store.getters.isLiteVersion).value;
const isWholesalerStaff = ref(false);
const isWholesaler = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
});

onMounted(async () => {
  if (
    user.value &&
    user.value.roles &&
    user.value.roles.find((item) => item.name == "dealer")
  ) {
    await store.dispatch("me");
  }
});
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
</style>
