import ApiService from "./api.service";

const AppService = {
  async list() {
    try {
      return await ApiService.get("dealer/apps");
    } catch (error) {
      console.log(error);
    }
  },
  async accessRequest(data) {
    try {
      return await ApiService.post("user/app/access", data);
    } catch (e) {
      console.log(e);
    }
  },
};

export { AppService };
