<template>
  <div id="delivery_data">
    <h4 class="mb-3 text-uppercase">{{ $t("pageName.orderDelivery") }}</h4>

    <label for="streetHouseNr" class="label px-3 text-uppercase">
      {{ $t("account.profile.streetHouseNr") }}
    </label>
    <input
      type="text"
      class="form-control"
      :placeholder="$t('account.profile.streetHouseNr')"
      :aria-label="$t('account.profile.streetHouseNr')"
      aria-describedby="basic-addon1"
      v-model="models.street"
      id="streetHouseNr"
    />

    <MDBRow>
      <MDBCol col="3">
        <label for="postcode" class="label px-3 text-uppercase">
          {{ $t("account.profile.postcode") }}
        </label>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('account.profile.postcode')"
          :aria-label="$t('account.profile.postcode')"
          aria-describedby="basic-addon1"
          v-model="models.postal_code"
          id="postcode"
        />
      </MDBCol>
      <MDBCol col="9">
        <label for="location" class="label px-3 text-uppercase">
          {{ $t("account.profile.location") }}
        </label>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('account.profile.location')"
          :aria-label="$t('account.profile.location')"
          aria-describedby="basic-addon1"
          v-model="models.city"
          id="location"
        />
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol col="12">
        <label for="country" class="label px-3 text-uppercase">
          {{ $t("account.profile.country") }}
        </label>
        <select
          class="form-select"
          :aria-label="$t('account.profile.country')"
          v-model="models.country"
          id="country"
        >
          <option disabled :value="null">{{ $t("account.profile.choose") }}</option>
          <option value="fe" v-if="isGermany || isSwitzerland">
            {{ $t("account.profile.french") }}
          </option>
          <option value="it" v-if="isGermany || isSwitzerland">
            {{ $t("account.profile.italian") }}
          </option>
          <option value="de" v-if="isGermany || isSwitzerland">
            {{ $t("account.profile.german") }}
          </option>
          <option value="nl" v-if="isDutch">
            {{ $t("account.profile.nederland") }}
          </option>
        </select>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { reactive, ref, watch, defineExpose, computed } from "vue";
import { useStore } from "vuex";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");
const isGermany = computed(() => LANG === "de");
const isSwitzerland = computed(() => LANG === "ch");

const store = useStore();

const indexToPercentage = ["street", "country", "city", "postal_code"];

const percentage = ref(0);

const models = reactive({
  id: null,
  street: null,
  postal_code: null,
  city: null,
  country: null,
  user_id: null,
  is_main: 0,
  is_residence: true,
  to_remove: false,
});

watch(
  () => {
    return indexToPercentage.map((item) => models[item]);
  },
  (newVal) => {
    percentage.value =
      (newVal.filter((i) => i).length / indexToPercentage.length) * 100;
  }
);

watch(
  () => store.getters.getMe,
  (newVal) => {
    models.user_id = newVal.id;
  }
);

watch(
  () => store.getters.getUserAddresses,
  (newVal) => {
    const additionalAddress = newVal.filter((item) => {
      return item.is_residence == 1 && item.is_main == 0;
    });

    if (additionalAddress.length !== 0) {
      models.id = additionalAddress[0].id;
      models.city = additionalAddress[0].city;
      models.postal_code = additionalAddress[0].postal_code;
      models.street = additionalAddress[0].street;
      models.country = additionalAddress[0].country;
    }
  }
);

defineExpose({
  models,
  percentage,
});
</script>
