const messages = {
  "title": "Välkommen till BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Har du redan ett konto?</b><br/>Logga in med din e-postadress och ditt lösenord.<br/><br/><b>Ny här?</b><br/>Om du är en ny användare, vänligen registrera dig.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Logga in / Registrera mig",
    "back": "Tillbaka till inloggning",
    "changeLanguage": "Byt språk",
    "start": "Starta"
  },
  "languageSelect": {
    "label": "Språkval",
    "description": "Vänligen välj ditt språk"
  }
}

export default {
  messages
}