const messages = {
  "title": "Willkommen bei BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Haben Sie bereits ein Konto?</b><br/>Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.<br/><br/><b>Bist du neu hier?</b><br/>Wenn Sie ein neuer Benutzer sind, registrieren Sie sich bitte.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Anmelden / Registrieren",
    "back": "Zurück zur Anmeldung",
    "changeLanguage": "Sprache ändern",
    "start": "Starten"
  },
  "languageSelect": {
    "label": "Sprachauswahl",
    "description": "Bitte wählen Sie eine Sprache "
  }
}

export default {
  messages
}