const messages = {
    fleetWithOptions:
        "Vous ne pouvez pas choisir la flotte si vous avez sélectionné des options",
    addProduct: "Votre produit a été ajouté au panier.",
    addProducts: "Vos produits ont été ajoutés au panier.",
    profileSaved: "Les données de votre profil ont été enregistrées",
    canAddProduct: "Tu n'as pas assez de points.",
    customerNumberSaved: "Numéro de client enregistré",
    customerNumberExist: "Numéro de client disponible",
    complaintForm:
        "Votre demande a été acceptée. Veuillez attendre une réponse",
    openHourSaved: "Les heures d'ouverture ont été enregistrées",
    changeParentId:
        "Vous ne pouvez pas modifier la branche avec le numéro de client supérieur si elle a des branches subordonnées.",
    requestSent: "La demande a été envoyée",
    completeProfileRequired:
        "Vous devez remplir votre profil à 100 % pour pouvoir poursuivre vos achats dans la boutique.",
    accessDenied: "Accès refusé",
    incomplete_address: "L'adresse est incomplète. Vérifiez s'il vous plaît",
    waitMoment: "Attendez un moment",
    downloadFinished: "Téléchargement terminé",
    selectOneField: "Sélectionnez un champ",
    noVoucher: "Le bon n'a pas été trouvé",
    cannotAddCsNumber: "Vous ne pouvez pas ajouter de numéro de client",
}

export default {
    messages
}
