const messages = {
  "title": "Bine ați venit la BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Ai deja un cont?</b><br/>Conectează-te cu adresa ta de e-mail și parola.<br/><br/><b>Ești nou aici?</b><br/>Dacă ești un utilizator nou, te rugăm să te înregistrezi.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Conectați-vă / Înscrieți-vă",
    "back": "Înapoi la conectare",
    "changeLanguage": "Schimbă limba",
    "start": "Start"
  },
  "languageSelect": {
    "label": "Selecția limbii",
    "description": "Te rugăm să selectezi limba ta"
  }
}

export default {
  messages
}