const messages = {
  "title": "Inbox",
  "desc": "Aici puteți găsi notificări și știri importante.",
  "newMessages": "Mesaje noi în căsuța poștală",
  "link": "Afișați mesajele",
  "showMessages": "Afișați mesajele",
  "message": {
    "sender": "Expeditor",
    "thread": "Subiect",
    "date": "Data"
  },
  "sender": {
    "system": "BRIDGESTONE PLUS"
  },
  "back": "Înapoi"
}

export default {
  messages,
};
