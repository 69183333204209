const messages = {
  seeAll: "Vezi tot",
  clear: "Șterge",
  back: "Înapoi",
  save: "Salvează",
  close: "Închide",
  of: "Din",
  to: "La",
  copyrights: "© {date} Bridgestone Solutions. Toate drepturile rezervate.",
  pageNotFound: "Pagină nu găsită",
  accessDenied: "Acces refuzat",
  search: "Căutare",
  buttons: {
    export: "Exportă",
    delete: "Șterge",
    save: "Salvează",
    cancel: "Anulează",
    next: "Următorul",
    back: "Înapoi",
    skip: "Sari",
    send: "Trimite",
  },
  tooltips: {
    info: "Ai nevoie de ajutor? 00 00 00 00",
  },
  confirmation: {
    header: "Confirmare necesară",
    message: "Dorești să ștergi această intrare?",
  },
  nav: {
    dashboard: "Panou de bord",
    account: "Profil",
    mailbox: "Mesaje primite",
    singleMailbox: "Mesaje",
    stammdaten: "Profil",
  },
  menu: {
    account: "Profil",
    plus: 'Despre Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Suport Vânzări",
    mailbox: "Mesaje primite",
    about: "Despre BS Plus",
    trainingAndCourses: "Training și Cursuri",
    externalTraining: "Training Extern",
    productInfo: "Informații despre Produse",
    productBrochures: "Broșuri Produse",
    priceLists: "Liste de Prețuri",
    price: "Preț",
    complaintForm: "Formular de Reclamație",
    sales_support: "Suport Vânzări",
    contact_service: "Informații și Serviciu Contact",
    logOut: "Deconectează-te",
    news: "Știri",
  },
};

export default {
  messages,
};
