const messages = {
  "termsAndConditions": "Vilkår og betingelser",
  "accept": "Acceptere ",
  "decline": "Nedgang",
  "dataModal": "For at kunne skræddersy dette tilbud til dine interesser vil vi bede dig om kort at angive dine produktinteresser eller forretningsområder.",
  "scrollToAccept": "Scroll ned for at acceptere vores betingelser for at fortsætte",
  "welcomeMessage": "Velkommen til Bridgestone Plus",
  "tailorOffer": "For at kunne skræddersy dette tilbud til dine interesser vil vi bede dig om kort at angive dine produktinteresser eller forretningsområder.",
  "selectAreas": "Vælg venligst fra følgende områder",
  "multipleAnswers": "(flere svar er mulige):",
  "personalPreferences": "Personlige præferencer",
  "carTyres": "Dæk til biler, SUV'er og nyttekøretøjer",
  "motorcyclesTyres": "Dæk til motorcykler",
  "truckBusTyres": "Dæk til lastbiler og busser",
  "agricultureTyres": "Dæk til landbrugskøretøjer",
  "otrTyres": "OTR-dæk",
  "save": "Gemme",
  "businessRelationship": "Hvad er dit forretningsforhold til Bridgestone?",
  "selectRelationship": "Vælg venligst*:",
  "tradingPartner": "Jeg er en handelspartner, der er...",
  "tradingPartnerDirect": "... direkte bestilling fra Bridgestone",
  "tradingPartnerIndirect": "... indirekte bestilling (via engroshandel f.eks.)",
  "tradingPartnerBoth": "... både direkte og indirekte bestilling ",
  "fleetResponsibleDirect": "Jeg er en flådeansvarlig, der er... ",
  "fleetResponsibleIndirect": "... indirekte bestilling (via handel eller engros) ",
  "fleetResponsibleBoth": "... direkte og indirekte bestilling ",
  "noBusinessRelationship": "<b>Jeg har ingen forretningsforbindelser med Bridgestone </b>(hverken direkte eller indirekte)",
  "yourCustomerNumber": "Dit Bridgestone-kundenummer*:",
  "enterCustomerNumber": "Indtast dit kundenummer",
  "waitPersonalisedView": "Vent venligst et øjeblik, din personlige visning af BRIDGESTONE PLUS vil blive genereret.",
  "personalised": "PERSONALISERET",
  "plusInfo": "Her finder du oplysninger om din Plus-konto og din Plus-indbakke.",
  "continue": "Fortsæt",
  "skip": "Spring over",
  "oneOfTwo": "1 af 2",
  "openApps": "Du kan åbne de apps, du allerede er registreret til, direkte. Du kan også anmode om adgang til andre apps, som kan være relevante for dig.",
  "requiredFields": "Udfyld venligst de obligatoriske felter: Titel, Fornavn, Efternavn, Fornavn",
  "stage": "trin",
  "chooseOne": "(Sæt venligst kryds i mindst 1 boks)",
  "required": "*obligatoriske felter",
  "lengthCsNumber": "Kundenummerets længde: 6-10 cifre"
}

export default {
  messages,
};
