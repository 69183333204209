const messages = {
  seeAll: "Öppna",
  clear: "Radera",
  back: "Tillbaka",
  save: "Spara",
  close: "Nära",
  of: "Av",
  to: "till",
  copyrights: "© {date} Bridgestone Solutions. Alla rättigheter förbehållna",
  pageNotFound: "Sidan hittades inte",
  accessDenied: "åtkomst nekad",
  search: "Sök",
  buttons: {
    export: "Export",
    delete: "Radera",
    save: "Spara",
    cancel: "Avboka",
    next: "Fortsätt",
    back: "Tillbaka",
    skip: "Hoppa över",
    send: "Skicka",
    clear: "Radera",
  },
  tooltips: {
    info: "Behöver du hjälp? 00 00 00 00",
  },
  confirmation: {
    header: "Bekräftelse krävs",
    message: "Do you want to delete this record?",
  },
  nav: {
    dashboard: "Hem",
    account: "Min profil",
    mailbox: "Inkorg",
    singleMailbox: "Meddelanden",
    stammdaten: "Min profil",
  },
  menu: {
    account: "Min profil",
    plus: 'Om Bridgestone <span class="text-danger">Plus</span>',
    salesSupport: "Soutien à la vente",
    mailbox: "Inkorg",
    about: "Om BS Plus",
    trainingAndCourses: "Formations et cours",
    externalTraining: "Formations externes",
    productInfo: "Informations sur les produits",
    productBrochures: "Brochures sur les produits",
    priceLists: "Listes de prix",
    price: "Pricat",
    complaintForm: "Formulaire de réclamation",
    sales_support: "Soutien à la vente",
    contact_service: "kontakt och service",
    logOut: "Logga ut",
    news: "Nyheter",
  },
};

export default {
  messages,
};
