const messages = {
  "title": "Bienvenido a BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>¿Ya tienes una cuenta?</b><br/>Inicia sesión con tu dirección de correo electrónico y tu contraseña.<br/><br/><b>¿Eres nuevo aquí?</b><br/>Si eres un nuevo usuario, por favor regístrate.<br/><br/>",
  "info": "",
  "buttons": {
    "action": "Iniciar sesión / Registrarme",
    "back": "Volver al inicio de sesión",
    "changeLanguage": "Cambiar de idioma",
    "start": "Iniciar"
  },
  "languageSelect": {
    "label": "Selección de idioma",
    "description": "Por favor, elige tu idioma "
  }
}

export default {
  messages
}