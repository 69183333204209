const messages = {
  title: "Maintenance",
  description: 'Currently, necessary maintenance work is being carried out on the servers.<br/><br/>' +
      'Therefore, you currently do not have access to <b>BRIDGESTONE <span class="text-danger">PLUS</span></b>.' +
      '<br/><br/>' +
      'We ask for your patience and thank you for your understanding.' +
      '<br/><br/>' +
      'Your Bridgestone Team'
}

export default {
    messages
}
