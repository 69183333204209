<template>
  <div v-if="show">
    <DebugInfo />

    <!-- Logo -->
    <img v-if="tenantConfig.showTopLogo" :src="tenantConfig.topLogo" alt="logo" class=""/>

    <!-- Background -->
    <div class="background-wrap">
      <div class="background" :class="tenantConfig.background"></div>
    </div>

    <!-- Router Content -->
    <div class="login-container px-2">
      <MDBRow>
        <MDBCol lg="12" xl="4" class="d-flex justify-content-center">
          <div class="d-flex flex-column justify-content-center align-items-center text-slider-wrapper">
            <MDBCard class="border-0 box-shadow card--height">
              <div v-if="tenantConfig.showLogo" class="w-100 d-flex justify-content-center">
                <img :src="tenantConfig.logo" alt="logo" />
              </div>
              <!-- Render Router View -->
              <router-view v-slot="{ Component }">
                <component
                    :is="Component"
                    :tenant-config="tenantConfig"
                    :loading="loading"
                />
              </router-view>
            </MDBCard>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  </div>
</template>

<script setup>
import {
  MDBCard,
  MDBCol,
  MDBRow,
} from 'mdb-vue-ui-kit'
import DebugInfo from '@/components/DebugInfo.vue'
import { ref, watchEffect } from "vue"
import { useStore } from "vuex"
import { tenantConfigs } from "@/configs/tenantConfig.js"
// Vuex Store
const store = useStore()

// Tenant Configuration
const tenantConfig = ref(tenantConfigs.DEFAULT)

// Loading state
const loading = ref(false)

// Show Content
const show = ref(false)

watchEffect(() => {
  // Dynamically update tenant configuration when tenantId changes
  tenantConfig.value = tenantConfigs.tenants[store.getters.tenantId] || tenantConfigs.tenants.DEFAULT
  // Show content after tenantConfig is resolved
  show.value = true
})
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
@import "@/styles/auth";
</style>
