const messages = {
    "title": "Home",
    "welcomeMessage": "Welkom bij BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Home",
    "search": "Zoeken",
    "sortBy": "Sorteren op",
    "seeMore": "Meer weergeven"
}

export default {
    messages
}
