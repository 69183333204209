import ApiService from "./api.service";

const MessageService = {
  async loadMessages() {
    try {
      return await ApiService.get("threadsUsers");
    } catch (e) {
      console.error(e);
    }
  },

  async loadUnreadMessages() {
    try {
      return await ApiService.get("unread/threadsUsers");
    } catch (e) {
      console.error(e);
    }
  },

  async setMessageIsRead(id) {
    try {
      return await ApiService.put(`threadsUsers/${id}`, {
        is_read: true,
      });
    } catch (e) {
      console.error(e);
    }
  },

  async deleteMessages(ids) {
    try {
      console.log("ids", ids);
      var params = {
        ids: ids,
      };
      console.log("ids", ids);
      return await ApiService.delete("threadsUsers", params);
    } catch (e) {
      console.error(e);
    }
  },
};

export { MessageService };
