const messages = {
    "title": "Domov",
    "welcomeMessage": "Vitajte v BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Domov",
    "search": "Hľadať",
    "sortBy": "Zoradiť podľa",
    "seeMore": "Zobraziť viac"
}

export default {
    messages
}
