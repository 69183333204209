const messages = {
    "title": "Inkorg",
    "desc": "Välkommen till inkorgen. Här hittar du viktiga meddelanden och nyheter.",
    "newMessages": "Nya meddelanden i din inkorg",
    "link": "Visa meddelandena",
    "showMessages": "Visa meddelandena",
    "message": {
        "sender": "Avsändare",
        "thread": "Ämne",
        "date": "Datum"
    },
    "sender": {
        "system": "BRIDGESTONE PLUS"
    },
    "back": "Tillbaka"
}

export default {
    messages
}