export default {
  URL: process.env.VUE_APP_API_URL,
  ADMIN: process.env.VUE_APP_ADMIN_URL,
};

export const TENANCY_SETTINGS = {
  TENANCY: process.env.VUE_APP_TENANCY === "true",
  TENANCY_COUNTRY: process.env.VUE_APP_TENANCY_COUNTRY,
  TENANCY_LOCAL: process.env.VUE_APP_TENANCY_LOCAL === "true"
}

// Define tenant groups and current tenant
export const TENANT_GROUPS = {}