const messages = {
  "seeAll": "Zobraziť všetko",
  "clear": "Vyčistiť",
  "back": "Späť",
  "save": "Uložiť",
  "close": "Zatvoriť",
  "of": "z",
  "to": "do",
  "copyrights": "© {date} Bridgestone Solutions. Všetky práva vyhradené.",
  "pageNotFound": "Stránka nebola nájdená",
  "accessDenied": "Prístup zamietnutý",
  "search": "Hľadať",
  "buttons": {
    "export": "Exportovať",
    "delete": "Vymazať",
    "save": "Uložiť",
    "cancel": "Zrušiť",
    "next": "Ďalej",
    "back": "Späť",
    "skip": "Preskočiť",
    "send": "Odoslať"
  },
  "tooltips": {
    "info": "Potrebujete pomoc? +421 905 306 674"
  },
  "confirmation": {
    "header": "Vyžaduje sa potvrdenie",
    "message": "Chcete túto položku vymazať?"
  },
  "nav": {
    "dashboard": "Domov",
    "account": "Profil",
    "mailbox": "Schránka",
    "singleMailbox": "Správy"
  },
  "menu": {
    "account": "Profil",
    "plus": "O Bridgestone <span class=\"text-danger\">Plus</span>",
    "salesSupport": "Podpora predaja",
    "mailbox": "Schránka",
    "about": "O BS Plus",
    "trainingAndCourses": "Školenia a kurzy",
    "externalTraining": "Externé školenie",
    "productInfo": "Informácie o produktoch",
    "productBrochures": "Produktové brožúry",
    "priceLists": "Cenníky",
    "price": "Cena",
    "complaintForm": "Formulár sťažnosti",
    "sales_support": "Podpora predaja",
    "contact_service": "Kontaktné údaje a servis",
    "logOut": "Odhlásiť sa",
    "news": "Novinky"
  }
}

export default {
  messages,
};
