<template>
  <VeeForm class="form bg-white px-5 py-4" @submit="onSubmit" v-slot="{ errors }">

    <MDBRow>
      <MDBCol>
        <h3 class="mb-4 text-uppercase">
          {{ $t("complaintForm.sections.damageType.title") }}
        </h3>
        <p>{{ $t("contactPage.damage") }}</p>
        <div class="btn-group mb-3" role="group" aria-label="Basic radio toggle button group">
          <Field type="radio" class="btn-check" name="sachschaden" id="sachschaden1" autocomplete="off"
            @click="setDamage(true)" :value="true" :checked="form.isDamage" />
          <label class="btn btn-outline-danger" for="sachschaden1">{{
            $t("yesText")
          }}</label>
          <ErrorMessage name="sachschaden1" class="text-danger text-small text-end m-0 pt-1" />

          <input type="radio" class="btn-check" name="sachschaden" id="sachschaden2" autocomplete="off"
            @click="setDamage(false)" :value="false" :checked="!form.isDamage" />
          <label class="btn btn-outline-danger" for="sachschaden2">{{
            $t("noText")
          }}</label>
        </div>

        <p>{{ $t("contactPage.personalInjury") }}</p>
        <div class="btn-group mb-3" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" name="personenschaden" id="personenschaden1" autocomplete="off"
            @click="setPersonalInjury(true)" :checked="form.isPersonalInjury" />
          <label class="btn btn-outline-danger" for="personenschaden1">{{
            $t("yesText")
          }}</label>

          <input type="radio" class="btn-check" name="personenschaden" id="personenschaden2" autocomplete="off"
            @click="setPersonalInjury(false)" :checked="!form.isPersonalInjury" />
          <label class="btn btn-outline-danger" for="personenschaden2">{{
            $t("noText")
          }}</label>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3 text-uppercase">
          {{ $t("complaintForm.sections.generally.title") }}
        </h3>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input type="text" class="form-control" :placeholder="`${$t('complaintForm.fields.ordinance')}`"
            :aria-label="$t('complaintForm.fields.ordinance')" aria-describedby="basic-addon1"
            v-model="form.generally.ordinance" />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <Field type="text" class="form-control" :placeholder="`${$t('yourEmail')}*`"
            :aria-label="`${$t('yourEmail')}*`" aria-describedby="basic-addon1" v-model="form.generally.email"
            name="generalEmail" :rules="{ required: true }" :label="$t('yourEmail')" id="generalEmail" />
          <div class="w-100">
            <ErrorMessage name="generalEmail" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <Field name="country" as="select" class="form-select mb-3 w-100" aria-label=".form-select-lg example"
            v-model="form.generally.country" :rules="{ required: true }" :label="$t('account.profile.country')" id="country">
            <option selected value="">{{ $t("account.profile.country") }}*</option>
            <option value="de">{{ $t("account.profile.de") }}</option>
            <option value="at">{{ $t("account.profile.at") }}</option>
            <option value="ch">{{ $t("account.profile.ch") }}</option>
          </Field>
          <div class="w-100">
            <ErrorMessage name="country" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <Field name="pickupLocation" as="select" class="form-select mb-3 w-100" aria-label=".form-select-lg example"
            v-model="form.generally.pickup_location_id" :rules="{ required: true }"
            :label="$t('complaintForm.fields.pickupLocation')" id="pickupLocation">
            <option selected value="">
              {{ $t("complaintForm.fields.pickupLocation") }}*
            </option>
            <option :value="pickup.id" v-for="pickup in pickupLocations" :key="pickup.id">
              {{ pickup.name }}
            </option>
          </Field>
          <div class="w-100">
            <ErrorMessage name="pickupLocation" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3 text-uppercase">
          {{ $t("complaintForm.sections.regulator.title") }}
        </h3>
      </MDBCol>
    </MDBRow>

    <Address title="" ref="addressTireDealer" :required="true" :filedId="1" />

    <Address :title="$t('contact.form.intermediaryAddress')" ref="addressOfIntermediary" :required="false"
      :filedId="2" />

    <Address :title="$t('addressEndCustomer')" ref="addressEndCustomerData" :required="false" :filedId="3" />

    <MDBRow>
      <MDBCol>
        <h3 class="my-3 text-uppercase">
          {{ $t("contact.form.vehicleDetails") }}
        </h3>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <Field name="vehicle_tyre_id" as="select" class="form-select mb-3 w-100" v-model="form.vehicle_tyre_id"
            :rules="{ required: true }" :label="$t('contact.form.vehicleTyres')" id="vehicle_tyre_id">
            <option value="" selected="selected">
              {{ $t("contact.form.vehicleTyres") }}
            </option>
            <option :value="vehicleTyre.id" v-for="vehicleTyre in vehicleTyres" :key="vehicleTyre.id">
              {{ vehicleTyre.name }}
            </option>
          </Field>
          <div class="w-100">
            <ErrorMessage name="vehicle_tyre_id" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input type="text" class="form-control" :placeholder="$t('contact.form.vehicleManufacturer')"
            :aria-label="$t('contact.form.vehicleManufacturer')" aria-describedby="basic-addon1"
            v-model="form.vehicleManufacturer" />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input type="text" class="form-control" :placeholder="$t('contact.form.vehicleModelType')"
            :aria-label="$t('contact.form.vehicleModelType')" aria-describedby="basic-addon1"
            v-model="form.vehicleModelType" />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <VueDatePicker :locale="LANG" :enable-time-picker="false" :cancelText="$t('buttons.cancel')"
            :selectText="$t('buttons.select')" :placeholder="$t('contact.form.firstRegistration')"
            :aria-label="$t('contact.form.firstRegistration')" aria-describedby="basic-addon1"
            v-model="form.firstRegistration" :format="utils.formatDatePicker" />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input type="number" class="form-control" :placeholder="$t('contact.form.enginePower')"
            :aria-label="$t('contact.form.enginePower')" aria-describedby="basic-addon1" v-model="form.enginePower" />
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="d-flex w-100">
          <select class="form-select mb-3" aria-label=".form-select-lg example" v-model="form.originalEquipment">
            <option selected :value="null">
              {{ $t("contact.form.originalEquipment") }}
            </option>
            <option :value="true">{{ $t("yesText") }}</option>
            <option :value="false">{{ $t("noText") }}</option>
          </select>
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="d-flex w-100">
          <select class="form-select mb-3" aria-label=".form-select-lg example" v-model="form.tyre_wear_id">
            <option selected :value="null">
              {{ $t("contact.form.tireMilageHours") }}
            </option>
            <option :value="tyreWear.id" v-for="tyreWear in tyreWears" :key="tyreWear.id">
              {{ tyreWear.name }}
            </option>
          </select>
        </div>
      </MDBCol>
      <MDBCol md="6">
        <div class="input-group mb-3">
          <input type="number" class="form-control" :placeholder="$t('contact.form.milageOrHours')"
            :aria-label="$t('contact.form.milageOrHours')" aria-describedby="basic-addon1"
            v-model="form.milageOrHour" />
        </div>
      </MDBCol>
    </MDBRow>
    <ReifenFormItem :id="1" :values="reifen1Values" :required="true" />
    <ReifenFormItem :id="2" :values="reifen2Values" />
    <div class="text-end">
      <button type="button" class="btn btn-dark" @click="() => (reifen2Values = copyReifen(reifen1Values))">
        {{ $t("contact.form.copyTire1") }}
      </button>
    </div>
    <ReifenFormItem :id="3" :values="reifen3Values" />
    <div class="text-end">
      <button type="button" class="btn btn-dark" @click="() => (reifen3Values = copyReifen(reifen1Values))">
        {{ $t("contact.form.copyTire1") }}
      </button>
    </div>
    <ReifenFormItem :id="4" :values="reifen4Values" />
    <div class="text-end">
      <button type="button" class="btn btn-dark" @click="() => (reifen4Values = copyReifen(reifen1Values))">
        {{ $t("contact.form.copyTire1") }}
      </button>
      <button type="button" class="btn btn-dark" @click="() => (reifen4Values = copyReifen(reifen3Values))">
        {{ $t("contact.form.copyTire3") }}
      </button>
    </div>

    <MDBRow>
      <MDBCol>
        <h3 class="mt-3 text-uppercase">
          {{ $t("contact.form.damageDetails") }}
        </h3>
        <p class="mb-3">{{ $t("contact.form.complaintReason") }}</p>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <div class="d-flex flex-wrap">
          <div class="form-check mx-1" v-for="(damageType, index) in damageTypes" :key="index">
            <Field v-slot="{ field }" name="terms" type="checkbox" :value="damageType.id" v-model="form.damage_type_id"
              :rules="{ required: true }" :label="$t('contact.form.complaintReason')">
              <input type="checkbox" :id="'claim_schaden_beanstandungscodes_' + index" class="form-check-input active"
                :value="damageType.id" name="terms" v-bind="field" />
              <label class="form-check-label" :for="'claim_schaden_beanstandungscodes_' + index">{{ damageType.id }}. {{
                damageType.name }}
              </label>
            </Field>
          </div>
          <div class="w-100">
            <ErrorMessage name="terms" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
      </MDBCol>
      <MDBCol col="12">
        <div class="input-group my-3">
          <Field type="text" class="form-control" :placeholder="$t('contact.form.complaintReasonText')"
            :aria-label="$t('contact.form.complaintReasonText')" aria-describedby="basic-addon1"
            v-model="form.complaintReasonText" :rules="{ max: 160 }" :label="$t('contact.form.complaintReasonText')"
            name="complaintReasonText" />
          <div class="w-100">
            <ErrorMessage name="complaintReasonText" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
      </MDBCol>

      <MDBCol col="12">
        <p>{{ $t("contact.form.isTyreReplaced") }}</p>
        <div class="btn-group mb-3" role="group" :aria-label="$t('contact.form.isTyreReplaced')" id="tyre-change">
          <Field type="radio" class="btn-check" name="tyre-change" id="tyre-change1" autocomplete="off" :value="true"
            v-model="form.isTyreReplaced" :rules="{ required: form.isTyreReplaced == null }"
            :label="$t('contact.form.isTyreReplaced')" />
          <label class="btn btn-outline-danger" for="tyre-change1">{{
            $t("yesText")
          }}</label>

          <Field type="radio" class="btn-check" name="tyre-change" id="tyre-change2" autocomplete="off" :value="false"
            v-model="form.isTyreReplaced" />
          <label class="btn btn-outline-danger" for="tyre-change2">{{
            $t("noText")
          }}</label>
        </div>
        <div class="w-100">
          <ErrorMessage name="tyre-change" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </MDBCol>

      <MDBCol col="12">
        <div class="input-group my-3">
          <input type="number" class="form-control" :placeholder="$t('contact.form.ifYesTyre')"
            :aria-label="$t('contact.form.ifYesTyre')" aria-describedby="basic-addon1" v-model="form.brandSpareTyre" />
        </div>
      </MDBCol>

      <MDBCol col="12">
        <div class="input-group mt-3">
          <Field type="text" class="form-control" :placeholder="$t('contact.form.clerkName')"
            :aria-label="$t('contact.form.clerkName')" aria-describedby="basic-addon1" name="clerkName"
            :rules="{ required: true }" :label="$t('contact.form.clerkName')" min="1" v-model="form.clerkName"
            id="clerkName" />
          <div class="w-100">
            <ErrorMessage name="clerkName" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol>
        <h3 class="my-3 text-uppercase">{{ $t("contact.form.conclusion") }}</h3>
        <p v-html="$t('contact.form.conclusionContent')"></p>
        <div>
          <Field name="regulation1" class="form-check-input" type="checkbox" :rules="{ required: true }" :value="true"
            :unchecked-value="false" id="regulation1" v-model="form.regulation1"
            :label="$t('contact.form.agreementReadAccept')" />
          <span class="px-2">{{ $t("contact.form.agreementReadAccept") }}</span>
          <div class="w-100">
            <ErrorMessage name="regulation1" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
        <a href="http://terms-de.bridgestoneemea.com/" target="_blank" class="d-block my-2">{{
          $t("contact.form.termsConditionsRead") }}
        </a>
        <div>
          <Field name="regulation2" type="checkbox" v-model="form.regulation2" :rules="{ required: true }"
            :label="$t('contact.form.termsConditionsReadAccept')" :value="true" :unchecked-value="false"
            class="form-check-input" id="regulation2">
          </Field>
          <span class="px-2">{{
            $t("contact.form.termsConditionsReadAccept")
          }}</span>
          <div class="w-100">
            <ErrorMessage name="regulation2" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>

        <MDBBtn type="submit" color="danger" :disabled="loading"
          class="d-flex justify-content-center align-items-center mt-5 mb-1"><span>{{ $t("global.buttons.send") }}</span>
          <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
        </MDBBtn>
        <p v-if="apiError" class="text-danger text-small text-end m-0 pt-2 text-small">
          {{ apiError }}
        </p>
        <div v-if="errors">
          <p v-for="(error, index) in errors" :key="index"
            class="text-danger text-small text-end m-0 pt-2 text-small cursor--pointer" @click="scrollTo(index)">
            {{ error }}</p>
        </div>
        <p>{{ $t("contact.form.requiredField") }}</p>
      </MDBCol>
    </MDBRow>
  </VeeForm>
  <MDBModal v-model="modal" tabindex="-1" centered size="xl" @hide="hideModal">
    <InjureInfo />
  </MDBModal>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import ReifenFormItem from "@/components/Contact/Form/ReifenFormItem.vue";
import Address from "@/components/ProductInfo/Content/Address.vue";
import InjureInfo from "@/components/ProductInfo/Content/InjureInfo.vue";
import { MDBRow, MDBCol, MDBBtn, MDBModal } from "mdb-vue-ui-kit";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import VueDatePicker from "@vuepic/vue-datepicker";
import { ref, onMounted, computed } from "vue";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import utils from "@/plugins/utils";
import { ContactService } from "@/services/contact.service";

const LANG = computed(() => localStorage.getItem("lang"));

const store = useStore();
const apiError = ref(null);

const loading = computed(() => store.getters.getPending);
const pickupLocations = computed(() => store.getters.getPickupLocations);
const user = computed(() => store.getters.getMe);
const damageTypes = computed(() => store.getters.getDamageTypes);
const vehicleTyres = computed(() => store.getters.getVehicleTyres);
const tyreWears = computed(() => store.getters.getTyreWears);
const tyrePositions = computed(() => store.getters.getTyrePositionChildren);
const salutations = computed(() => store.getters.getSalutations);
const modal = ref(false);
const addressTireDealer = ref(null);
const addressOfIntermediary = ref(null);
const addressEndCustomerData = ref(null);
const pageHeight = ref(0);

const setDamage = (value) => {
  if (value) modal.value = value;
  form.value.isDamage = value;
};

const setPersonalInjury = (value) => {
  if (value) modal.value = value;
  form.value.isPersonalInjury = value;
};

const hideModal = () => {
  form.value.isDamage = null;
  form.value.isPersonalInjury = null;
};

const form = ref({
  isDamage: null,
  isPersonalInjury: null,
  generally: {
    ordinance: "",
    email: "",
    pickup_location_id: null,
    country: null,
  },
  vehicle_tyre_id: null,
  damage_type_id: null,
  vehicleManufacturer: null,
  vehicleModelType: null,
  firstRegistration: null,
  clerkName: null,
  regulation1: null,
  regulation2: null,
  isTyreReplaced: null,
  brandSpareTyre: null,
  originalEquipment: null,
  complaintReasonText: null,
  tyre_wear_id: null,
  milageOrHour: null,
  enginePower: null,
});

const reifen1Values = ref({
  tyre_position_id: null,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serial_number: "",
});

const reifen2Values = ref({
  tyre_position_id: null,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serial_number: "",
});

const reifen3Values = ref({
  tyre_position_id: null,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serial_number: "",
});

const reifen4Values = ref({
  tyre_position_id: null,
  size: "",
  index: "",
  profile: "",
  dot: "",
  serial_number: "",
});

const copyReifen = (reifen) => {
  return {
    name: reifen.name,
    size: reifen.size,
    index: reifen.index,
    profile: reifen.profile,
    dot: reifen.dot,
    serial_number: reifen.serial_number,
  };
};

const onSubmit = async (values, { resetForm }) => {
  const data = {
    complaint: {
      pickup_location_id: form.value.generally.pickup_location_id,
      user_id: user.value.plainId,
      ordinance: form.value.generally.ordinance,
      generalEmail: form.value.generally.email,
      country: form.value.generally.country,
      enginePower: form.value.enginePower,
      brandSpareTyre: form.value.brandSpareTyre,
      complaintReasonText: form.value.complaintReasonText,
      firstRegistration: form.value.firstRegistration,
      isTyreReplaced: form.value.isTyreReplaced,
      milageOrHour: form.value.milageOrHour,
      vehicle_tyre_id: form.value.vehicle_tyre_id,
      vehicleModelType: form.value.vehicleModelType,
      vehicleManufacturer: form.value.vehicleManufacturer,
      clerkName: form.value.clerkName,
      originalEquipment: form.value.originalEquipment ?? false,
      regulation1: form.value.regulation1,
      regulation2: form.value.regulation2,
      tyre_wear_id: form.value.tyre_wear_id,
    },
    complaintReasons: form.value.damage_type_id,
    tires: [
      reifen1Values.value,
      reifen2Values.value,
      reifen3Values.value,
      reifen4Values.value,
    ],
    addressTireDealer: addressTireDealer.value.data,
    addressOfIntermediary: addressOfIntermediary.value.data,
    addressEndCustomerData: addressEndCustomerData.value.data,
  };

  const response = await store.dispatch("storeCompliant", data);

  if (response?.ok) {
    await createFormPdf(
      data,
      response.complaint_id,
      response.hashid,
      form.value
    );

    toast(i18n.global.t("toastMessages.complaintForm"), {
      type: "success",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    resetForm();
  } else {
    apiError.value = response.data.message;
  }
};

const createFormPdf = async (data, complaintId, hashid, formValue) => {
  const url = `${window.location.origin}/files/clamform.pdf`;

  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

  const pages = pdfDoc.getPages();
  const pdfForm = pdfDoc.getForm();
  const firstPage = pages[0];
  const secondPage = pages[1];
  const { height } = firstPage.getSize();
  const forbidenNames = ref([
    "brandSpareTyre",
    "regulation2",
    "clerkName",
    "complaintReasonText",
  ]);

  pageHeight.value = height / 2 + utils.pdfFirstPageContent.complaint_id.y;
  utils.createField(
    pdfForm,
    utils.pdfFirstPageContent.complaint_id.fieldName,
    complaintId,
    firstPage,
    utils.pdfFirstPageContent.complaint_id.x,
    pageHeight.value,
    utils.pdfFirstPageContent.complaint_id.width,
    utils.pdfFirstPageContent.complaint_id.height
  );

  //first page titles
  for (let i = 0; i < utils.pdfFieldTitlesPage1.length; i++) {
    pageHeight.value = height / 2 + utils.pdfFieldTitlesPage1[i].y;

    firstPage.drawText(utils.pdfFieldTitlesPage1[i].text, {
      x: utils.pdfFieldTitlesPage1[i].x,
      y: pageHeight.value,
      size: utils.pdfFieldTitlesPage1[i].size,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  }
  //first page content
  for (let i = 0; i < utils.pdfFieldsPage1.length; i++) {
    pageHeight.value = height / 2 + utils.pdfFieldsPage1[i].y;
    utils.createField(
      pdfForm,
      utils.pdfFieldsPage1[i].fieldName,
      utils.pdfFieldsPage1[i].text,
      firstPage,
      utils.pdfFieldsPage1[i].x,
      pageHeight.value,
      utils.pdfFieldsPage1[i].width,
      utils.pdfFieldsPage1[i].height
    );
  }
  //second page titles
  for (let i = 0; i < utils.pdfFieldTitlesPage2.length; i++) {
    pageHeight.value = height / 2 + utils.pdfFieldTitlesPage2[i].y;

    secondPage.drawText(utils.pdfFieldTitlesPage2[i].text, {
      x: utils.pdfFieldTitlesPage2[i].x,
      y: pageHeight.value,
      size: utils.pdfFieldTitlesPage2[i].size,
      font: helveticaFont,
      color: rgb(0, 0, 0),
    });
  }

  //second page content
  for (let i = 0; i < utils.pdfFieldsPage2.length; i++) {
    pageHeight.value = height / 2 + utils.pdfFieldsPage2[i].y;
    utils.createField(
      pdfForm,
      utils.pdfFieldsPage2[i].fieldName,
      utils.pdfFieldsPage2[i].text,
      secondPage,
      utils.pdfFieldsPage2[i].x,
      pageHeight.value,
      utils.pdfFieldsPage2[i].width,
      utils.pdfFieldsPage2[i].height
    );
  }

  // store data.complaint in PDF
  for (const property in data.complaint) {
    const object = !forbidenNames.value.includes(property)
      ? utils.pdfFirstPageContent[property]
      : utils.pdfSecondPageContent[property];
    let page = !forbidenNames.value.includes(property) ? firstPage : secondPage;

    if (object) {
      let value = data["complaint"][property];
      if (property.includes("_id")) {
        if (property == "vehicle_tyre_id" && data["complaint"][property]) {
          value = vehicleTyres.value.find(
            (x) => x.id == data["complaint"][property]
          ).name;
        }

        if (property == "pickup_location_id" && data["complaint"][property]) {
          value = pickupLocations.value.find(
            (x) => x.id == data["complaint"][property]
          ).name;
        }

        if (property == "tyre_wear_id" && data["complaint"][property]) {
          value = tyreWears.value.find(
            (x) => x.id == data["complaint"][property]
          ).name;
        }
      }
      if (property == "country" && data["complaint"][property]) {
        value = i18n.global.t(`profile.${data["complaint"][property]}`);
      }
      if (property == "firstRegistration" && data["complaint"][property]) {
        value = utils.formatDatePicker(data["complaint"][property]);
      }
      if (property == "originalEquipment") {
        value = utils.setYesNoText(data["complaint"][property]);
      }

      if (property == "regulation2") {
        value = i18n.global.t("yesText");
      }

      if (value && typeof value === "number") {
        object.text = value.toString();
      } else {
        object.text = value ?? "";
      }

      pageHeight.value = height / 2 + object.y;
      utils.createField(
        pdfForm,
        object.fieldName,
        object.text,
        page,
        object.x,
        pageHeight.value,
        object.width,
        object.height
      );
    }
  }

  for (const property in reifen1Values.value) {
    utils.pdfSecondPageContent.tyre1[property];
    let value;
    if (property === "tyre_position_id") {
      const tyreName = tyrePositions.value.find(
        (x) => x.id == reifen1Values.value.tyre_position_id
      );

      value = tyreName ? tyreName.name["de"] : "";
    } else {
      value = reifen1Values.value[property];
    }

    pageHeight.value =
      height / 2 + utils.pdfSecondPageContent.tyre1[property].y;
    utils.createField(
      pdfForm,
      utils.pdfSecondPageContent.tyre1[property].fieldName,
      value,
      secondPage,
      utils.pdfSecondPageContent.tyre1[property].x,
      pageHeight.value,
      utils.pdfSecondPageContent.tyre1[property].width,
      utils.pdfSecondPageContent.tyre1[property].height
    );
  }

  for (const property in reifen2Values.value) {
    utils.pdfSecondPageContent.tyre2[property];
    let value;
    if (property === "tyre_position_id") {
      const tyreName = tyrePositions.value.find(
        (x) => x.id == reifen2Values.value.tyre_position_id
      );

      value = tyreName ? tyreName.name["de"] : "";
    } else {
      value = reifen2Values.value[property];
    }

    pageHeight.value =
      height / 2 + utils.pdfSecondPageContent.tyre2[property].y;
    utils.createField(
      pdfForm,
      utils.pdfSecondPageContent.tyre2[property].fieldName,
      value,
      secondPage,
      utils.pdfSecondPageContent.tyre2[property].x,
      pageHeight.value,
      utils.pdfSecondPageContent.tyre2[property].width,
      utils.pdfSecondPageContent.tyre2[property].height
    );
  }

  for (const property in reifen3Values.value) {
    utils.pdfSecondPageContent.tyre3[property];
    let value;
    if (property === "tyre_position_id") {
      const tyreName = tyrePositions.value.find(
        (x) => x.id == reifen3Values.value.tyre_position_id
      );

      value = tyreName ? tyreName.name["de"] : "";
    } else {
      value = reifen3Values.value[property];
    }

    pageHeight.value =
      height / 2 + utils.pdfSecondPageContent.tyre3[property].y;
    utils.createField(
      pdfForm,
      utils.pdfSecondPageContent.tyre3[property].fieldName,
      value,
      secondPage,
      utils.pdfSecondPageContent.tyre3[property].x,
      pageHeight.value,
      utils.pdfSecondPageContent.tyre3[property].width,
      utils.pdfSecondPageContent.tyre3[property].height
    );
  }

  for (const property in reifen4Values.value) {
    utils.pdfSecondPageContent.tyre4[property];
    let value;
    if (property === "tyre_position_id") {
      const tyreName = tyrePositions.value.find(
        (x) => x.id == reifen4Values.value.tyre_position_id
      );

      value = tyreName ? tyreName.name["de"] : "";
    } else {
      value = reifen4Values.value[property];
    }

    pageHeight.value =
      height / 2 + utils.pdfSecondPageContent.tyre4[property].y;
    utils.createField(
      pdfForm,
      utils.pdfSecondPageContent.tyre4[property].fieldName,
      value,
      secondPage,
      utils.pdfSecondPageContent.tyre4[property].x,
      pageHeight.value,
      utils.pdfSecondPageContent.tyre4[property].width,
      utils.pdfSecondPageContent.tyre4[property].height
    );
  }

  for (const property in data.addressTireDealer) {
    utils.pdfFirstPageContent.addressTyreDealer[property];

    let value;
    if (property === "salutation_id") {
      const salutation = salutations.value.find(
        (x) => x.id == data.addressTireDealer[property]
      );

      value = salutation ? salutation.name : "";
    } else if (property === "postal_code") {
      value = `${data.addressTireDealer["postal_code"] ?? ""} ${data.addressTireDealer["city"] ?? ""
        }`;
    } else if (property === "city") {
      continue;
    } else {
      value = data.addressTireDealer[property] ?? "";
    }

    if (value && typeof value === "number") {
      value = value.toString();
    } else {
      value = value ?? "";
    }

    pageHeight.value =
      height / 2 + utils.pdfFirstPageContent.addressTyreDealer[property].y;
    utils.createField(
      pdfForm,
      utils.pdfFirstPageContent.addressTyreDealer[property].fieldName,
      value,
      firstPage,
      utils.pdfFirstPageContent.addressTyreDealer[property].x,
      pageHeight.value,
      utils.pdfFirstPageContent.addressTyreDealer[property].width,
      utils.pdfFirstPageContent.addressTyreDealer[property].height
    );
  }

  for (const property in data.addressOfIntermediary) {
    utils.pdfFirstPageContent.addressOfIntermediary[property];

    let value;
    if (property === "salutation_id") {
      const salutation = salutations.value.find(
        (x) => x.id == data.addressOfIntermediary[property]
      );

      value = salutation ? salutation.name : "";
    } else if (property === "postal_code") {
      value = `${data.addressOfIntermediary["postal_code"] ?? ""} ${data.addressOfIntermediary["city"] ?? ""
        }`;
    } else if (property === "city" || property === "customer_number") {
      continue;
    } else {
      value = data.addressOfIntermediary[property] ?? "";
    }

    if (value && typeof value === "number") {
      value = value.toString();
    } else {
      value = value ?? "";
    }

    pageHeight.value =
      height / 2 + utils.pdfFirstPageContent.addressOfIntermediary[property].y;
    utils.createField(
      pdfForm,
      utils.pdfFirstPageContent.addressOfIntermediary[property].fieldName,
      value,
      firstPage,
      utils.pdfFirstPageContent.addressOfIntermediary[property].x,
      pageHeight.value,
      utils.pdfFirstPageContent.addressOfIntermediary[property].width,
      utils.pdfFirstPageContent.addressOfIntermediary[property].height
    );
  }

  for (const property in data.addressEndCustomerData) {
    utils.pdfFirstPageContent.addressEndCustomerData[property];

    let value;
    if (property === "salutation_id") {
      const salutation = salutations.value.find(
        (x) => x.id == data.addressEndCustomerData[property]
      );

      value = salutation ? salutation.name : "";
    } else if (property === "postal_code") {
      value = `${data.addressEndCustomerData["postal_code"] ?? ""} ${data.addressEndCustomerData["city"] ?? ""
        }`;
    } else if (property === "city" || property === "customer_number") {
      continue;
    } else {
      value = data.addressEndCustomerData[property] ?? "";
    }

    if (value && typeof value === "number") {
      value = value.toString();
    } else {
      value = value ?? "";
    }

    pageHeight.value =
      height / 2 + utils.pdfFirstPageContent.addressEndCustomerData[property].y;
    utils.createField(
      pdfForm,
      utils.pdfFirstPageContent.addressEndCustomerData[property].fieldName,
      value,
      firstPage,
      utils.pdfFirstPageContent.addressEndCustomerData[property].x,
      pageHeight.value,
      utils.pdfFirstPageContent.addressEndCustomerData[property].width,
      utils.pdfFirstPageContent.addressEndCustomerData[property].height
    );
  }

  let damageList = [];
  for (const property in data.complaintReasons) {
    damageList.push(damageTypes.value[property].name);
  }

  utils.createField(
    pdfForm,
    utils.pdfSecondPageContent.complaintReason.fieldName,
    damageList.join(", "),
    secondPage,
    utils.pdfSecondPageContent.complaintReason.x,
    height / 2 + utils.pdfSecondPageContent.complaintReason.y,
    utils.pdfSecondPageContent.complaintReason.width,
    utils.pdfSecondPageContent.complaintReason.height
  );

  const damageText = utils.setYesNoText(formValue.isDamage);

  utils.createField(
    pdfForm,
    utils.pdfSecondPageContent.damage.fieldName,
    damageText,
    secondPage,
    utils.pdfSecondPageContent.damage.x,
    height / 2 + utils.pdfSecondPageContent.damage.y,
    utils.pdfSecondPageContent.damage.width,
    utils.pdfSecondPageContent.damage.height
  );

  const personalInjuryText = utils.setYesNoText(formValue.isPersonalInjury);

  utils.createField(
    pdfForm,
    utils.pdfSecondPageContent.personalInjury.fieldName,
    personalInjuryText,
    secondPage,
    utils.pdfSecondPageContent.personalInjury.x,
    height / 2 + utils.pdfSecondPageContent.damage.y,
    utils.pdfSecondPageContent.personalInjury.width,
    utils.pdfSecondPageContent.personalInjury.height
  );

  const isTyreReplacedText = utils.setYesNoText(formValue.isTyreReplaced);

  utils.createField(
    pdfForm,
    utils.pdfSecondPageContent.isTyreReplaced.fieldName,
    isTyreReplacedText,
    secondPage,
    utils.pdfSecondPageContent.isTyreReplaced.x,
    height / 2 + utils.pdfSecondPageContent.damage.y,
    utils.pdfSecondPageContent.isTyreReplaced.width,
    utils.pdfSecondPageContent.isTyreReplaced.height
  );

  pdfForm.flatten();

  const pdfBytes = await pdfDoc.save();

  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "label";
  link.click();
  URL.revokeObjectURL(link.href);

  const form = new FormData();
  form.append("file", blob);

  const fileData = {
    id: hashid,
    form: form,
  };

  await ContactService.storeConfirmation(fileData);

  form.value = {
    isDamage: null,
    isPersonalInjury: null,
    generally: {
      ordinance: "",
      email: "",
      pickup_location_id: null,
      country: null,
    },
    vehicle_tyre_id: null,
    damage_type_id: null,
    vehicleManufacturer: null,
    vehicleModelType: null,
    firstRegistration: null,
    clerkName: null,
    regulation1: null,
    regulation2: null,
    isTyreReplaced: null,
    brandSpareTyre: null,
    originalEquipment: null,
    complaintReasonText: null,
    tyre_wear_id: null,
    milageOrHour: null,
    enginePower: null,
  };
  addressTireDealer.value = null;
  addressOfIntermediary.value = null;
  addressEndCustomerData.value = null;
};

const scrollTo = (id) => {
  document.getElementById(id).scrollIntoView();

}

onMounted(async () => {
  await store.dispatch("loadPickupLocations");
  await store.dispatch("loadDamageTypes");
  await store.dispatch("loadTyrePositions");
  await store.dispatch("loadVehicleTyres");
  await store.dispatch("loadTyreWears");
  await store.dispatch("loadSalutations");
});
</script>
