const messages = {
    "fleetWithOptions": "Nem választhat flottát, ha opciókat választott.",
    "addProduct": "A terméket hozzáadtuk a kosárhoz.",
    "addProducts": "A termékeket hozzáadtuk a kosárhoz.",
    "profileSaved": "A profil adatai elmentve.",
    "canAddProduct": "Nincs elegendő pontja.",
    "customerNumberSaved": "Ügyfélszám mentve.",
    "customerNumberExist": "Ügyfélszám elérhető.",
    "complaintForm": "Kérését elfogadtuk. Kérjük, várjon a válaszra.",
    "openHourSaved": "A nyitvatartási idő elmentve.",
    "changeParentId": "Nem módosíthatja a felsőbb ügyfélszámú ágat, ha alárendelt ágai vannak.",
    "requestSent": "A kérés elküldve.",
    "completeProfileRequired": "A folytatáshoz 100%-ban ki kell töltenie a profilját.",
    "accessDenied": "Hozzáférés megtagadva.",
    "incomplete_address": "A cím hiányos. Ellenőrizze, kérem.",
    "waitMoment": "Várjon egy pillanatot.",
    "downloadFinished": "Letöltés befejezve.",
    "selectOneField": "Válasszon ki egy mezőt.",
    "noVoucher": "Az utalvány nem található.",
    "cannotAddCsNumber": "Nem adhat hozzá ügyfélszámot."
}

export default {
    messages
}
