const messages = {
    title: 'Accueil',
    welcomeMessage: "Bienvenue sur BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Accueil",
    "search": "Recherche",
    "sortBy": "Trier par",
    "seeMore": "Voir plus",
}

export default {
    messages
}
