const messages = {
  "termsAndConditions": "Termeni și condiții",
  "accept": "Acceptare",
  "decline": "declinare",
  "dataModal": "Pentru a putea adapta această ofertă intereselor dumneavoastră, vă rugăm să precizați pe scurt care sunt produsele sau domeniile de activitate de care sunteți interesat.",
  "scrollToAccept": "Pentru a continua vă rugăm  derulați în jos și acceptați condițiile noastre ",
  "welcomeMessage": "Bine ați  venit la Bridgestone Plus",
  "tailorOffer": "Pentru a putea adapta această ofertă intereselor dumneavoastră, vă rugăm să precizați pe scurt care sunt produsele sau domeniile de activitate de care sunteți interesat.",
  "selectAreas": "Vă rugăm să selectați* din următoarele domenii",
  "multipleAnswers": "(sunt posibile răspunsuri multiple):",
  "personalPreferences": "Preferințe personale",
  "carTyres": "Anvelope pentru mașini, SUV-uri, utilitare",
  "motorcyclesTyres": "Anvelope pentru motociclete",
  "truckBusTyres": "Anvelope pentru camioane și autobuze",
  "agricultureTyres": "Anvelope pentru vehicule agricole",
  "otrTyres": "Anvelope OTR",
  "save": "Salvează",
  "businessRelationship": "Care este relația ta de afaceri cu Bridgestone?",
  "selectRelationship": "Vă rugăm să selectați*:",
  "tradingPartner": "Sunt un partener comercial care...",
  "tradingPartnerDirect": "... comandă direct de la Bridgestone",
  "tradingPartnerIndirect": "... comandă indirect (prin intermediul angrosistilor, etc.)",
  "tradingPartnerBoth": "... comandă atât direct cât și indirect",
  "fleetResponsibleDirect": "Sunt un manager de flotă care...",
  "fleetResponsibleIndirect": "... comandă indirect (prin intermediul comerțului sau angrosistilor)",
  "fleetResponsibleBoth": "... comandă atât direct cât și indirect",
  "noBusinessRelationship": "<b>Nu am nicio relație de afaceri cu Bridgestone</b> (nici directă, nici indirectă)",
  "yourCustomerNumber": "Numărul dvs. de client Bridgestone*:",
  "enterCustomerNumber": "Introduceți numărul dvs. de client",
  "waitPersonalisedView": "Vă rugăm să așteptați un moment, opinia dumneavoastră personalizată despre BRIDGESTONE PLUS va fi generată.",
  "personalised": "PERSONALIZAT",
  "plusInfo": "Aici veți găsi informații despre contul dvs. Plus și cutia poștală Plus.",
  "continue": "Continuă",
  "skip": "Omite",
  "oneOfTwo": "1 din 2",
  "openApps": "Puteți deschide direct aplicațiile pentru care sunteți deja înregistrat. De asemenea, puteți solicita acces la alte aplicații care ar putea fi relevante pentru dvs.",
  "requiredFields": "Vă rugăm să completezi câmpurile obligatorii: Titlu, Prenume, Nume de familie",
  "stage": "Pas",
  "chooseOne": "(Vă rugăm să bifați cel puțin o căsuță)",
  "required": "*câmpuri obligatorii",
  "lengthCsNumber": "Lungimea numărului de client: 6-10 cifre"
}

export default {
  messages,
};
