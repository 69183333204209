const messages = {
    "fleetWithOptions": "Je kunt de vloot niet kiezen als je opties hebt geselecteerd.",
    "addProduct": "Je product is toegevoegd aan het winkelmandje.",
    "addProducts": "Je producten zijn toegevoegd aan het winkelmandje.",
    "profileSaved": "Je profielgegevens zijn opgeslagen.",
    "canAddProduct": "Je hebt niet genoeg punten.",
    "customerNumberSaved": "Klantnummer opgeslagen.",
    "customerNumberExist": "Klantnummer beschikbaar.",
    "complaintForm": "Je verzoek is geaccepteerd. Wacht alsjeblieft op een reactie.",
    "openHourSaved": "Openingstijden zijn opgeslagen.",
    "changeParentId": "Je kunt het filiaal met het bovenliggende klantnummer niet wijzigen als het onderliggende filialen heeft.",
    "requestSent": "Het verzoek is verzonden.",
    "completeProfileRequired": "Je moet je profiel 100% invullen om verder te winkelen in de winkel.",
    "accessDenied": "Toegang geweigerd.",
    "incomplete_address": "Het adres is onvolledig. Controleer het alstublieft.",
    "waitMoment": "Wacht een moment.",
    "downloadFinished": "Download voltooid.",
    "selectOneField": "Selecteer een veld.",
    "noVoucher": "De voucher is niet gevonden.",
    "cannotAddCsNumber": "Je kunt geen klantnummer toevoegen."
}

export default {
    messages
}
