const messages = {
    title: "Boîte de réception",
    desc: "Bienvenue dans votre boîte de réception. Vous y trouverez des notifications et des nouvelles importantes.",
    newMessages: "Nouveaux messages dans votre boîte aux lettres",
    link: "Afficher les messages",
    "showMessages": "Afficher les messages",
    message: {
        sender: "Expéditeur",
        thread: "Sujet",
        date: "Date",
    },
    sender: {
        system: "BRIDGESTONE PLUS",
    },
    back: "Retour à l'aperçu",
}

export default {
    messages
}