const messages = {
  title: "Bienvenue sur BRIDGESTONE <span class='text-danger'>PLUS</span>",
  description: "<b>Vous avez déjà un compte?</b><br/>Connectez-vous à l'aide de votre adresse e-mail et de votre mot de passe.<br/><br/><b>Vous êtes nouveau ici?</b><br/>Cliquez ci-dessous pour créer votre compte.<br/><br/><b>Une question?</b><br/>Veuillez nous contacter au <b>04 84 49 29 00.</b>",
  info: "",
  buttons: {
    action: "S'identifier / S'inscrire",
    back: "Retour à la connexion",
    changeLanguage: "Changer de langue",
    start: "Commencer"
  },
  languageSelect: {
    label: "Sélection de la langue",
    description: "Veuillez choisir votre langue",
  }
}

export default {
  messages
}