const messages = {
  "title": "Welkom bij BRIDGESTONE <span class='text-danger'>PLUS</span>",
  "description": "<b>Heeft u al een account?</b><br/>Log in met uw e-mailadres en wachtwoord.<br/><br/><b>Bent u nieuw hier?</b><br/>Klik hieronder om uw account aan te maken.<br/><br/><b>Een vraag?</b><br />Neem contact op met <strong>service.consommateur@take-off.fr</strong>",
  "info": "",
  "buttons": {
    "action": "Inloggen / Registreren",
    "back": "Terug naar inloggen",
    "changeLanguage": "Taal wijzigen",
    "start": "Start"
  },
  "languageSelect": {
    "label": "Taalkeuze",
    "description": "Kies uw taal",
    "start": {
      'nlBE': 'START',
      'frBE': 'DEPART'
    }
  }
}

export default {
  messages
}