const messages = {
    "title": "Hjem",
    "welcomeMessage": "Velkommen til BRIDGESTONE <span class=\"text-danger\">PLUS</span>, %{first_name} %{last_name}",
    "home": "Hjem",
    "search": "Søg efter",
    "sortBy": "Sorter efter",
    "seeMore": "Se mere"
}
export default {
    messages
}
