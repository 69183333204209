const messages = {
  title: "Welcome to BRIDGESTONE <span class='text-danger'>PLUS</span>",
  description: "Log in with your email address and password.<br /><br /><b>Are you a Bridgestone TYRE<span class='text-danger'>CLUB</span> participant?</b><br /><br />Then first set your new password for the email address you used for the TYRE<span class='text-danger'>CLUB</span> using the <b>Forgot password</b> function.<br /><br />After that, you will be logged in automatically.",
  info: '',
  buttons: {
    action: 'LOG IN',
    back: 'Back to Login',
    changeLanguage: 'Change Language',
    start: 'Start'
  },
  languageSelect: {
    label: "Language Selection",
    description: "Please select your language",
  },
}

export default {
  messages
}
